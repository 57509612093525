import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { Icon } from '@iconify/react';
import uploadIcon from '@iconify/icons-mdi/upload';

import Import from './Import';

export const styles = theme => createStyles({
    button: {
        marginRight: 20,
        marginBottom: 8
    },
})

class SchedulesButton extends Component {
    state = {
        showDialog: false, 
    };

    openImport = async () => {
        this.setState({showDialog: true})
    }

    handleClose = () => {
        this.setState({ showDialog: false })
    }

    render() {
        const {classes, } = this.props;
        const { showDialog } = this.state
        return (
            <Fragment>
                <Button    
                    className={classes.button}  
                    color="primary" 
                    size="small"
                    variant="contained"
                    startIcon={<Icon icon={uploadIcon} />}
                    onClick={this.openImport} 
                >
                    Импорт
                </Button>
                <Import handleCloseClick={this.handleClose} showDialog={showDialog}/>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(SchedulesButton);
