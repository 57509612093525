import React, { Component, Fragment } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import deburr from 'lodash/deburr';

import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Labeled, HTMLField } from '../../tpls/fields';
import { TextInput } from '../../tpls/input';

import { Filter } from '../../Utils/components';

const styles = theme => createStyles({
    text: {
        fontSize: '0.7rem',
        fontWeight: 400,
        lineHeight: '1.2em'
    },
    dividers: {
        padding: '8px 16px'
    },
    panelSummaryContent: {
        margin: 0
    },
    panelSummaryRoot:{
        padding: 0
    },
    cardSchedule: {
        width: '100%'
    },
    cardTitle: {
        color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#0a529f'
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    table: {
        padding: 1,
        fontSize: 11,
        color: '#084c92',
    },
    tableHead: {
        backgroundColor: '#f6b733'
    },
    cellHead: {
        color: '#0a529f',
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    workHours:{
        fontWeight: 600
    },
    additionhHours:{
        fontStyle: 'oblique'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    description: {
        color: 'red'
    }
})


const weekDay = {
    1: "Пн.",
    2: "Вт.",
    3: "Ср.",
    4: "Чт.",
    5: "Пт.",
    6: "Сб.",
    7: "Вс.",
};

const appsWorks = (item, classes) => {
    if(! item){
        return "-"
    }
    return (
        <span>
        {item.schedule.map( (el,index) =>
            <span key={index}>
                {index===0  
                    ? 
                        <div className={classes.workHours}>{el.start} - {el.end}</div>
                    : 
                        <div className={classes.workHours}>{el.start} - {el.end}</div>
                }
            </span>
        )}
        </span>
    )
}

const AppsList = ({record, apps, classes}) => {
    return (
        <Fragment>
            <Table>
                <TableHead
                    classes={{
                        root: classes.tableHead
                    }}
                >
                    <TableRow>
                        <TableCell style={{width: '30%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                        Анализы
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Пн.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Вт.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Ср.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Чт.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Пт.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Сб.
                        </TableCell>
                        <TableCell style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            Вc.
                        </TableCell>
                    </TableRow>
                </TableHead>
                {record &&
                    <TableBody>
                        {record.map(
                            (item, index )=>
                            <TableRow key={index}>
                                <TableCell classes={{ root: classes.cellRoot}}>
                                    <b>Приложение {item.application}</b>
                                    {item.analysis.map( analys => (
                                        <div key={analys.code} style={{paddingLeft: 32}}>{analys.code} {analys.name}</div>
                                    ))}
                                </TableCell>
                                {Array.from({length: 7}, (v, k) => k+1).map( day =>(
                                    <TableCell key={day} style={{textAlign: 'center'}} classes={{ root: classes.cellRoot}}>
                                        {appsWorks(item.weekDays.find( i => i.day === day), classes)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>
                }
            </Table>
        </Fragment>
    )
};

class SchedulesDialog extends Component {
    constructor(props){
        super(props)
        this.state = {
            error: false,
            search: '',
            expanded: {}
        };
    }

    getList(value, list) {
        if(!value){
            return list;
        }
        const inputValue = deburr(value.trim()).toLowerCase();
        const result = list.filter(punkt => {
              if(!punkt.orientirs){
                punkt.orientirs = '';
              }
              try {
                  const address = punkt.address.toLowerCase().trim().search(inputValue)>=0;
                  const orientirs = punkt.orientirs.toLowerCase().trim().search(inputValue)>=0;
                  const keep = ( address || orientirs);
                  return keep;
              }
              catch(e){
                console.log(e);
                return false;
              }
            });
        return result;
      }

    search = ({search}) => this.setState({search})
    
    render() {
        const { list, classes,  ...props } = this.props;
        const { expanded, search } = this.state;
        let finded = [];
        if(list){
            finded = this.getList(search, list);
        }
        return (
            <Dialog classes={{ paper: classes.dialogPaper }} {...props}>
                <DialogTitle id="scroll-dialog-title">График сдачи биоматериала</DialogTitle>
                <DialogContent 
                    style={styles.text}
                    dividers={true}
                    classes={{
                        dividers: classes.dividers
                    }}
                >
                    <Filter
                        setFilters = {this.search}
                    >
                        <TextInput 
                            source="search"
                            label={`Поиск`}
                            alwaysOn
                            type="search"
                            className={classes.phone}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                      <SearchIcon size="small"/>
                                    </InputAdornment>
                            }}
                        />
                    </Filter>
                        {list 
                            ?
                                finded.map( punkt => (
                                    <ExpansionPanel expanded={expanded[punkt.code]} key={punkt.code} >
                                        <ExpansionPanelSummary 
                                            classes={{
                                                root: classes.panelSummaryRoot,
                                                content: classes.panelSummaryContent,
                                            }
                                        }>
                                            <Card className={classes.cardSchedule}>
                                                <CardHeader
                                                    classes={{
                                                        title: classes.cardTitle,
                                                        root: classes.cardHeader
                                                    }}
                                                    action={
                                                        <IconButton 
                                                            size="small"
                                                            style={{transform: expanded[punkt.code]&&'rotate(180deg)'}}
                                                            onClick={() => this.expanded(punkt.code)}
                                                        >
                                                            <ExpandMoreIcon />
                                                        </IconButton>
                                                        }
                                                    title={`г. ${punkt.city},  ${punkt.address}`}
                                                />
                                                <CardContent style={{padding: 0}}>
                                                    <AppsList record={punkt.schedules} classes={classes}/>
                                                    <Labeled label="Коментарий" className={classes.description}>
                                                        <HTMLField record={punkt}  style={{ color: 'red' }} source="descriptions"/>
                                                    </Labeled>
                                                </CardContent>
                                            </Card>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.details}>
                                            <div style={{width: '75%'}}>
                                                <Labeled label="Ориентиры" className={classes.inlineField15}>
                                                    <HTMLField record={punkt} source="orientirs"/>
                                                </Labeled>
                                                <Labeled label="Пандус" className={classes.inlineField15}>
                                                    <HTMLField record={punkt} source="pandus"/>
                                                </Labeled>
                                            </div>
                                            <div style={{ width: '20%', marginLeft: 'auto' }}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                            </TableCell>
                                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                                График
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Array.from({length: 7}, (v, k) => k+1).map( item =>
                                                            <TableRow key={item}>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    <b>{weekDay[item]}</b>
                                                                </TableCell>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    
                                                                    { punkt.workHours[item] 
                                                                        ? 
                                                                            punkt.workHours[item].map( (el, i) =>{
                                                                                let det = '';
                                                                                if(i< punkt.workHours[item].length-1){
                                                                                    det = ', '
                                                                                } 
                                                                                return `${el.start} - ${el.end}${det}` 
                                                                            })
                                                                            // `${punkt.workHours[item][0].start} - ${punkt.workHours[item][0].end}`
                                                                        : 
                                                                            'Вых.' 
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                        
                                ))
                            :
                                <LinearProgress /> 
                        }
                </DialogContent>
                <DialogActions>
                    <Button 
                        className={classes.close}
                        onClick={props.onClose}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(SchedulesDialog);