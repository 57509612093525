import { CHANGE_FILTERS } from '../_constants';

export function filters (filters = {}, action) {
    const { type, payload } = action
    switch (type) {
        case CHANGE_FILTERS:
            return Object.assign({}, filters, payload.change)
        default:
            return filters
    }
}