import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import {
    withStyles,
    Toolbar,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import DeleteButton from '../../tpls/buttons/DeleteButton';
import { TextInput } from '../../tpls/input';

const styles = theme => ({
    tableHeader: {
        padding: 1,
        fontSize: 11,
    },
    table: {
        padding: 1,
        fontSize: 11,
        color: '#084c92',
    },
    form: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    },
    name: {
        display: 'flex',
        width: theme.spacing(33),
        marginRight: 20
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: 20,
        marginBottom: theme.spacing(2),
    }
});


class PanelsSettings extends Component {

    componentDidMount(){
        const { initialize } = this.props;
        initialize({});
    }

    componentDidUpdate(prevProps){
        if(!prevProps.showDialog&&this.props.showDialog){
            const { initialize } = this.props;
            initialize({});
        }
    }

    render() {
        const {classes, panels = [], valid, addPanel, deletePanel, isSaving = false } = this.props;
        return (
            <Fragment>
                <div className={classes.form}>
                    <Toolbar>
                        <Field
                            name="code"
                            type="number"
                            component={TextInput}
                            label={`Код`}
                            disabled={isSaving}
                            className={classes.code}
                        />
                        <Field
                            name="name"
                            component={TextInput}
                            label={`Название`}
                            disabled={isSaving}
                            className={classes.name}
                        />
                        <div>
                            <Button 
                                className={classes.addButton}
                                color="primary" 
                                size="small"
                                variant="outlined"
                                startIcon={isSaving
                                    ? <CircularProgress size={20} thickness={2} />
                                    : <AddIcon />
                                }
                                onClick={() => addPanel(this.props.formValues)}
                                disabled={isSaving || !valid}
                            > 
                                Добавить
                            </Button>
                        </div>
                    </Toolbar>
                </div>
                <Table>
                    <TableHead>
                        <TableRow style={{ height: 30 }}>
                            <TableCell className={classes.tableHeader} style={{width: '5%'}}>
                                #
                            </TableCell>
                            <TableCell className={classes.tableHeader} style={{width: '10%'}}>
                                Код
                            </TableCell>
                            <TableCell className={classes.tableHeader} style={{width: '65%'}}>
                                Название
                            </TableCell>
                            <TableCell className={classes.tableHeader} style={{width: '10%'}}>
                                Кол-во
                            </TableCell>
                            <TableCell className={classes.tableHeader} style={{width: '10%'}}>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(panels.length>0) ?
                        <TableBody>
                            {panels.map( (item, index )=>
                                <TableRow key={index} style={{ height: 30 }}>
                                    <TableCell className={classes.table}>
                                        {index+1}
                                    </TableCell>
                                    <TableCell className={classes.table}>
                                        <b>{item.code}</b>
                                    </TableCell>
                                    <TableCell className={classes.table}>
                                        <b>{item.name}</b>
                                    </TableCell>
                                    <TableCell className={classes.table}>
                                        {item.analyses}
                                    </TableCell>
                                    <TableCell className={classes.table} style={{textAlign: 'center', paddingRight: 0}}>
                                        <DeleteButton  item={item} action={deletePanel} disabled={item.analyses>0}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    :
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.notFound} colSpan={7}>
                                    Нет данных
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('panelsForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(
        mapStateToProps
    ),
    withStyles(styles),
    reduxForm({
        form: 'panelsForm',
        // onSubmit: submit,
        validate: (values, props) => {
            const errors = { };
            if (!values.name) {
                errors.name = 'Обазятельное'
            }
            if (!values.code) {
                errors.code = 'Обазятельное'
            }
            const findCode = props.panels.find( a => {
                return a.code === +values.code 
            });
            const findName = props.panels.find( a => {
                return ( a.name ? a.name.toLowerCase() : '') === (values.name ? values.name.toLowerCase() : '')
            });
            if(findCode){
                errors.code = 'Код существет'
            }
            if(findName){
                errors.name = 'Название существует'
            }
            return errors;
        },
        destroyOnUnmount: false,
    })
)(PanelsSettings);