import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';

import { getFormValues, submit, isInvalid } from 'redux-form'

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import SchedulesButton from './SchedulesButton';
import ReferenceButton from './ReferenceButton';

import { Button } from '@material-ui/core';

import { history, confirm } from '../../Utils/_helpers';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


class Actions extends Component {
    close = () =>{
        const { formValues } = this.props;
        if(formValues){
            let flag = true;
            const { data = [], analysis = [], first_name, phone, city, sex } = formValues;
            if(first_name || phone || city || sex || data.length>0 || analysis.length>0){
                flag = false
            }
            if(flag){
                return history.goBack();
            }else{
                return confirm({
                            title: "Закрыть?",
                            message: "Элемент не сохранен! Закрыть?",
                            confirmLabel: 'Закрыть',
                            onConfirn: this.goBack,
                        });
            }
        }
        return ;
    }

    goBack = () => {
        history.goBack();
    }
    
    render(){
        const { 
            classes, 
            dispatch,
            isSaving,
            invalid
        } = this.props;
        return(
            <Fragment>
                <SchedulesButton disabled={isSaving} />
                <ReferenceButton disabled={isSaving} />
                <Button                                                         
                    className={classes.button}                                                     
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={isSaving || invalid}
                    onClick={() => dispatch(submit('calculatorForm'))}
                >
                    Сохранить
                </Button>
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    disabled={isSaving}
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}
    

const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        formValues: getFormValues('calculatorForm')(state),
        invalid: isInvalid('calculatorForm')(state),
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(Actions);
