import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import {compose} from 'recompose';

import Dashboard from '../../dashboard';
import Calculator from '../../calculator';
import Analysis from '../../analysis';
import Centers from '../../centers';
import Reference from '../../reference';
import Discount from '../../discount';
import Partners from '../../partners';
import Insurance from '../../insurance';
// import Reports from '../../reports';
import Import from '../../import';
import Users from '../../users';
import Profile from '../../profile';
import Statistics from '../../statistics';
import Visor from '../../visor';



// import Settings from '../../settings';
import NotFound from './404';

import { MenuItems } from './Menu';
import { permissions } from '../_helpers';

const styles = theme =>({
  root: {
    position: 'absolute',
    top: `calc(100vh - ${theme.spacing(12)}px)`,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(12),
    overflow: 'auto',
    padding: theme.spacing(1),
    position: 'absolute',
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    width: `calc(100% - ${theme.spacing(13)}px)`,
  }
});

const WithPermissionRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>{
        const { location } = rest;
        const pathname = location.pathname.split('/');
        const permissionsItem =  MenuItems.find( m => m.to.split('/')[1] === pathname[1]);
        const permission = permissions();
        return (!permissionsItem || (permissionsItem&&permissionsItem.permissions.indexOf(permission)>=0))
          ? 
            <Component {...props} permissionsComponent={permissionsItem.permissions}/>
          : 
            <NotFound />
      }
    }
  />
);

class Main extends Component{
    state = { 
        isExpanded: true,
        taskEl: null,
        block: ''
    }

    render(){
        const { classes } = this.props;
        return (
            <main className={classes.content}>
                {/* <Redirect from="" to="/calculator" /> */}
                <Switch>
                    <Route exact path='/' component={Dashboard} />
                    {/* <Route exact path='/' component={Calculator.list} />
                    <Route exact path='/create' component={Calculator.create} />
                    <Route exact path='/:id' component={Calculator.list} /> */}
                    <WithPermissionRoute exact path='/calculator' component={Calculator.list} />
                    <WithPermissionRoute exact path='/calculator/create' component={Calculator.create} />
                    <WithPermissionRoute exact path='/calculator/:id' component={Calculator.list} />
                    <Route exact path='/analysis' component={Analysis.list} />
                    <Route exact path='/analysis/:id' component={Analysis.list} />
                    <Route exact path='/centers' component={Centers.list} />
                    <Route exact path='/centers/:id' component={Centers.show} />
                    <Route exact path='/reference' component={Reference.list} />
                    <Route exact path='/reference/create' component={Reference.create} />
                    <Route exact path='/reference/:id' component={Reference.show} />
                    <Route exact path='/reference/:id/edit' component={Reference.edit} />
                    <Route exact path='/discount/acting' component={Discount.list} />
                    <Route exact path='/discount/past' component={Discount.list} />
                    <Route exact path='/discount/card' component={Discount.list} />
                    <Route exact path='/discount/acting/:id' component={Discount.show} />
                    <Route exact path='/discount/past/:id' component={Discount.show} />
                    <Route exact path='/discount/card/:id' component={Discount.card} />
                    <Route exact path='/partners' component={Partners.list} />
                    <Route exact path='/insurance' component={Insurance.list} />
                    {/* <WithPermissionRoute exact path='/reports' component={Reports.list} /> */}
                    <WithPermissionRoute exact path='/import' component={Import.list} />
                    <WithPermissionRoute exact path='/import/:id' component={Import.list} />
                    <WithPermissionRoute exact path='/users' component={Users.list} />
                    <WithPermissionRoute exact path='/users/:id/show' component={Users.list} />
                    <WithPermissionRoute exact path='/users/:id/edit' component={Users.list} />
                    <WithPermissionRoute exact path='/users/create' component={Users.list} />
                    <WithPermissionRoute exact path='/statistics' component={Statistics} />
                    <WithPermissionRoute exact path='/visor' component={Visor.list} />
                    <WithPermissionRoute exact path='/visor/:id' component={Visor.list} />
                    <Route exact path='/profile' component={Profile} />
                    {/*<Route exact path='/schedule/:date/breaks' component={Schedule.breaks} />
                    <Route exact path='/statistics' component={Statistics} />
                    <Route exact path='/scripts' component={Scripts.list}/>
                    <Route path='/settings' component={Settings}/> */}

                    {/* <Route exact path='/scripts/:id' component={Scripts.show} /> */}
                    <Route path="*" component={NotFound} />
                </Switch>
                
            </main>
        );
    }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
    connect(),
    withRouter,
    withStyles(styles),
)(Main);