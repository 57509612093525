import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import Actions from './components/Actions';
import { Labeled, TextField } from '../tpls/fields'

export const styles = theme => createStyles({
    table: {
        fontSize: 11
    },
    inComplex: {
        padding: '0px 8px',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    cardStyleFull: {
        width: '-webkit-fill-available',
        padding: theme.spacing(1),
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        height: `calc(100vh - ${theme.spacing(10)}px)`,
        overflowY: 'auto',
        position: 'relative'
    },
    cardHeaderMain: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    flex10: {
        flex: '10em'
    },
    flex20: {
        flex: '20em'
    },
    flex40: {
        flex: '40em'
    }
});

class ReferenceShow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    componentDidMount(){
        if(!!(this.props.match&&this.props.match.params&&this.props.match.params.id)){
            this.fetchData(this.props.match.params.id)
        }
    }

    async fetchData(id) {
        let path =  history.location.pathname.replace(`/${id}`, '');
        const { data } = await provider(
            GET_ONE,
            path,
            id
        );
        if(data) {
          this.setState({ data });
        }
    }

    render() {
        const { classes } = this.props;
        const { data = {} } = this.state;
        return (
            <Card className={classes.cardStyleFull}>
                <CardHeader
                    classes={{ root: classes.cardHeaderMain }}
                    action={ <Actions />}
                />
                <CardContent style={{padding: 0, display: 'flex'}}>
                        <Labeled label="Код" className={classes.flex10}>
                            <TextField record={data} source="code"/>
                        </Labeled>
                        <Labeled label="Приложение" className={classes.flex10}>
                            <TextField record={data} source="application"/>
                        </Labeled>
                        <Labeled label="Наименование услуги" className={classes.flex40}>
                            <TextField record={data} source="name"/>
                        </Labeled>
                        <Labeled label="Стоимость" className={classes.flex10}>
                            <TextField record={data} source="price"/>
                        </Labeled>
                        <Labeled label="Срок выполнения (рабочих дней*)" className={classes.flex20}>
                            <TextField record={data} source="deadline"/>
                        </Labeled>
                </CardContent>
                <CardContent>
                    {(data&&data.inComplex)&&
                        <Labeled label={`В комплексе иследования по ${data.inComplex.length} показателям`}>
                            <Table className={classes.table} record={data} source="inComplex">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                            #
                                        </TableCell>
                                        <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                            Код
                                        </TableCell>
                                        <TableCell style={{width: '65%'}} classes={{ root: classes.cellRoot}}>
                                            Название
                                        </TableCell>
                                        <TableCell style={{width: '20%'}} classes={{ root: classes.cellRoot}}>
                                            Материал
                                        </TableCell>
                                        <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                            Срок
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.inComplex.map( (item, i )=>
                                        <TableRow key={i}>
                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                <b>{i+1}</b>
                                            </TableCell>
                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                <b>{item.code}</b>
                                            </TableCell>
                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                <b>{item.name}</b>
                                            </TableCell>
                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                {item.material}
                                            </TableCell>
                                            <TableCell  classes={{ root: classes.cellRoot}}>
                                                {item.deadline}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Labeled>
                    }
                </CardContent>
            </Card>
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(ReferenceShow);
