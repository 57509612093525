import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';
import { connect } from 'react-redux';
import { getFormValues, submit, isInvalid } from 'redux-form'

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import { Button } from '@material-ui/core';

import { history } from '../../Utils/_helpers';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    disabled: {
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ce8e96',
        }
    },
    activate: {
        color: '#0a529f',
        backgroundColor: '#bbd9ea63',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#bbd9ea',
            border: '1px solid #0a529f',
        }
    },
})


class ActionsEdit extends Component {
    constructor(props){
        super(props);
        this.close = this.close.bind(this);
    }

    close(){

        return history.goBack();
    }

    render(){
        const { 
            classes, 
            dispatch,
            formValues,
            isSaving,
            changeStatus,
            invalid
        } = this.props;
        return(
            <Fragment>
                {formValues&&
                    <Button                                                         
                        className={classes.button}   
                        classes={{
                            root: formValues.status === 'Active' ? classes.disabled : classes.activate
                        }}                                                 
                        size="small"
                        color="secondary"
                        variant="contained"
                        startIcon={formValues.status === 'Active' ? <BlockIcon /> : <CheckIcon />}
                        disabled={isSaving || invalid}
                        onClick={changeStatus}
                    >
                        {formValues.status === 'Active' ? 'Заблокировать' : 'Активировать'}
                    </Button>
                }
                <Button                                                         
                    className={classes.button}                                                     
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={isSaving || invalid}
                    onClick={() => dispatch(submit('userEditForm'))}
                >
                    Сохранить
                </Button>
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        formValues: getFormValues('userEditForm')(state),
        invalid: isInvalid('userEditForm')(state),
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ActionsEdit);