import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { AppBar, Menu, Main, Loading } from './Utils/components';

import { history } from './Utils/_helpers';

class App extends Component {
    state = {
        done: false,
        selectedMenu: {path: 'dashboard'},
        title: '',
        url: window.location.href,
    }

    componentDidMount(){
        if(!this.props.token){
            history.push('/login')
        }
    }

    UNSAFE_componentWillMount(){
        this.setState({done: true})
    }

    componentDidUpdate(prevProps, prevState){
        if(!this.props.token){
            history.push('/login')
        }
    }

    setTitle = (menu) => {
        this.setState({title: menu ? menu.title : ''})
    }
    render(){
        const { title, done } = this.state;
        // const { basket } = this.props
        if(!done){
            return <Loading />;
        }
        return (
            <Fragment>
                <AppBar title={title} />
                <Menu onSelect={this.onSelect} />
                <Main />
            </Fragment>
        );
    }
}

const tokenToProps = () => {
    let token = localStorage.getItem('token');
    let permission = localStorage.getItem('permission');
    return { token, permission };
}

const mapStateToProps = (state) => {
    const { basket } = state;
    return { 
        basket
    };
};

export default compose(
    connect(mapStateToProps, tokenToProps),
)(App);