import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import {
    withStyles,
    Toolbar,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import DeleteButton from '../../tpls/buttons/DeleteButton';
import { SelectInput, TextInput } from '../../tpls/input';
import { GET_LIST, CREATE, DELETE, provider } from '../../Utils/_services/provider';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    tableHeader: {
        padding: 1,
        fontSize: 11,
    },
    table: {
        padding: 1,
        fontSize: 11,
        color: '#084c92',
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    name: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    state: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: 20,
        marginBottom: theme.spacing(2),
    }
});


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            cities: [],
            isSaving: false,
        };

        this.fetchCities = this.fetchCities.bind(this);
        this.fetchSates = this.fetchSates.bind(this);
        this.add = this.add.bind(this);
        this.deleteCity = this.deleteCity.bind(this);
    }
    
    componentDidMount(){
        const { initialize } = this.props;
        initialize({});
    }

    componentDidUpdate(prevProps){
        if(!prevProps.showDialog&&this.props.showDialog){
            const { initialize } = this.props;
            initialize({});
            this.fetchCities();
            this.fetchSates();
        }
    }

    async fetchCities() {
        const { data } = await provider(
            GET_LIST,
            `/city`,
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data) {
          this.setState({ cities: data });
        }
    }

    async fetchSates() {
        const { data } = await provider(
            GET_LIST,
            `/states`,
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data) {
          this.setState({ states: data });
        }
    }

    async add() {
        const { formValues } = this.props;
        const { status } = await provider(
            CREATE,
            '/city',
            {
                data: formValues,
            }
        );
        if(status===200){
            this.fetchCities();
        }
    }

    async deleteCity(item) {
        const { status } = await provider(
            DELETE,
            '/city',
            item._id
        );
        if(status===200){
            this.fetchCities();
        }
    }

    handleCloseClick = () => {
        this.props.handleCloseClick();
    }

    render() {
        const { cities, states, isSaving } = this.state;
        const {classes, showDialog, valid } = this.props;
        return (
            <Fragment>
                <Dialog
                    // maxWidth='lg'
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                >
                    <DialogTitle>Настройки</DialogTitle>
                    <DialogContent>
                        <Fragment>
                            <Toolbar>

                                <Field
                                    name="name"
                                    component={TextInput}
                                    label={`Название`}
                                    disabled={isSaving}
                                    className={classes.name}
                                />
                                <Field
                                    name="state"
                                    component={SelectInput}
                                    label={`Область`}
                                    options={states}
                                    disabled={isSaving}
                                    className={classes.state}
                                />
                                <div>
                                    <Button 
                                        className={classes.addButton}
                                        color="primary" 
                                        size="small"
                                        variant="outlined"
                                        startIcon={isSaving
                                            ? <CircularProgress size={20} thickness={2} />
                                            : <AddIcon />
                                        }
                                        onClick={() => this.add()}
                                        disabled={isSaving || !valid}
                                    > 
                                        Добавить
                                    </Button>
                                </div>
                            </Toolbar>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ height: 30 }}>
                                        <TableCell className={classes.tableHeader} style={{width: '5%'}}>
                                            #
                                        </TableCell>
                                        <TableCell className={classes.tableHeader} style={{width: '30%'}}>
                                            Название
                                        </TableCell>
                                        <TableCell className={classes.tableHeader} style={{width: '45%'}}>
                                            Область
                                        </TableCell>
                                        <TableCell className={classes.tableHeader} style={{width: '10%'}}>
                                            Кол-во
                                        </TableCell>
                                        <TableCell className={classes.tableHeader} style={{width: '10%'}}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {(cities.length>0) ?
                                    <TableBody>
                                        {cities.map( (item, index )=>
                                            <TableRow key={index} style={{ height: 30 }}>
                                                <TableCell className={classes.table}>
                                                    {index+1}
                                                </TableCell>
                                                <TableCell className={classes.table}>
                                                    <b>{item.name}</b>
                                                </TableCell>
                                                <TableCell className={classes.table}>
                                                    {item.state.name}
                                                </TableCell>
                                                <TableCell className={classes.table}>
                                                    {item.punkts}
                                                </TableCell>
                                                <TableCell className={classes.table} style={{textAlign: 'center', paddingRight: 0}}>
                                                    <DeleteButton  item={item} action={this.deleteCity} disabled={item.punkts>0}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                :
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.notFound} colSpan={7}>
                                                Нет данных
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                            {/* {loading&&
                                <LinearProgress />
                            } */}
                        </Fragment>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.close}
                            onClick={this.handleCloseClick}
                            startIcon={<CloseIcon />}
                        >
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('cityForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(
        mapStateToProps
    ),
    withStyles(styles),
    reduxForm({
        form: 'cityForm',
        // onSubmit: submit,
        validate: (values) => {
            const errors = { };
            if (!values.name) {
                errors.name = 'Обазятельное поле'
            }
            if (!values.state) {
                errors.state = 'Обазятельное поле'
            }
            return errors;
        },
        destroyOnUnmount: false,
    })
)(Settings);