import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { basketConstants } from '../../Utils/_constants';
import store from '../../Utils/_reducer';

const { ADD_TO_BASKET } = basketConstants;

const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
}

const onAdd = code =>{
    store.dispatch({type: ADD_TO_BASKET, code})
}

const BasketField = ({classes, style, ...props}) =>{
    return (
        <div className={classes.main} style={style}>
            <IconButton size='small'  onClick={() => onAdd(props.value)} aria-label="Добавить анализ">
                <ShoppingCartIcon fontSize="small"/>
            </IconButton>
        </div>
    )
}

export default withStyles(styles)(BasketField);