import { profileConstants } from '../_constants';

const { PROFILE_HIDE, PROFILE_SHOW } = profileConstants;
const initialState = {
  profile: false,
}

export function profile (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case PROFILE_SHOW:
      return { profile: true, barcode: false };
    case PROFILE_HIDE:
      return { profile: false }
    default:
      return state
  }
}