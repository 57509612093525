import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import {
    Card, 
    CardActions, 
    CardHeader, 
    TextField, 
    InputAdornment, 
    CardContent
} from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';

import logo from '../img/Logo.1e38d95f.svg';
import helix from '../img/logo_Helix.svg';

import { userActions } from '../Utils/_actions';

const styles = theme => ({
    login: {
        maxWidth: 1312,
        margin: '0 auto',
        paddingTop: 84,
        marginBottom: 60,
    },
    login_grid: {
        marginBottom: 40,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    grid: {
        flexGrow: 0,
        maxWidth: '50%',
        flexBasis: '50%'
    },
    card: {
        maxWidth: 428,
        display: 'block',
        margin: '0 auto',
        height: 570
    },
    cardHeader: {
        paddingBottom: 20, 
        textAlign: 'center'
    },
    main: {
        minHeight: '100vh',
        backgroundColor: '#f4f4f4'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '5em 1em 1em 1em',
        flexDirection: 'column',
    },
    reset: {
        paddingTop: '1em'
    },
    button: {
        width: '50%'
    },
    avatar: {
        margin: '3em',
        display: 'flex',
        justifyContent: 'center',
    }
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) =>{
    return(
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            variant="outlined"
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
            }}
            {...inputProps}
            {...props}
            fullWidth
        />
    )
}

const renderPassword = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) =>{
    return(
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            variant="outlined"
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
            }}
            {...inputProps}
            {...props}
            fullWidth
        />
    )
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.setResetForm = this.setResetForm.bind(this);
    }
    
    submit(values){
        this.props.login(values);
    }

    setResetForm(){
        const { history } = this.props;
        history.push("/reset")
    }

    render(){
        const { classes, handleSubmit, isLoading } = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.login}>
                    <div className={classes.login_grid}>
                        <div className={classes.grid}>
                            <img height={570} width={650} src={logo} alt="Логотип"/>
                        </div>
                        <div className={classes.grid}>
                            <form onSubmit={handleSubmit(this.submit)}>
                                <Card className={classes.card}>
                                    <div className={classes.avatar}>
                                        <img src={helix} alt="Логотип"/>
                                    </div>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title={'Авторизация'}
                                    />
                                    <CardContent className={classes.form}>
                                            <div className={classes.input}>
                                                <Field
                                                    autoFocus
                                                    name="username"
                                                    component={renderInput}
                                                    label={`E-mail`}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                            <div className={classes.input}>
                                                <Field
                                                    name="password"
                                                    component={renderPassword}
                                                    label={`Пароль`}
                                                    type="password"
                                                    disabled={isLoading}
                                                />
                                            </div>
                                    </CardContent>
                                    <CardActions className={classes.actions}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={isLoading}
                                            className={classes.button}
                                        >
                                            Войти
                                        </Button>
                                        <Link
                                            component="button"
                                            variant="body2" 
                                            onClick={this.setResetForm}
                                            className={classes.reset}
                                        >
                                            Забыли пароль?
                                        </Link>
                                    </CardActions>
                                </Card>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const actionCreators = {
    login: userActions.login,
    reset: userActions.reset,
};

const mapState = (state) => {
    const { fetch} = state;
    const isLoading = !!fetch.loading>0 || false;
    var recordLiveValues = getFormValues('auth')(state);
    return { formValues: recordLiveValues, isLoading }
}

const enhance = compose(
    connect( mapState, actionCreators),
    reduxForm({
        form: 'auth',
        validate: (values) => {
            const errors = {};
            if (!values.username) {
                errors.username = "Не верный e-mail";
            }
            if (!values.password) {
                errors.password = "Поле не может быть пустым";
            }
            return errors;
        },
    }),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);


export default EnhancedLogin;
