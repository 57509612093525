import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Card } from '@material-ui/core';

import {GET_LIST, provider } from '../Utils/_services/provider';

import Filter from './Filter';
import StatisticsTable from './List';

const styles = theme =>
    createStyles({
        root: {
            width: '100%',
            overflow: 'auto'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 400,
        },
    });

class Statistics extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        const startDate = moment().startOf('day').subtract(6,'days');
        const endDate = moment().startOf('day');
        const interval = 1440;
        const queue = '-';
        this.state = {
            defaultFormValues: {
                startDate,
                endDate,
                interval,
                queue,
                isLoading: true,
            }
        };

        this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async fetchData(values) {
        this.setState({isLoading: true});
        const { data } = await provider(
            GET_LIST,
            `/statistics`,
            {
                filter: {...values },
            }
        );
        if(this._isMounted) {
            this.setState({ data, isLoading: false });
        }
    }

    render() {
        const { classes } = this.props;
        const { data = [], defaultFormValues, isLoading } = this.state;
        return (
            <Card className={classes.root}>
                    <Filter defaultFormValues={defaultFormValues} fetchData={this.fetchData}/>
                    <StatisticsTable data={data} isLoading={isLoading}/>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('statisticsForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(Statistics);
