import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

class AddButton extends Component {
    state = {
        error: false,
    };
    
    render() {
        const { value, action, disabled, isSaving } = this.props;
        const onClick = () => {
            if(!disabled){
                return action(value);
            }
            return;
        }
        return (
            <IconButton size='small' style={{color: (disabled || isSaving) ? '#9e9e9e' : '#f29f33'}} disabled={disabled || isSaving} onClick={onClick} aria-label="Добавить анализ">
                <ShoppingCartIcon fontSize="small"/>
            </IconButton>
        );
    }
}


const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    return { 
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
)(AddButton);