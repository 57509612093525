import React, { Component } from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { List } from '../../Utils/components';

import { TextField, BooleanField, SelectField, DateField  } from '../../tpls/fields';

import Filter from '../Filter';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class DiscountPastList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: { }
        };
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { filter } = this.state;
        return (
            <div className={classes.root}>
                <List
                    refresh={true}
                    creat={false}
                    show={true}
                    basePath="/discount/past"
                    filters={<Filter />}
                    filterDefaultValues={filter}
                    perPage={25} 
                    sort={{ field: 'code', order: 'ASC' }} 
                >
                    <TextField
                        source="code"
                        label="Код"
                    />
                    <TextField
                        source="name"
                        label="Название"
                    />
                    <DateField
                        format="DD.MM.YYYY"
                        source="start"
                        label="Начало"
                    />
                    <DateField
                        format="DD.MM.YYYY"
                        source="end"
                        label="Окончание"
                    />
                    <SelectField
                        notSort={true}
                        source="discount_dase"
                        label="База"
                        options={[
                            { 
                                id: 'У',
                                name: 'Услуга'
                            },
                            { 
                                id: "Ч",
                                name: 'Чек'
                            },
                            { 
                                id: 'Н',
                                name: 'Нулевая стоимость'
                            },
                            { 
                                id: 'Т',
                                name: 'Скидка от теста'
                            },
                            { 
                                id: 'С',
                                name: 'По себестоимости'
                            },
                        ]}
                    />
                    <TextField
                        notSort={true}
                        source="discount"
                        label="Скидка"
                    />
                    <SelectField
                        notSort={true}
                        source="influence"
                        label="Влияние"
                        options={[
                            { 
                                id: 'А',
                                name: 'Анализ'
                            },
                            { 
                                id: "К",
                                name: 'Комплекс'
                            },
                            { 
                                id: 'АК',
                                name: 'Анализ/Комплекс'
                            },
                        ]}
                    />
                    <BooleanField
                        notSort={true}
                        source="availability"
                        label="Доступен"
                    />
                </List>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
)(DiscountPastList);
