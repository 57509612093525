import React, { Component } from 'react';
import compose from 'recompose/compose';
import {  
    getFormValues,
    change
} from 'redux-form';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_LIST, GET_ONE, provider } from '../Utils/_services/provider';

import Actions from './components/Actions';
import Form from './components/Form';
import CalculateCard from './components/CalculateCard';
import AnalysisCard from './components/AnalysisCard';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#0a529f'
    },
    cardHeaderMain: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardStyleFull: {
        width: '-webkit-fill-available',
        padding: theme.spacing(1),
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        height: `calc(100vh - ${theme.spacing(10)}px)`,
        overflowY: 'auto',
        position: 'relative'
    },
    cardTitle: {
        color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleLeft: {
        height: `calc(100vh - ${theme.spacing(23)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '45%',
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(23)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '54%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    }
});

class CalculatorCreate extends Component {
    state = {
        analysis: [],
        calculate: false,
        isLoading: false,
        showInfo: false,
        summary: {
            deadline: '-',
            additionalPrice: '-',
            analysisPrice: '-',
            summa: '-',
        }
    };

    async UNSAFE_componentWillMount() {
        const analysis = await provider(
            GET_LIST,
            '/analysis',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const noAnalysis = await provider(
            GET_LIST,
            '/noanalysis',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const panels = await provider(
            GET_LIST,
            '/panels',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const choices = [];
        if(analysis.status===200){
            const analysisLength = analysis.data.length;
            for (let index = 0; index < analysisLength; index++) {
                const element = analysis.data[index];
                choices.push(element);
            }
        }
        if(noAnalysis.status===200){
            const noAnalysisLength = noAnalysis.data.length;
            for (let index = 0; index < noAnalysisLength; index++) {
                const { name } = noAnalysis.data[index];
                choices.push( {
                    name, 
                    code: '',
                    analog: [],
                    other_name: null,
                    noAnalysis: true 
                });
            }
        }
        this.setState({
            choices,
            panels: panels.status===200 ? panels.data : [],
        });
    }

    async fetchData(analysis) {
        this.setState({ isLoading: true });
        const { dispatch } = this.props;
        const { sex }= this.props.formValues;
        // this.props.formValues.analysis = analysis;
        dispatch(change('calculatorForm', 'analysis', analysis));
        this.setState({analysis: analysis});
        const { data } = await provider(
            GET_LIST,
            '/calculate',
            {
                filter: {
                    sex: sex, 
                    ids: analysis
                },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data){
            dispatch(change('calculatorForm', 'data', data));
            const additionalPrice = this.additionalPrice(data);
            const analysisPrice = this.analysisPrice(data);
            const summa = this.summaPrice(data);
            const summary = {
                additionalPrice,
                analysisPrice,
                summa,
                deadline: this.deadline(data),
            }
            this.setState({data, isLoading: false, calculate: true, summary });
        }else{
            this.setState({data: analysis.map( a => a = {Code: a}), isLoading: false, calculate: false});
        }
    }


    additionalPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            if(!item.isAnalys){
                summ += +item.Price;
            }
        }
        return `${summ.toFixed(2)}р`;
    }

    analysisPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            if(item.isAnalys){
                summ += +item.Price;
            }
        }
        return `${summ.toFixed(2)}р`;
    }

    summaPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            summ += +item.Price;
        }
        return `${summ.toFixed(2)}р`;
    }

    deadline (data){
        let max=0;
        for (var i = 0; i < data.length; i++) {
            if(data[i].WorkingDays>max){
                max = data[i].WorkingDays;
            }
        }
        let days = 'рабочих дней';
        if(max===1){
            days = 'рабочий день'
        }else if(max>1&& max<5){
            days = 'рабочих дня'
        }
        return `${max} ${days}`;
    }

    onInfo = async analys =>{
        this.setState({showInfo: true, showData: analys, selected: analys._id});
        const { data } = await provider(
            GET_ONE,
            '/analysis',
            analys._id
        );
        this.setState({showData: data})
    }

    closeInfo = () =>{
        this.setState({showInfo: false, showData: null, selected: null})
    }

    addAnalys = (item, clear) => {
        let { analysis } = this.state;
        if(item&&analysis.indexOf(item.code)<0){
            analysis.push(item.code);
            this.fetchData(analysis);
        }
        if(clear){
            clear();
        }
        this.setState({analysis, showInfo: false, showData: null, selected: null})
    };

    deleteElement = (item) => {
        const { analysis } = this.state;
        let index = analysis.indexOf(item.Code);
        analysis.splice(index, 1);
        this.fetchData(analysis);
        this.setState({ analysis });
        return;
    }

    render() {
        const { classes, formValues, invalid, resource, location, ...props} = this.props;
        const { 
            calculate, 
            isLoading, 
            selected,
            showInfo, 
            showData,
            summary,
            choices,
            panels
        } = this.state;
        return (
            <div className={classes.root}>
                <Card className={classes.cardStyleFull}>
                    <CardHeader
                        classes={{ root: classes.cardHeaderMain }}
                        action={ <Actions />}
                    />
                    <Form />
                    <CardContent style={{padding: 0, display: 'flex'}}>
                        <AnalysisCard 
                            selected={selected}
                            addAnalys={this.addAnalys}
                            onInfo={this.onInfo} 
                            choices={choices}
                            panels={panels}
                        />
                        <CalculateCard 
                            showInfo={showInfo}
                            onInfo={this.onInfo} 
                            showData={showData}
                            addAnalys={this.addAnalys}
                            closeInfo={this.closeInfo}
                            isLoading={isLoading}
                            calculate={calculate}
                            summary={summary}
                            choices={choices}
                            panels={panels}
                            deleteElement={this.deleteElement}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps)
);

export default enhance(CalculatorCreate);
