import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import Skeleton from '@material-ui/lab/Skeleton';

import { Card, Typography } from '@material-ui/core';

import ListToolbar from './ListToolbar';

import { history } from '../../_helpers';

import {GET_LIST, provider } from '../../_services/provider';

import { basketConstants } from '../../_constants';
import store from '../../_reducer';

const { ADD_TO_BASKET } = basketConstants;

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'auto'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table:{
    minWidth: 750,
    width: `calc(100% - ${theme.spacing(2)*2}px)`,
    marginLeft: theme.spacing(2),
  },
  skeleton:{
    // backgroundColor: '#bbd9ea',
    height: 35,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  skeletonRow:{
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  sortIcon: {
    fontSize: '1rem'
  },
  headerSize: {
    padding: theme.spacing(1),
    fontSize: '0.75rem'
  },
  notFound:{
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 600,
  }
});

const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort, headCells = [], show, basket, play } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding='default'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.notSort 
                ? 
                  <span>{headCell.label}</span>
                :
                  <TableSortLabel
                    classes = {{
                      icon: classes.sortIcon
                    }}
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>
          ))}
          {!!basket&&
            <TableCell padding='default'>
            </TableCell>
          }
          {!!show&&
            <TableCell padding='default'>
            </TableCell>
          }
          {!!play&&
            <TableCell padding='default'>
            </TableCell>
          }
        </TableRow>
      </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array.isRequired,
};

class EnhancedList extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        const { children = [] } = props;
        let headers = [];
        children.map( el => {
          const label = el.props.label || el.props.source;
          const id = el.props.source;
          return headers.push({ id, label, ...el.props })
         
        })
        const { history } = props;
        const query = queryString.parse(history.location.search);
        const { orderBy, order, page, rowsPerPage } = query;
        this.state = {
            order: order || props.sort.order.toLowerCase(),
            orderBy: orderBy || props.sort.field,
            page: +page || 0,
            total: 0,
            filterValues: props.filterDefaultValues || {},
            rowsPerPage: +rowsPerPage || props.perPage,
            headers
        };
        this.fetchData = this.fetchData.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.setCreate = this.setCreate.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.setPlay = this.setPlay.bind(this);

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    async componentDidMount (){
      this._isMounted = true;
      this.fetchData();
    }

    componentWillUnmount() {
      this.setState = ({ filterValues: this.props.filterDefaultValues || {} });
      this._isMounted = false;
    }

    async fetchData(props = {} ) {
      const { basePath } = this.props;
      const { orderBy, order, page, filterValues, rowsPerPage } = this.state;
      // this.checkForm(props);
      const { refresh } = props;
      const { data, total } = await provider(
          GET_LIST,
          `/${basePath}`,
          {
              filter: { ...filterValues },
              sort: { field: orderBy, order },
              pagination: { page: refresh ? 1 : page+1, perPage: rowsPerPage },
          }
      );
      if(this._isMounted) {
        this.setState({ data, total });
      }
    }

    checkForm (props) {
      const { orderBy, order, page, filterValues, rowsPerPage } = this.state;
      const { refresh } = props;
      if(refresh){
        this.setState({ page: 0 });
      }
      let searchAray = [`orderBy=${orderBy}`,`order=${order}`,`page=${refresh ? 0 : page}`,`rowsPerPage=${rowsPerPage}`];
      Object.keys(filterValues).map( key => {
        let item = filterValues[key];
        if(moment.isMoment(item)){
          return searchAray.push(`${key}=${item.format("YYYY-MM-DD")}`)
        }else{
          return searchAray.push(`${key}=${item}`)
        }
      });
      const { history } = this.props;
      history.push({
        pathname: history.location.pathname,
        search: `?${searchAray.join("&")}`
      })
    }
    
    setCreate () {
      const { history, match } = this.props;
      let path = `${history.location.pathname}/create`;
      if(!!(match&&match.params&&match.params.id)){
        const value = match.params.id;
        path =  history.location.pathname.replace(value, 'create');
      }
      history.push(path);
    }

    setShow (id) {
      const { history, match, pathToShow } = this.props;
      let path = `${history.location.pathname}/${id}${!!pathToShow ? pathToShow : ''}`;
      if(!!(match&&match.params&&match.params.id)){
        const value = match.params.id;
        path =  history.location.pathname.replace(value, id);
      }
      history.push(path);
    }

    setPlay(obj){
      const { dispatch } = this.props;
      dispatch({type: 'SHOW_PLAYER', payload: { record: obj } });
    }

    setFilters (values) {
      if(this._isMounted) {
        this.setState({filterValues: values});
      }
    }

    handleRequestSort (event, property) {
        const { orderBy, order } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => this.fetchData());
    };

    handleChangePage (event, newPage) {
      const { orderBy, order, filterValues, rowsPerPage } = this.state;
      this.setState({ page: newPage }, () => {
        let searchAray = [`orderBy=${orderBy}`,`order=${order}`,`page=${newPage}`,`rowsPerPage=${rowsPerPage}`];
        Object.keys(filterValues).map( key => {
          let item = filterValues[key];
          if(moment.isMoment(item)){
            return searchAray.push(`${key}=${item.format("YYYY-MM-DD")}`)
          }else{
            return searchAray.push(`${key}=${item}`)
          }
        });
        history.push({
          pathname: history.location.pathname,
          search: `?${searchAray.join("&")}`
        });
        this.fetchData()
      });
    };

    handleChangeRowsPerPage (event) {
      const { orderBy, order, filterValues } = this.state;
      this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) }, () => {
        let searchAray = [`orderBy=${orderBy}`,`order=${order}`,`page=0`,`rowsPerPage=${parseInt(event.target.value, 10)}`];
        Object.keys(filterValues).map( key => {
          let item = filterValues[key];
          if(moment.isMoment(item)){
            return searchAray.push(`${key}=${item.format("YYYY-MM-DD")}`)
          }else{
            return searchAray.push(`${key}=${item}`)
          }
        });
        history.push({
          pathname: history.location.pathname,
          search: `?${searchAray.join("&")}`
        });
        this.fetchData()
      });
    };

    handleUpdate () {
      const { orderBy, order, filterValues, rowsPerPage } = this.state;
      this.setState({ page: 0 }, () => {
        let searchAray = [`orderBy=${orderBy}`,`order=${order}`,`page=0`,`rowsPerPage=${rowsPerPage}`];
        Object.keys(filterValues).map( key => {
          let item = filterValues[key];
          if(moment.isMoment(item)){
            return searchAray.push(`${key}=${item.format("YYYY-MM-DD")}`)
          }else{
            return searchAray.push(`${key}=${item}`)
          }
        });
        history.push({
          pathname: history.location.pathname,
          search: `?${searchAray.join("&")}`
        })
        this.fetchData();
      });
    }

    onAdd(code) {
      store.dispatch({type: ADD_TO_BASKET, code})
    }

    render(){
        const { 
          classes, 
          children, 
          filters, 
          match, 
          filter, 
          isLoading, 
          creat, 
          refresh, 
          basket,
          inBasket = [],
          show,
          play,
          player,
          actions,
          deletedField
        } = this.props;
        const { 
          order,
          orderBy,
          page,
          rowsPerPage,
          total,
          filterValues,
          data = [],
          headers = [],
        } = this.state;

        const controllerProps = {
          setFilters: this.setFilters,
          fetchData: this.handleUpdate,
          setCreate: this.setCreate
        };
        return(
          <Card className={classes.root}>
              {/* <EnhancedTableToolbar {...props}/> */}
              {filters && (
                  <ListToolbar
                      filters={filters}
                      isLoading={isLoading}
                      actions={actions}
                      {...controllerProps}
                      filterValues={filterValues}
                      permanentFilter={filter}
                      creat={creat} 
                      refresh={refresh}
                  />
              )}
              <div className={classes.tableWrapper}>
                {data.length>0?
                  <Fragment>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            headCells={headers}
                            rowCount={data.length}
                            show={show}
                            play={play}
                            basket={basket}
                        />
                        {data.length>0&&
                          <TableBody>
                              {data.map((row, index) =>{
                                const id = !!(match &&match.params &&match.params.id !== 'create') ? match.params.id : false;
                                const disabledBasket = !!basket&&inBasket.indexOf(row[basket])>=0;
                                const selected = id === row.id || player.id === row.id;
                                const deleted = row[deletedField] || false;
                                return(
                                  <TableRow key={index} style={{ backgroundColor: selected ? "#3cb66a3d" : ( deleted ? "#f4433661" : "") }}>
                                    {children.map( (child, i) => {
                                      return (<TableCell key={`${index}-${i}`}>{React.cloneElement(child, { value: row[child.props.source] })}</TableCell>)
                                    })}
                                    {!!basket&&
                                      <TableCell>
                                        <IconButton size='small' disabled={disabledBasket} style={{ color: disabledBasket&&'#9e9e9e' }}  onClick={() => this.onAdd(row[basket])} aria-label="Добавить анализ">
                                            <ShoppingCartIcon fontSize="small"/>
                                        </IconButton>
                                      </TableCell>
                                    }
                                    {!!show&&
                                        <TableCell>
                                          <IconButton style={{color: '#0a529f'}} onClick={() => this.setShow(row.id)} size='small' aria-label="Показать детали">
                                            <KeyboardArrowRightIcon size="small"/>
                                          </IconButton>
                                        </TableCell>
                                    }
                                    {!!play&&
                                        <TableCell>
                                          <IconButton style={{color: '#0a529f'}} onClick={() => this.setPlay(row)} size='small' aria-label="Проиграть">
                                            <PlayArrowIcon size="small"/>
                                          </IconButton>
                                        </TableCell>
                                    }
                                  </TableRow>
                                )
                              }
                              )}
                          </TableBody>
                        }
                    </Table>
                    {data.length>0&&
                      <TablePagination
                          rowsPerPageOptions={[25, 50, 100]}
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    }
                  </Fragment>
                :
                  <Fragment>
                    {(data.length===0&&!isLoading) ? 
                        <Typography className={classes.notFound}>
                          Результатов не найдено
                        </Typography>
                      :
                      <Fragment >
                        {[].map.call('_'.repeat(10), (a,i)=>i).map((i) =>(
                          <div className={classes.skeletonRow} key={`${i}`} >
                            {headers.map( (h,j) => (
                              <Skeleton
                                style={{
                                  width: `${100/headers.length}%`
                                }}
                                key={`${j}`} 
                                variant="text" 
                                animation='wave'
                                className={classes.skeleton}
                              />
                            ))}
                          </div>
                        ))
                        }
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>
          </Card>
        )
    }
}

const mapStateToProps = (state, props) =>{
    const { fetch, player } = state;
    return {
        history,
        player,
        isLoading: !!fetch.loading
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(EnhancedList);