
import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Labeled, TextField, HTMLField } from '../../tpls/fields'

export const styles = theme => createStyles({
    table: {
        fontSize: 11
    },
    inComplex: {
        padding: '0px 8px',
    },
    info:{
        display: 'flex'
    },
    flex20: {
        flex: '20em'
    },
    flex30: {
        flex: '30em'
    },
    flex40: {
        flex: '40em'
    },
    flex50: {
        flex: '50em'
    },
    flex80: {
        flex: '80em'
    },
    fullField: {
        width: '100%',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    heading: {
        color: '#0a529f',
        fontWeight: 600,
        padding: '0px 8px',
        wifth: '90%'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto',
        width: '100%'
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#fbb725',
        color: '#0a529f',
    },
    cardTitleInfo: {
        color: '#0a529f;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    closeInfo: {
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ce8e96',
        }
    },
});

const weekDay = {
    1: "Пн.",
    2: "Вт.",
    3: "Ср.",
    4: "Чт.",
    5: "Пт.",
    6: "Сб.",
    7: "Вс.",
};

class Info extends Component {
    render(){
        const {data, classes } = this.props
        if(!data){
            return null;
        }
        return(
            <div className={classes.info} >
                <div style={{width: '75%'}}>
                    <div style={{display:'flex'}}>
                        <Labeled label="Код" className={classes.flex20}>
                            <TextField record={data} source="code"/>
                        </Labeled>
                        <Labeled label="Город" className={classes.flex20}>
                            <TextField record={data} source="city"/>
                        </Labeled>
                        <Labeled label="Область" className={classes.flex20}>
                            <TextField record={data} source="state"/>
                        </Labeled>
                        <Labeled label="Адрес" className={classes.flex40}>
                            <TextField record={data} source="address"/>
                        </Labeled>
                    </div> 
                    <div style={{display:'flex'}}>
                        <Labeled label="Примечание" className={classes.flex50}>
                            <HTMLField record={data} source="descriptions"/>
                        </Labeled>
                        <Labeled label="Наличие пандуса" className={classes.flex50}>
                            <HTMLField record={data} source="pandus"/>
                        </Labeled>
                    </div> 
                    <div style={{display:'flex'}}>
                        <Labeled label="Ориентиры" className={classes.flex20}>
                            <HTMLField record={data} source="orientirs"/>
                        </Labeled>
                    </div>
                </div>
                <div style={{ width: '20%', marginLeft: 'auto' }}>
                    {data&&
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                    </TableCell>
                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                        График
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from({length: 7}, (v, k) => k+1).map( item =>
                                    <TableRow key={item}>
                                        <TableCell  classes={{ root: classes.cellRoot}}>
                                            <b>{weekDay[item]}</b>
                                        </TableCell>
                                        <TableCell  classes={{ root: classes.cellRoot}}>
                                            { data.workHours[item] 
                                                ? 
                                                    data.workHours[item].map( (el, i) => `${el.start} - ${el.end}, ` )
                                                : 
                                                    'Вых.' 
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    }
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Info);