import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@iconify/react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import uploadIcon from '@iconify/icons-mdi/upload';

import SearchIcon from '@material-ui/icons/Search';

import { LinearProgress } from '@material-ui/core';
import { withRouter } from "react-router";
import {compose} from 'recompose';
import { Button } from '@material-ui/core';

import Import from './Import';

import logo from '../../img/logo_Helix.svg';

import { userActions } from '../_actions';
// import { history } from '../_helpers';
import { permissions } from '../../Utils/_helpers';

const styles = theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        flexGrow: 1,
        width: '100%',
        position: 'absolute'
    },
    buttonRoot: {
        color: '#2eb66a',
        // backgroundColor: '#fbb725',
        border: '1px solid #2eb66a',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#0a529f',
            border: '1px solid #0a529f',
        }
        // '&:hover': {
        //     backgroundColor: '#fbb725',
        // }
    },
    toolbarRoot: {
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 48
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    img: {
        padding: 3,
        width: 95,
        // filter: 'invert()'
    },
    title: {
        flexGrow: 1,
    },
    barcodeButton: {
        marginRight: 24,
        color: '#FFFFFF',
        backgroundColor: '#2EB66A',
        // border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#00539F',
            // border: '1px solid #0a529f',
        }
    },
    paddingButton: {
        paddingRight: 4
    }
});

class MenuAppBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.openProfile = this.openProfile.bind(this);
        this.openImport = this.openImport.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleLogout(event) {
        userActions.logout();
    };

    openBarcode() {
        const { dispatch } = this.props;
        dispatch({type: 'BARCODE_SHOW' });
    }

    openProfile() {
        // history.push('/profile')
        const { dispatch } = this.props;
        dispatch({type: 'PROFILE_SHOW' });
    }
    openImport = async () => {
        this.setState({showDialog: true})
    }

    handleClose = () => {
        this.setState({ showDialog: false })
    }


    render(){
        const { showDialog } = this.state;
        const { classes, isLoading, barcode, profile } = this.props;
        const permission = permissions();
        const permissionsRoles = ['admin', 'supervisor', 'operator'];

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Toolbar
                        classes={{
                            root: classes.toolbarRoot
                        }}
                    >   
                        <div className={classes.title}>
                            <img className={classes.img} src={logo} alt="Логотип"/>
                        </div>
                        {permissionsRoles.indexOf(permission)>=0&&
                            <div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SearchIcon />}
                                    size="small"
                                    // disabled={invalid}
                                    className={classes.barcodeButton}
                                    onClick={() => this.openBarcode()}
                                    disabled={profile.profile}
                                >
                                    Проверить результаты
                                </Button>
                            </div>
                        }
                        {permission==='admin'&&
                            <div className={classes.paddingButton}>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    size="small"
                                    onClick={() => this.openImport()}
                                    color="inherit"
                                    disabled={barcode.barcode || profile.profile}
                                    classes={{
                                        root: classes.buttonRoot
                                    }}
                                >
                                    <Icon icon={uploadIcon} width="24" height="24" />
                                </IconButton>
                            </div>
                        }
                        <div className={classes.paddingButton}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={() => this.openProfile()}
                                color="inherit"
                                disabled={barcode.barcode}
                                classes={{
                                    root: classes.buttonRoot
                                }}
                            >
                                <PersonIcon />
                            </IconButton>
                        </div>
                        <div className={classes.paddingButton}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={this.handleLogout}
                                // color="inherit"
                                classes={{
                                    root: classes.buttonRoot
                                }}
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                    {isLoading&&
                        <LinearProgress color="primary" />
                    }
                    <Import handleCloseClick={this.handleClose} showDialog={showDialog}/>
                </AppBar>
            </div>
        );
    }
}

const mapState = (state) => {
    const { fetch, barcode, profile } = state;
    const isLoading = fetch ? (fetch.loading>0 ? true : false) : false;
    return {
        barcode, profile,
        isLoading,
    }
}

export default compose(
    connect(mapState),
    withRouter,
    withStyles(styles),
)(MenuAppBar);    