import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, UPDATE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import { TextInput, SelectInput, SwitchInput } from '../tpls/input';
import Actions from './components/ActionsEdit';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    firstName: {
        flex: "50em"
    },
    lastName: {
        marginLeft: theme.spacing(2),
        flex: "50em"
    },
    email: {
        flex: "50em"
    },
    role: {
        marginLeft: theme.spacing(2),
        flex: "25em"
    },
    flex10: {
        marginLeft: theme.spacing(2),
        flex: "25em"
    },
});

const submit = async ( values ) =>{
    const { status } = await provider(
        UPDATE,
        '/users',
        {  
            id: values.id,
            data: values
        }
    );
    if(status===200){
        history.goBack()
    }
}

class UsersEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            roles: []
        };
        this.changeStatus = this.changeStatus.bind(this);
    }
    
    componentDidMount(){
        if(this.props.id){
            this.fetchData(this.props.id)
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    async changeStatus() {
        const { formValues } = this.props;
        formValues.status = formValues.status==='Active' ? 'Disabled' : 'Active'
        const { status } = await provider(
            UPDATE,
            '/users',
            {  id: formValues.id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(data) {
            const { initialize } = this.props;
            initialize({ 
                id,
                ...data
            });
        }
    }

    close = () =>{
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}`)
    }

    
    render() {
        const { classes, isSaving, formValues, roles } = this.props;
        return (
            <Card  className={classes.root}>
                <CardHeader
                    action={ <Actions changeStatus={this.changeStatus}/>}
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    {formValues&&
                        <div className={classes.info} >
                            <div style={{display:'flex'}}>
                                <Field
                                    name="last_name"
                                    component={TextInput}
                                    label={`Фамилия`}
                                    disabled={isSaving}
                                    className={classes.firstName}
                                    normalize={this.formatName}
                                />
                                <Field
                                    name="first_name"
                                    component={TextInput}
                                    label={`Имя`}
                                    disabled={isSaving}
                                    className={classes.lastName}
                                    normalize={this.formatName}
                                />
                            </div> 
                            <div style={{display:'flex', paddingTop: 16}}>
                                <Field
                                    name="email"
                                    component={TextInput}
                                    label={`E-mail`}
                                    disabled={isSaving}
                                    className={classes.flex10}
                                    normalize={this.email}
                                />
                                <Field
                                    name="role"
                                    component={SelectInput}
                                    label={`Роль`}
                                    options={roles}
                                    disabled={isSaving}
                                    className={classes.role}
                                    onFocus={this.onFocus}
                                />
                            </div>
                            <div style={{display:'flex', paddingTop: 16}}>
                                <Field
                                    name="ips"
                                    component={TextInput}
                                    label={`IP адреса`}
                                    disabled={isSaving}
                                    className={classes.flex10}
                                />
                            </div>
                            <div style={{display:'flex', paddingTop: 16}}>
                                <Field
                                    name="externalAccess"
                                    component={SwitchInput}
                                    label={`Внешний доступ`}
                                    disabled={isSaving}
                                    className={classes.flex10}
                                />
                                 <Field
                                    name="changePassword"
                                    component={SwitchInput}
                                    label={`Смена пароля(запретить)`}
                                    disabled={isSaving}
                                    className={classes.flex10}
                                />
                            </div> 
                        </div>
                    }
                </CardContent>
            </Card>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('userEditForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
    reduxForm({
        form: 'userEditForm',
        onSubmit: submit,
        // validate: (values) => {
        //     const errors = { };
        //     if (!values.email) {
        //         errors.code = 'Обазятельное поле'
        //     }
        //     if (!values.name_first) {
        //         errors.name_first = 'Обазятельное поле'
        //     }
        //     if (!values.name_second) {
        //         errors.name_second = 'Обазятельное поле'
        //     }
        //     if (!values.role) {
        //         errors.role = 'Обазятельное поле'
        //     }
        //     if (!values.status) {
        //         errors.status = 'Обазятельное поле'
        //     } 
        //     return errors;
        // },
        destroyOnUnmount: false,
    })
);

export default enhance(UsersEdit);
