import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { history } from '../Utils/_helpers';

import { List } from '../Utils/components';

import { TextField, HTMLField } from '../tpls/fields';

import Filter from './Filter';
import SettingsButton from './components/SettingsButton';

import { permissions } from '../Utils/_helpers';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class CentersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: { },
            permission: null,
            permissionsComponent: ['admin']
        };
    }

    componentDidMount(){
        const permission = permissions();
        this.setState({permission});
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes, perPage, page } = this.props;
        const { filter, permissionsComponent = [], permission  } = this.state;
        return (
            <div className={classes.root}>
                <List
                    refresh={true}
                    creat={false}
                    show={true}
                    filterDefaultValues={filter}
                    perPage={perPage} 
                    page={page}
                    basePath='centers'
                    filters={<Filter form="centersForm"/>}
                    actions={(permission&&permissionsComponent.indexOf(permission)>=0) ? <SettingsButton /> : null }
                    sort={{ field: 'panel', order: 'DESC' }} 
                >
                    <TextField
                        notSort={true}
                        source="code"
                        label="Код"
                    />
                    <TextField
                        notSort={true}
                        source="city"
                        label="Город"
                    />
                    <TextField
                        notSort={true}
                        source="address"
                        label="Адрес"
                    />
                    <HTMLField
                        notSort={true}
                        source="descriptions"
                        label="Примечание"
                    />
                </List>
            </div>
        );
    }
}


const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('centersForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { },
    };
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps),
)(CentersList);
