import React, { Component } from 'react';
import compose from 'recompose/compose';
import moment from 'moment';
import { Route } from 'react-router';
import { createStyles, withStyles } from '@material-ui/core/styles';


import Drawer from '@material-ui/core/Drawer';

import { List } from '../Utils/components';

import { TextField, DateField } from '../tpls/fields';

import Filter from './Filter';
import Show from './Show';
import Import from './components/ImportButton';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class ImportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                startDate: moment().startOf('day').subtract(6,'days'),
                endDate: moment().endOf('day'), 
            }
        };
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { filter } = this.state;
        return (
            <Route path="/import/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );
                        return (
                            <div className={classes.root}>
                                <List
                                    classes={{root: isMatch&&classes.listWithDrawer}}
                                    refresh={true}
                                    creat={false}
                                    show={true}
                                    basePath='import'
                                    filters={<Filter />}
                                    match={match}
                                    filterDefaultValues={filter}
                                    perPage={25} 
                                    actions={<Import />}
                                    sort={{ field: 'updateAt', order: 'DESC' }} 
                                >
                                    <DateField
                                        source="updateAt"
                                        label="Дата"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="Analysis"
                                        label="Анализы"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="AnalysisToComplex"
                                        label="Комплексы"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="MedicalCenter"
                                        label="Пункты"
                                    />
                                    {/* <SelectField
                                        notSort={true}
                                        source="sex"
                                        label="Муж/Жен"
                                        options={[
                                            {name: "Муж/Жен", id: "U"},
                                            {name: "Муж", id: "M"},
                                            {name: "Жен", id: "F"},
                                        ]}
                                    />
                                    <TextField
                                        notSort={true}
                                        source="deadline"
                                        label="Срок"
                                    /> */}
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <div style={{padding: '0px 8px'}}>
                                            <Show id={match.params.id}/>
                                        </div>
                                    ) : null}
                                </Drawer>
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

export default compose(
    withStyles(styles),
)(ImportList);
