import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIcon from '@material-ui/icons/Phone';

class PhoneInput extends Component{
  
  formatPhone = (value) =>{
    const phoneNumber = parsePhoneNumberFromString(value, "BY");
    if(phoneNumber){
      value = phoneNumber.formatInternational();
    }
    return value;
  }

  render(){
    const { input: { ...inputProps }, meta: { touched, error } = {}, ...props }  = this.props;
    return(
      <TextField
        tupe="number"
        error={!!(touched && error)}
        helperText={touched && error}
        onChange={({ target: { value }}) => inputProps.onChange(this.formatPhone(value))}
        InputProps={{
          endAdornment: 
                  <InputAdornment position="end">
                    <PhoneIcon size="small"/>
                  </InputAdornment>,
          inputProps:{
            ...inputProps,
          }
        }}
        {...props}
        fullWidth
      />
    )
  }
}

export default PhoneInput;
