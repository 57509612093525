import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import { Button } from "@material-ui/core";

import { Icon } from '@iconify/react';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import LinearProgress from '@material-ui/core/LinearProgress';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {UPLOAD, CREATE, provider } from '../../Utils/_services/provider';

import CheckData from './CheckData';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    upload: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    fileLabel: {
        color: '#0a529f'
    },
    deleteLabel: {
        color: 'red'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
});


class Import extends Component {
    state = {
        error: false,
        showDialog: false,
        loading: false,
        expanded: false,
        activeStep: 0,
        prepeireData: {},
        file: null,
    };

    prev = () => {
        const { activeStep } = this.state;
        this.setState({activeStep: activeStep-1});
    }

    load = async () => {
        this.setState({ activeStep: 1, prepeireData: {}, loading: true });
        const { status, data} = await provider(UPLOAD, '/import/check', {  data: {file: this.state.file } })
        this.setState({ loading: false})
        if(status === 200 ){
            this.setState({ prepeireData: data });
        }
    };

    save = async () => {
        const data = this.state.prepeireData;
        this.setState({loading: true, activeStep: 2})
        const { status }  = await provider(CREATE, '/import/upload', { data })
        if(status === 200 ){
            this.setState({ loading: false });
            this.handleCloseClick();
        }else{
            this.setState({ loading: false });
        }
    };

    deleteFile = () => {
        this.setState({ 
            error: false,
            loading: false,
            expanded: false,
            activeStep: 0,
            file: null,
            prepeireData: {}
        });
    }

    handleChange = panel => (event, isExpanded) => {
        const expanded = isExpanded ? panel : false
        this.setState({expanded});
    };

    setFile = event =>{
        const { name } = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0])
        reader.onload = e => {
            this.setState({
                file: {
                    src: e.target.result,
                    title: name
                } 
            })
        }
    }

    handleCloseClick = () => {
        this.setState({ 
            error: false,
            loading: false,
            expanded: false,
            activeStep: 0,
            file: null,
            prepeireData: {}
        });
        this.props.handleCloseClick();
    }

    render() {
        const { loading, activeStep, prepeireData, expanded, file } = this.state;
        const {classes, showDialog } = this.props;
        
        return (
            <Fragment>
                <Dialog
                    maxWidth='lg'
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                >
                    <DialogTitle>Импорт справочника</DialogTitle>
                    <DialogContent>
                        <Stepper nonLinear activeStep={activeStep}>
                              <Step key={0}>
                                 <StepLabel>Загрузка</StepLabel>
                              </Step>
                              <Step key={1}>
                                 <StepLabel>Проверка</StepLabel>
                              </Step>
                              <Step key={2}>
                                 <StepLabel>Обновление</StepLabel>
                              </Step>
                        </Stepper>
                        {(activeStep === 0 && !loading)&&
                            <div className={classes.upload}>
                                {!!!file 
                                    ?
                                        <label htmlFor="upload">
                                            <input
                                                style={{ display: "none" }}
                                                id="upload"
                                                name="upload"
                                                type="file"
                                                onChange={this.setFile}
                                            />
                                            <Button 
                                                color="primary" 
                                                variant="contained" 
                                                component="span"
                                                startIcon={ <Icon icon={microsoftExcel} /> }
                                            >
                                                Выбрать Файл
                                            </Button>
                                        </label>
                                    :
                                        <div>
                                            <span className={classes.fileLabel}>{file.title}</span>
                                            <IconButton classes={{label: classes.deleteLabel}} onClick={this.deleteFile} size='small' aria-label="Очистить">
                                                <CancelIcon size="small"/>
                                            </IconButton>
                                        </div>
                                }
                            </div>
                        }
                        {loading&&
                            <LinearProgress />
                        }
                        {activeStep === 1&&
                            <CheckData handleChange={this.handleChange} expanded={expanded} prepeireData={prepeireData}/>
                        }
                    </DialogContent>
                    <DialogActions>
                        {activeStep === 0 &&
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={loading || !!!file}
                                onClick={this.load}
                                startIcon={<PublishIcon />}
                            >
                                Загрузить
                            </Button>
                        }
                        {activeStep === 1 &&
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={loading}
                                onClick={this.prev}
                                startIcon={<ArrowBackIcon />}
                            >
                                Назад
                            </Button>
                        }
                        {activeStep === 1 &&
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={loading}
                                onClick={this.save}
                                startIcon={<SaveIcon />}
                            >
                                Сохранить
                            </Button>
                        }
                        <Button
                            className={classes.close}
                            disabled={loading}
                            onClick={this.handleCloseClick}
                            startIcon={<CloseIcon />}
                        >
                            Отменить
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default compose(
    withStyles(styles)
)(Import);