
import React, { Component, Fragment } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { Labeled, TextField, BooleanField, HTMLField } from '../../../tpls/fields'

export const styles = theme => createStyles({
    table: {
        fontSize: 11
    },
    inComplex: {
        padding: '0px 8px',
    },
    flex20: {
        flex: '20em'
    },
    flex30: {
        flex: '30em'
    },
    flex40: {
        flex: '40em'
    },
    flex80: {
        flex: '80em'
    },
    fullField: {
        width: '100%',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    heading: {
        color: '#0a529f',
        fontWeight: 600,
        padding: '0px 8px',
        wifth: '90%'
    },
    deleted: {
        color: 'red',
        textIndent: 20,
        fontSize: 14,
        fontWeight: 600,
    },
    button: {
        marginRight: theme.spacing(1),
        color: '#0a529f',
        backgroundColor: '#e2f4ff',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#bbd9ea',
            border: '1px solid #0a529f',
        }
    },
});


const daysLabel = (day) => {
    let days = 'дней';
    if(day===1){
        days = 'день'
    }else if(day>1&& day<5){
        days = 'дня'
    }
    return `${day} ${days}`;
}

const sexLabel = (sex) => {
    if(sex==='F'){
        return 'Жен.'
    }else if(sex==='M'){
        return 'Муж.'
    }else{
        return 'Муж./Жен.'
    }
}
class AnalysisInfo extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
       
    }


    render(){
        const { data, classes, onInfo, className } = this.props;
        const record = {
            application: data.application ? `Приложение ${data.application}` : '-',
            panel: data.panel ? data.panel : '-',
            code: data.code || '-',
            name: data.name || '',
            deleted: data.deleted || false,
            price: data.price || '-',
            deadline: data.deadline ? daysLabel(data.deadline) : '-',
            material: data.material || '-',
            third_party: data.third_party || false,
            discount: data.discount || false,
            happy_hours: data.happy_hours || false,
            isComplex: data.isComplex || false,
            other_name: data.other_name,
            description: data.description,
            inComplex: data.inComplex || [],
            minsk_only: data.minsk_only || false,
            punkts: data.punkts || [],
            excPunkts: data.excPunkts || [],
            sex: sexLabel(data.sex),
            included: data.included || [],
            availability: data.availability || true
        }
        return(
           
                    <div className={className || classes.info} >
                        {record.deleted&&
                            <div className={classes.deleted}>ВНИМАНИЕ! Данный анализ заблокирован или удален.</div>
                        }
                        <div style={{display:'flex'}}>
                            <Labeled label="Код" className={classes.flex20}>
                                <TextField record={record} source="code"/>
                            </Labeled>
                            <Labeled label="Название" className={classes.flex80}>
                                <TextField record={record} source="name"/>
                            </Labeled>
                        </div>
                        <div style={{display:'flex'}}>
                            <Labeled label="Цена" className={classes.flex20}>
                                <TextField record={record} source="price"/>
                            </Labeled>
                            <Labeled label="Срок" className={classes.flex20}>
                                <TextField record={record} source="deadline"/>
                            </Labeled>
                            <Labeled label="Пол" className={classes.flex20}>
                                <TextField record={record} source="sex"/>
                            </Labeled>
                            <Labeled label="Биоматериал" className={classes.flex40}>
                                <TextField record={record} source="material"/>
                            </Labeled>
                        </div> 
                        <div style={{display:'flex'}}>
                            <Labeled label="Приложение" className={classes.flex20}>
                                <TextField record={record} source="application"/>
                            </Labeled>
                            <Labeled label="Панель" className={classes.flex80}>
                                <TextField record={record} source="panel"/>
                            </Labeled>
                        </div> 
                        <div style={{display:'flex'}}>
                            <Labeled label="Комплекс" className={classes.flex20}>
                                <BooleanField record={record} source="isComplex"/>
                            </Labeled>
                            <Labeled label="Скидки" className={classes.flex20}>
                                <BooleanField record={record} source="discount"/>
                            </Labeled>
                            <Labeled label="Сч. часы" className={classes.flex20}>
                                <BooleanField record={record} source="happy_hours"/>
                            </Labeled>
                            <Labeled label="Ст. исп." className={classes.flex20}>
                                <BooleanField record={record} source="third_party"/>
                            </Labeled>
                            <Labeled label="Только Минск" className={classes.flex20}>
                                <BooleanField record={record} source="minsk_only"/>
                            </Labeled>
                        </div>
                        {record.description&&
                            <Labeled label="Описание" className={classes.fullField}>
                                <HTMLField style={{color: 'red'}} record={record} source="description" />
                            </Labeled>
                        }
                        {(record.punkts&&record.punkts.length>0)&&
                            <Fragment>
                                <Typography className={classes.heading}>Анализ можно сдать только в следующих пунктах</Typography>
                                <div className={classes.inComplex}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                    #
                                                </TableCell>
                                                <TableCell style={{width: '30%'}} classes={{ root: classes.cellRoot}}>
                                                    Город
                                                </TableCell>
                                                <TableCell style={{width: '65%'}} classes={{ root: classes.cellRoot}}>
                                                    Адрес
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.punkts.map( (item, i )=>
                                                <TableRow key={i}>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{i+1}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{item.city}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.address}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fragment>
                        }
                        {(record.excPunkts&&record.excPunkts.length>0)&&
                            <Fragment>
                                <Typography className={classes.heading}>Анализ нельзя сдать в следующих пунктах</Typography>
                                <div className={classes.inComplex}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                    #
                                                </TableCell>
                                                <TableCell style={{width: '30%'}} classes={{ root: classes.cellRoot}}>
                                                    Город
                                                </TableCell>
                                                <TableCell style={{width: '65%'}} classes={{ root: classes.cellRoot}}>
                                                    Адрес
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.excPunkts.map( (item, i )=>
                                                <TableRow key={i}>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{i+1}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{item.city}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.address}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fragment>
                        }
                        {record.other_name&&
                            <Labeled label="Дополнительная информация" className={classes.fullField}>
                                <HTMLField record={record} source="other_name" />
                            </Labeled>
                        }
                        
                        {(record.isComplex&&record.inComplex.length>0)&&
                            <Fragment>
                                <Typography className={classes.heading}>В комплексе иследования по {record.inComplex.length} показателям</Typography>
                                <div className={classes.inComplex}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                    #
                                                </TableCell>
                                                <TableCell style={{width: '7%'}} classes={{ root: classes.cellRoot}}>
                                                    Код
                                                </TableCell>
                                                <TableCell style={{width: '45%'}} classes={{ root: classes.cellRoot}}>
                                                    Название
                                                </TableCell>
                                                <TableCell style={{width: '33%'}} classes={{ root: classes.cellRoot}}>
                                                    Материал
                                                </TableCell>
                                                <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                                    Срок
                                                </TableCell>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.inComplex.map( (item, i )=>
                                                <TableRow key={item.code}>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{i+1}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{item.code}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.material}
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {daysLabel(item.deadline)}
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.cellRoot}}>
                                                        <IconButton onClick={() => onInfo(item)} size='small' aria-label="Показать детали">
                                                            <KeyboardArrowRightIcon size="small"/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fragment>
                        }

                        {(record.included&&record.included.length>0)&&
                            <Fragment>
                                <Typography className={classes.heading}>Входит в состав комплексных иследований</Typography>
                                <div className={classes.inComplex}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                    #
                                                </TableCell>
                                                <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                                    Код
                                                </TableCell>
                                                <TableCell style={{width: '85%'}} classes={{ root: classes.cellRoot}}>
                                                    Название
                                                </TableCell>
                                                <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.included.map( (item, i )=>
                                                <TableRow key={item.code}>
                                                    <TableCell classes={{ root: classes.cellRoot}}>
                                                        <b>{i+1}</b>
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.cellRoot}}>
                                                        <b>{item.code}</b>
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.cellRoot}}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.cellRoot}}>
                                                        <IconButton onClick={() => onInfo(item)} size='small' aria-label="Показать детали">
                                                            <KeyboardArrowRightIcon size="small"/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fragment>
                        }
                    </div>
                
        )
    }
}

export default withStyles(styles)(AnalysisInfo);