import React, { Component } from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { List } from '../Utils/components';

import { TextField } from '../tpls/fields';

import Filter from './Filter';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class ReferenceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: { }
        };
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { filter } = this.state;
        return (
            <div className={classes.root}>
                <List
                    refresh={true}
                    creat={true}
                    show={true}
                    // basket='code'
                    basePath='reference'
                    filters={<Filter />}
                    filterDefaultValues={filter}
                    perPage={25} 
                    sort={{ field: 'panel', order: 'DESC' }} 
                >
                    <TextField
                        notSort={true}
                        source="code"
                        label="Код"
                    />
                    <TextField
                        notSort={true}
                        source="name"
                        label="Название"
                    />
                </List>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
)(ReferenceList);
