import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import LinearProgress from '@material-ui/core/LinearProgress';

import {GET_ONE, DELETE, provider } from '../Utils/_services/provider';
import { history, confirm } from '../Utils/_helpers';

import { AnalysisInfo } from '../Utils/components';
import { permissions } from '../Utils/_helpers';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto',
        width: '100%'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#f4f6f9',
        color: '#0a529f',
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    cardTitleInfo: {
        color: '#0a529f;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    delete: {
        marginRight: theme.spacing(1),
        color: '#F44336',
        backgroundColor: '#fcedec',
        border: '1px solid #f4433685',
        '&:hover': {
            backgroundColor: '#f7c7c4',
            border: '1px solid #f44336',
        }
    },
    closeInfo: {
        marginRight: theme.spacing(1),
        backgroundColor: '#FF5722',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#e93f33',
        }
    },
    edit: {
        marginRight: theme.spacing(1),
        color: '#0a529f',
        backgroundColor: '#e2f4ff',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#bbd9ea',
            border: '1px solid #0a529f',
        }
    },
    info:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(20)}px)`,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    reference:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(20)}px)`,
    },
    cardTitle: {
        fontSize: '0.95rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    referenceCardContent: {
        fontSize: '0.95rem;',
    }
});

class AnalysisShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: true,
            tab: 0,
            permission: null,
            permissionsComponent: ['admin', 'supervisor']
        };
        this.deleteElement = this.deleteElement.bind(this);
        this.close = this.close.bind(this);
        this.deleteProcessing = this.deleteProcessing.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onInfo = this.onInfo.bind(this);
    }
    
    componentDidMount(){
        const permission = permissions();
        this.setState({permission});
        if(this.props.id){
            this.fetchData(this.props.id)
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(data) {
          this.setState({ data, isLoading: false });
        }
    }

    close (){
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}`)
    }

    onInfo (item){
        const pathname = history.location.pathname.split('/');
        const path = `/${pathname[1]}/${item._id}`;
        return history.push(path);
    }

    deleteElement () {
        return confirm({
            title: "Удалить?",
            message: "Выдействительно хотите удалить эелемент?",
            confirmLabel: 'Удалить',
            onConfirn: this.deleteProcessing,
        });
    }
    
    async deleteProcessing (){
        const pathname = history.location.pathname.split('/');
        
        await provider(
            DELETE,
            `/${pathname[1]}`,
            this.props.id
        );
        return history.goBack();
    }

    handleChange (event, newValue) {
        this.setState({ tab: newValue})
    }

    render() {
        const { data = {}, tab, permissionsComponent = [], permission, isLoading  } = this.state;
        const { classes } = this.props;
        return (
            <Fragment>
             <Card  className={classes.cardStyleRigth}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleInfo,
                        root: classes.cardHeaderInfo
                    }}
                    style={{ paddingBottom: 6}}
                    action={
                        <Fragment>
                            {(permission&&permissionsComponent.indexOf(permission)>=0)&&
                                <Fragment>
                                    <Button
                                        style={{
                                            padding: '0px 10px',
                                            marginTop: 6
                                        }}
                                        classes={{
                                            root: classes.closeInfo
                                        }}    
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={this.deleteElement} 
                                    >
                                        Удалить
                                    </Button>
                                    {/* <Button
                                        style={{
                                            padding: '0px 10px',
                                            marginTop: 6
                                        }}
                                        classes={{
                                            root: classes.edit
                                        }}    
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        startIcon={<EditIcon />}
                                        onClick={this.edit} 
                                    >
                                        Редактировать
                                    </Button> */}
                                </Fragment>
                            }
                            <Button  
                                style={{
                                    padding: '0px 10px',
                                    marginTop: 6
                                }}
                                classes={{
                                    root: classes.delete
                                }}                                                        
                                size="small"
                                color="secondary"
                                variant="outlined"
                                startIcon={<CloseIcon />}
                                onClick={this.close} 
                            >
                                Закрыть
                            </Button>
                        </Fragment>

                    }
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    <AppBar position="static">
                        <Tabs value={tab} onChange={this.handleChange}>
                            <Tab label="Описание"  />
                            <Tab label="Правила подготовки"  />
                        </Tabs>
                    </AppBar>
                    {tab === 0 && (
                        <AnalysisInfo className={classes.info} data={data} onInfo={this.onInfo} isLoading={isLoading}/>
                    )}
                    {tab === 1 && (
                        <div className={classes.reference}>
                            {data&&data.references 
                                ?
                                data.references.map((reference, i) => (
                                        <Card key={i} className={classes.card}>
                                            <CardHeader
                                                classes={{
                                                    title: classes.cardTitle,
                                                    root: classes.cardHeader
                                                }}
                                                title={reference.name}
                                            />
                                            <CardContent className={classes.referenceCardContent}>
                                                <div dangerouslySetInnerHTML={{__html: reference.description}} />
                                            </CardContent>
                                        </Card>
                                    ))
                                :
                                    <LinearProgress /> 
                            }
                        </div>
                    )}
                </CardContent>
            </Card>
            </Fragment>
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(AnalysisShow);
