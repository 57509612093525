import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => createStyles({
    text: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1.2em'
    },
    card: {
        borderRadius: 'unset',
        boxShadow: 'none'
    },
    cardTitle: {
        color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#0a529f'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    dialogPaper: {
        height: 'calc(100% - 64px)'
    }
})

class ReferenceDialog extends Component {
    state = {
        error: false,
    };
    
    render() {
        const { list, classes,  ...props } = this.props;
        return (
            <Dialog 
                maxWidth='lg'
                classes={{ paper: classes.dialogPaper }}
                fullWidth={true}
                {...props}
            >
                <DialogTitle id="scroll-dialog-title">Правила подготовки</DialogTitle>
                <DialogContent className={classes.text} dividers={true}>
                    {list 
                        ?
                            list.map((reference, i) => (
                                <Card key={i} className={classes.card}>
                                    <CardHeader
                                        classes={{
                                            title: classes.cardTitle,
                                            root: classes.cardHeader
                                        }}
                                        title={reference.name}
                                    />
                                    <CardContent>
                                        <div dangerouslySetInnerHTML={{__html: reference.description}} />
                                    </CardContent>
                                </Card>
                            ))
                        :
                            <LinearProgress /> 
                    }
                </DialogContent>
                <DialogActions>
                    <Button classes={{
                            root: classes.close
                        }}  
                        onClick={props.onClose} 
                        size="small"
                        color="secondary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ReferenceDialog);