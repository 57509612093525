import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
}

const SelectField = ({classes, options, style, ...props}) =>{
    let value = '-'
    if(options){
        const find = options.find( o => o.id === props.value);
        if(find){
            value = find.name;
        }
    }
    return (
        <div className={classes.main} style={style}>
            <div className={classes.div}>
                {value}
            </div>
        </div>
    )
}

export default withStyles(styles)(SelectField);
