import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {compose} from 'recompose';

import CloseIcon from '@material-ui/icons/Close';

import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Drawer from '@material-ui/core/Drawer';
import moment from 'moment';

import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { TextInput } from '../../tpls/input';

import {GET_ONE, provider } from '../_services/provider';
import { Labeled, TextField, SelectField } from '../../tpls/fields'

const styles = theme => ({
    success: {
        color: '#bbd9ea',
        backgroundColor: "#0a529f",
    },
    error: {
        backgroundColor: "#e57373",
    },
    info: {
        color: '#0a529f',
    },
    warning: {
        backgroundColor: "#fbb725",
        color: '#0a529f',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    // close: {
    //     color: "#f44336",
    //     border: "1px solid #f4433663",
    //     '&:hover': {
    //         border: '1px solid #f44336',
    //         backgroundColor: '#ff000008',
    //     }
    // },
    close: {
        adding: '0px 10px',
        marginTop: 6,
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#e93f33bf',
        }
    },
    drawerPaper:{
        zIndex: 1200,
        width: '100%',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'none',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        touchAction: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    barcodeCard: {
        paddingTop: theme.spacing(7),
        width: '50em',
        flex: '1 0 auto',
        padding: '0px 8px',
        top: 0,
        height: `calc(100vh - ${theme.spacing(7)}px)`,
        display: 'flex',
        outline: 'none',
        position: 'fixed',
        overflowY: 'auto',
        flexDirection: 'column',
        left: 'auto',
        right: 0
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#3cb66a',
        // color: '#0a529f',
    },
    cardTitleInfo: {
        color: '#ffffff;',
        fontSize: '0.95rem;',
        fontWeight: 600
    },
    searchRow: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '50%'
    },
    cellRoot:{
        padding: 0
    },
    flex60: {
        flex: '60em'
    },
    flex20: {
        flex: '40em'
    },
    flex40: {
        flex: '40em'
    },
    notFoundText: {
        paddingTop: theme.spacing(2),
        color: 'red',
        fontWeight: 700,
        textAlign: 'center'
    }
});

const ActionsShow  = ({classes, onClose}) => {
    return(
        <Button                                                         
            className={classes.button}   
            classes={{
                root: classes.close
            }}                                                   
            size="small"
            color="secondary"
            variant="outlined"
            startIcon={<CloseIcon />}
            onClick={onClose} 
        >
            Закрыть
        </Button>
    );
}

class Barcode extends React.Component {
    state = {
        openBarcode: false,
        data: null
    };
  
    componentDidUpdate() {
        const { barcode } = this.props.barcode;
        const { openBarcode } = this.state;
        if(barcode&&!openBarcode){
            this.openBarcode(barcode)
        }
    }

    onClose = () => {
        const { dispatch, reset } = this.props;
        this.setState({
            openBarcode: false,
            data: null
        });
        dispatch(reset('barcodeForm'))
        dispatch({type: 'BARCODE_HIDE' });
    };

    openBarcode = (barcode) => {
        this.setState({ openBarcode: barcode, loading: true});
    }

    searchBarcode = async (form) => {
        const { barcode } = form;
        this.setState({ loading: true });
        const { data } = await provider(
            GET_ONE,
            '/result',
            barcode
        );
        this.setState({ data, loading: false })
    }

    formatBarcode = value => (value.replace(/\D+/g,""))

  render() {
    const { openBarcode, data, loading } = this.state;
    const { classes, invalid, formValues } = this.props;
    const patient = {
        fullname: (data&&data.Patient) ? data.Patient.LastName+' '+data.Patient.FirstName : null,
        gender: (data&&data.Patient) ? data.Patient.Gender : null,
        dateOfBirth: (data&&data.Patient) ? moment(data.Patient.DateOfBirth,'YYYYMMDD').format("DD.MM.YYYY") : null
    }
    if(data){
        data.DeadLineDate =  moment(data.DeadLineDate,'YYYYMMDD').format("DD.MM.YYYY")
        data.OrderDate =  moment(data.OrderDate,'YYYYMMDD').format("DD.MM.YYYY")
    }
    return (
        <div>
            {openBarcode &&
                    <div className={classes.drawerPaper}></div>
            }
            <Drawer
                variant="persistent"
                open={openBarcode}
                anchor="right"
                onClose={this.onClose}
            >
                {openBarcode ? (
                    <Card  className={classes.barcodeCard}>
                        <CardHeader
                            title={`Проверка готовности заказа`}
                            classes={{
                                title: classes.cardTitleInfo,
                                root: classes.cardHeaderInfo    
                            }}
                            action={ <ActionsShow classes={classes} onClose={this.onClose}/>}
                        />
                        <CardContent classes={{
                                root: classes.cardContent
                            }}
                        >
                            <div className={classes.searchRow}>
                                <Field
                                    name="barcode"
                                    component={TextInput}
                                    label={`Штрих-код`}
                                    className={classes.name}
                                    normalize={this.formatBarcode}
                                />
                                <div style={{marginLeft: 16, marginTop: 16}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<SearchIcon />}
                                        size="small"
                                        disabled={invalid}
                                        onClick={() => this.searchBarcode(formValues)}
                                    >
                                        Найти
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.info} >
                                {(!loading&&!data.id)
                                    ?
                                        <div className={classes.notFoundText}>Заказ не найден</div>
                                    :
                                        <div>
                                            {data&&
                                                <div style={{display:'flex'}}>
                                                    <Labeled label="ФИО клиента" className={classes.flex60}>
                                                        <TextField record={patient} source="fullname"/>
                                                    </Labeled>
                                                    <Labeled label="Пол" className={classes.flex20}>
                                                        <SelectField
                                                            source="gender"
                                                            record={patient}
                                                            options={[
                                                                {name: "Муж", id: "M"},
                                                                {name: "Жен", id: "F"},
                                                            ]}
                                                        />
                                                    </Labeled>
                                                    <Labeled label="Дата рождения" className={classes.flex20}>
                                                        <TextField record={patient} source="dateOfBirth"/>
                                                    </Labeled>
                                                </div> 
                                            }
                                            {data&&
                                                <div style={{display:'flex'}}>
                                                    <Labeled label="Дата заказа" className={classes.flex20}>
                                                        <TextField record={data} source="OrderDate"/>
                                                    </Labeled>
                                                    <Labeled label="Срок" className={classes.flex20}>
                                                        <TextField record={data} source="DeadLineDate"/>
                                                    </Labeled>
                                                    <Labeled label="Путнкт" className={classes.flex20}>
                                                        <TextField record={data} source="WorkSpace"/>
                                                    </Labeled>
                                                </div> 
                                            }
                                            {(data&&data.Services)&&
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                                #
                                                            </TableCell>
                                                            <TableCell style={{width: '7%'}} classes={{ root: classes.cellRoot}}>
                                                                Код
                                                            </TableCell>
                                                            <TableCell style={{width: '60%'}} classes={{ root: classes.cellRoot}}>
                                                                Наименование
                                                            </TableCell>
                                                            <TableCell style={{width: '23%'}} classes={{ root: classes.cellRoot}}>
                                                                Статус
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.Services.map( (item, i )=>
                                                            <TableRow key={i}>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    <b>{i+1}</b>
                                                                </TableCell>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    <b>{item.ServiceCode}</b>
                                                                </TableCell>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    {item.ServiceName}
                                                                </TableCell>
                                                                <TableCell  classes={{ root: classes.cellRoot}}>
                                                                    {item.ServiceStatus}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            }
                                        </div>

                                }
                            </div>
                        </CardContent>
                    </Card>
                ) : null}
            </Drawer>
        </div>
    );
  }
}

const mapStateToProps = (state, props) =>{
    const { fetch, barcode } = state;
    var recordLiveValues = getFormValues('barcodeForm')(state);
    return {
        barcode,
        formValues: recordLiveValues,
        isLoading: !!fetch.loading
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    reduxForm({
        form: 'barcodeForm',
        validate: (values) => {
            const errors = {};
            if (!values.barcode) {
                errors.barcode = 'Обазятельное поле'
            } 
            return errors;
        },
        destroyOnUnmount: false
    })
)(Barcode);