import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';

import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';


const styles = theme =>({
  button: {
    marginLeft: theme.spacing(1),
  },
  close: {
      color: "#f44336",
      border: "1px solid #f4433663",
      '&:hover': {
          border: '1px solid #f44336',
          backgroundColor: '#ff000008',
      }
  },
})


let openConfirmFn;

class Confirm extends React.Component {
  state = {
    open: false,
    message: '',
    title: 'Закрыть?',
    confirmLabel: 'Закрыть'
  };

  componentDidMount() {
    openConfirmFn = this.openConfirm;
  }

  openConfirm = ({title, message , ...props}) => {
    this.setState({ open: true, title, message, ...props });
  };

  onClose = () => {
    this.setState({ open: false });
  }

  onConfirm = () =>{
    this.setState({ open: false });
    this.state.onConfirn();
  }

  render() {
    const { open, title, message, confirmLabel  } = this.state;
    const { classes } = this.props;
    return (
      <Dialog
        open={open}
        TransitionComponent={Slide}
        keepMounted
        onClose={this.handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            className={classes.button}   
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.onConfirm()} 
          >
            { confirmLabel }
          </Button>
          <Button 
            size="small" 
            variant="outlined"
            onClick={ () => this.onClose()} 
            color="primary"
            className={classes.close}
            startIcon={<CloseIcon />}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default  compose(
  withStyles(styles),
)(Confirm);


export function openConfirm(obj, onConfirm, onClose) {
  return openConfirmFn(obj, onConfirm, onClose);
}