import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormValues, Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { DateInput, SelectInput } from '../tpls/input';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    date: {
        display: 'flex',
        width: theme.spacing(15),
        marginRight: 20
    },
    queue: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    actions: {
        marginLeft: 'auto',
    },
    actionsButton: {
        marginRight: 20,
        marginBottom: 8
    },
    button: {
        margin: 12,
    },
    
});

class StatisticsFilter extends Component {
    state = {}

    componentDidMount(){
        const { initialize, defaultFormValues } = this.props;
        initialize(defaultFormValues);
        this.props.fetchData(defaultFormValues);
    }

    refresh = () => {
        const { fetchData, formValues  } = this.props;
        fetchData(formValues);
    }

    render(){
        const { classes, formValues = {}, isLoading } = this.props;
        return ( 
            <Toolbar className={classes.toolbar}>
                {formValues&&
                    <Fragment>
                        <Field
                            name="startDate"
                            component={DateInput}
                            label={`С`}
                            disabled={isLoading}
                            className={classes.date}
                            maxDate={new Date(formValues.endDate)}
                        />
                        <Field
                            name="endDate"
                            component={DateInput}
                            label={`По`}
                            disabled={isLoading}
                            className={classes.date}
                            minDate={new Date(formValues.startDate)}
                            maxDate={ new Date() }
                        />
                        <Field
                            name="interval"
                            component={SelectInput}
                            label={`Интервал`}
                            disabled={isLoading}
                            className={classes.date}
                            options={[
                                {id: 15, name: '15 минут'},
                                {id: 30, name: '30 минут'},
                                {id: 60, name: '1 час '},
                                {id: 1440, name: 'День'},
                            ]}
                        />
                        {/* <Field
                            name="queue"
                            component={SelectInput}
                            label={`Очередь`}
                            disabled={isLoading}
                            className={classes.queue}
                            options={[
                                {id: '-', name: 'Все'},
                                {id: 'Synevo', name: 'Synevo'},
                                {id: 'Synevo - COVID', name: 'Synevo - COVID'},
                            ]}
                        /> */}
                        <div className={classes.actions}>
                            <Button 
                                className={classes.actionsButton}
                                color="primary" 
                                size="small"
                                variant="outlined"
                                startIcon={isLoading 
                                    ? <CircularProgress size={20} thickness={2} />
                                    : <RefreshIcon />
                                }
                                onClick={this.refresh}
                                disabled={isLoading}
                            > 
                                Обновить
                            </Button>
                        </div>
                    </Fragment>
                }
            </Toolbar> 
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('statisticsForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
    reduxForm({
        form: 'statisticsForm',
        validate: (values) => {
            const errors = {};
            if (!values.startDate) {
                errors.endDate = 'Обазятельное поле'
            } 
            if (!values.endDate) {
                errors.endDate = 'Обазятельное поле'
            } 
            return errors;
        },
        destroyOnUnmount: false
    })
)(StatisticsFilter);