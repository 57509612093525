import { basketConstants } from '../_constants';

const { ADD_TO_BASKET, CLEAR_BASKET } = basketConstants;
let order = []

export function basket (state = { order }, action) {
  switch (action.type) {
    case ADD_TO_BASKET:
        order.push(action.code);
        return order
    case CLEAR_BASKET:
        order = [];
        return order;
    default:
      return order
  }
}