import { authHeader, history, notify } from '../_helpers';
import { alertActions } from '../_actions';
import { alertConstants } from '../_constants';


export const userService = {
    login,
    logout,
    reset,
    get,
    update,
};

function login({username, password}) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/v1/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', user.token);
            return user;
        })
        .catch( error => {
            alertActions({type: alertConstants.ERROR, message: error})
            return;
        })
}

function reset({username}) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
    };
    return fetch(`/v1/reset`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        })
        .catch( error => {
            alertActions({type: alertConstants.ERROR, message: error})
            return;
        })
}

function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('permission');
    history.push('/login');
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                notify({message: data.message, type: 'error'});
                // auto logout if 401 response returned from api
                logout();
                // window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}