import React from 'react';
import { connect } from 'react-redux';
import {compose} from 'recompose';

import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
    main: {
        marginTop: 16,
        // color: '#0a529f',
        fontSize: 14,
        marginBottom: 8,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        // color: '#0a529f',
        width: '100%',
        border: 0,
        margin: 0,
        padding: '0 0 8px',
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
    label: {
        position: 'relative',
        fontWeight: 600,
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        // color: '#0a529f',
        padding: '16px 0 0',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1
    }
}

const Labeled = ({classes, style, className, children, isLoading, ...props}) =>{
    return (
        <div className={className} style={{...style, ...styles.main}} >
            <label className={classes.label}>
                {props.label}
            </label>
            <div className={classes.div}>
                    {isLoading&&
                        <LinearProgress style={{width: '90%'}}/>
                    }
                    {!isLoading && (children ? React.cloneElement(children, { value: children.props.record[children.props.source] }) : null) }
            </div>
        </div>
    )
}

const mapState = (state) => {
    const { fetch } = state;
    const isLoading = fetch ? (fetch.loading>0 ? true : false) : false;
    return { 
        isLoading,
    }
}

export default compose(
    connect(mapState),
    withStyles(styles),
)(Labeled);
