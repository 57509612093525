import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    color: '#bbd9ea',
    backgroundColor: "#0a529f",
  },
  error: {
    backgroundColor: "#e57373",
  },
  info: {
    color: '#0a529f',
    backgroundColor: "#bbd9ea",
  },
  warning: {
    backgroundColor: "#fbb725",
    color: '#0a529f',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

let openSnackbarFn;

class Notifier extends React.Component {
  state = {
    open: false,
    message: '',
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: '',
    });
  };

  openSnackbar = (obj) => {
    this.setState({ open: true, ...obj });
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;
    const Icon = this.state.type ? variantIcon[this.state.type] : InfoIcon;
    const message = (
      <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {this.state.message}
      </span>
      
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Slide}
        autoHideDuration={3000}
        open={open}
        onClose={this.handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'snackbar-message-id',
        }}
      >
        <SnackbarContent
          className={clsx(classes[this.state.type])}
          aria-describedby="client-snackbar"
          message={message}
          action={[
            <IconButton size="small" key="close" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
              <CloseIcon size="small" className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(Notifier);

export function openSnackbar(obj) {
  openSnackbarFn(obj);
}