import React, { Component } from 'react';
import compose from 'recompose/compose';
import {  
    getFormValues,
} from 'redux-form';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import LinearProgress from '@material-ui/core/LinearProgress';

import SuggestionsField from './SuggestionsField'

export const styles = theme => createStyles({
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    cardStyleLeft: {
        height: `calc(100vh - ${theme.spacing(23)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '45%',
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
    },
    cardTitle: {
        color: '#ffffff;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#3cb66a'
    },
})
class AnalysisCard extends Component {
    state = {
    };
    
    render() {
        const { 
            classes, 
            formValues, 
            selected,
            addAnalys,
            onInfo,
            choices, 
            panels 
        } = this.props;
        return (
            <Card  className={classes.cardStyleLeft}>
                <CardHeader
                    classes={{
                        title: classes.cardTitle,
                        root: classes.cardHeader
                    }}
                    title="Справочник услуг"
                />
                {!choices&&
                    <CardContent style={{padding: 0}}>
                        <LinearProgress color="secondary" /> 
                    </CardContent>
                } 
                <CardContent classes={{
                    root: classes.cardContent
                }} >
                    {(formValues&&choices&&panels)&&
                        <SuggestionsField 
                            choices={choices} 
                            panels={panels} 
                            selected={selected} 
                            onAdd={addAnalys}
                            onInfo={onInfo} 
                        />
                    }
                </CardContent>
            </Card>
        )
    }
}


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    )
);


export default enhance(AnalysisCard);
