import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import { Labeled, TextField, DateField } from '../tpls/fields'

import ActionsShow from './componets/ActionsShow';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    }
});

class VisorShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            calculate: false,
            summary: {
                deadline: '-',
                additionalPrice: '-',
                analysisPrice: '-',
                summa: '-',
            }
        };
    }
    
    componentDidMount(){
        if(this.props.id){
            this.fetchData(this.props.id)
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(data) {
          this.setState({ data });
        }
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;
        return (
            <Card  className={classes.cardStyleShow}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleShow,
                        root: classes.cardHeaderShow
                    }}
                    action={ <ActionsShow data={data}/>}
                />
                <CardContent>
                <div style={{display: 'flex'}}>
                        <div>
                            <Labeled label="Телефон" className={classes.inline}>
                                <TextField record={data} source="Address"/>
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Дата / время" className={classes.inline}>
                                <DateField record={data} source="StartTime" format="DD.MM.YYYY HH:mm:ss" />
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Длит" className={classes.inline}>
                                <TextField record={data} source="Duration"/>
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Опреатор" className={classes.inline}>
                                <TextField record={data} source="Agent"/>
                            </Labeled>
                        </div>
                    </div>
                </CardContent>
            </Card>
                
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(VisorShow);
