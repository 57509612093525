import React, { Component, Fragment } from 'react';
import {createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Badge
} from '@material-ui/core';
import { Labeled, TextField } from '../../tpls/fields'

import DeleteButton from './DeleteButton';
import QuickSuggestionsField from './QuickSuggestionsField';

export const styles = theme => createStyles({
    header: {
        padding: 0, 
        display: 'flex'
    },
    table: {
        padding: 1,
        // fontSize: 11,
        color: theme.palette.text.primary,
    },
    center: {
        textAlign: 'center'
    },
    tableHeader: {
        padding: 1,
        fontSize: 11,
        textAlign: 'center'
    },
    inline: { 
        display: 'inline-block', 
        paddingRight: 40,
        '& label' : {
            fontSize: 'small',
            color: '#084c92'
        },
        '& div span' : {
            fontWeight: 600,
            color: '#084c92'
        },
    },
    scrolling:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(38)}px)`
    },
    notFound:{
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    badge: {
        paddingLeft: 40,
        marginTop: 5,
    }
});

class AnalysisTable extends Component {
    state = {};
    
    backgroundColor(item, analysis) {
        if(item.isAnalys){
            if(analysis.indexOf(item.Code)<0){
                return '#ebebeb'
            }else{
                return !!item.usedInComplex ? '#f4433652': '#3cb66a40'
            }
        }else{
            return "white";
        }
    }

    render() {
        const { 
            classes, 
            analysis, 
            deleteElement, 
            summary, 
            choices, 
            panels, 
            addAnalys,
            show, 
            data = [] 
        } = this.props;
        return (
            <Fragment>
                <div className={classes.header}>
                    <div>
                        <Labeled label="Срок исполнения" className={classes.inline}>
                            <TextField record={summary} source="deadline"/>
                        </Labeled>
                    </div>
                    <div style={{ marginLeft: 'auto'}}>
                        <Labeled label="Доп. услуги" className={classes.inline}>
                            <TextField record={summary} source="additionalPrice"/>
                        </Labeled>
                    </div>
                    <div style={{ marginLeft: 'auto'}}> 
                        <Labeled label="Стоимость анализов" className={classes.inline}>
                            <TextField record={summary} source="analysisPrice"/>
                        </Labeled>
                    </div>
                    <div style={{ marginLeft: 'auto'}}>
                        <Labeled label="Итого" className={classes.inline}>
                            <TextField record={summary} source="summa"/>
                        </Labeled>
                    </div>
                </div>
                {!show&&
                    <QuickSuggestionsField 
                        choices={choices} 
                        panels={panels}
                        onAdd={addAnalys}
                    />
                }
                <div className={classes.scrolling}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
                                    #
                                </TableCell>
                                <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
                                    Код
                                </TableCell>
                                <TableCell className={classes.tableHeader} style={{width: '60%', textAlign: 'center'}}>
                                    Наименование / Примечание / Биоматериал
                                </TableCell>
                                <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
                                    Прил.
                                </TableCell>
                                <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
                                    Срок
                                </TableCell>
                                <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
                                    Цена
                                </TableCell>
                                {!show&&
                                    <TableCell className={classes.tableHeader} style={{width: '5%'}}>
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        {(data.length>0) ?
                            <TableBody>
                                {data.map( (item, index )=>
                                    <TableRow key={index} style={{
                                            backgroundColor: this.backgroundColor(item, analysis),
                                            height: 30 
                                    }}>
                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                            {index+1}
                                        </TableCell>
                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                            {item.Code}
                                        </TableCell>
                                        <TableCell className={classes.table}>
                                            {/* <AnalysDialog label={item.label || item.Name} item={item} /> */}
                                            {item.label || item.Name}
                                            {item.isComplex&&
                                                <Badge className={classes.badge} badgeContent={'Комплекс'} color="primary"/>
                                            }
                                            {item.description&&
                                                <div style={{ color: 'red' }} dangerouslySetInnerHTML={{__html: item.description}} />
                                            }
                                            {(item.materials&&item.materials.length>0) &&
                                                <div>
                                                    {item.materials.map( (material, i) =>
                                                            <b style={{color: '#607D8B', paddingRight: 5}} key={i}>{material}</b>
                                                    )}
                                                </div>
                                            }
                                            {(item.usedInComplex&&item.usedInComplex.length>0)&&
                                                <div><b  style={{color: 'red'}}>Анализ входит в комплекс: {item.usedInComplex.join(', ')}</b></div>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                            {item.application}
                                        </TableCell>
                                        <TableCell className={classnames(
                                                                    classes.table,
                                                                    classes.rightAlignedCell
                                                            )}  style={{textAlign: 'center'}}>
                                            {item.WorkingDays}
                                        </TableCell>
                                        <TableCell
                                            className={classnames(
                                                                classes.table,
                                                                classes.rightAlignedCell,
                                                        )}
                                            style={{textAlign: 'center'}}>
                                            {item.Price}
                                        </TableCell>
                                        {!show&&
                                            <TableCell className={classes.table} style={{textAlign: 'center', paddingRight: 0}}>
                                                {analysis.indexOf(item.Code)>=0&&
                                                    <DeleteButton action={deleteElement} item={item}/>
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        :
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.notFound} colSpan={7}>
                                        Нет анализов для расчета
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                </div>
            </Fragment>
        );
    }
}


export default compose(
    withStyles(styles),
)(AnalysisTable);
