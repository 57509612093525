import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, UPDATE, GET_LIST,  provider } from '../Utils/_services/provider';

import { TextInput, SelectInput } from '../tpls/input';
import Actions from './Actions';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        flex: '60em'
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    firstName: {
        flex: "50em"
    },
    lastName: {
        marginLeft: theme.spacing(2),
        flex: "50em"
    },
    email: {
        flex: "50em"
    },
    role: {
        marginLeft: theme.spacing(2),
        flex: "25em"
    },
});

const submit = values =>{
    const { last_name, first_name} = values;
    provider(
        UPDATE,
        '/profile',
        {
            data: {
                last_name, 
                first_name
            }
        }
    );
}

class ProfileEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            roles: []
        };
        this.fetchData = this.fetchData.bind(this);
    }
    
    async componentDidMount(){
        this.fetchData(this.props.id)
        const { data } = await provider(
            GET_LIST,
            '/roles',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const roles = data.map( d => {return { id: d.id, name: d.label }})
        this.setState({
            roles
        });
    }

    async fetchData() {
        const { data } = await provider(
            GET_ONE,
            `/profile`
        );
        if(data) {
            const { initialize } = this.props;
            initialize(data);
        }
    }

    render() {
        const { formValues, classes, isSaving } = this.props;
        const { roles } = this.state;
        return (
            <Card  className={classes.root}>
                <CardHeader
                    action={ <Actions changeStatus={this.changeStatus}/>}
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    {formValues&&
                        <div>
                            <div style={{display:'flex'}}>
                                <Field
                                    name="last_name"
                                    component={TextInput}
                                    label={`Фамилия`}
                                    disabled={isSaving}
                                    className={classes.firstName}
                                    normalize={this.formatName}
                                />
                                <Field
                                    name="first_name"
                                    component={TextInput}
                                    label={`Имя`}
                                    disabled={isSaving}
                                    className={classes.lastName}
                                    normalize={this.formatName}
                                />
                            </div> 
                            <div style={{display:'flex', paddingTop: 16}}>
                                <Field
                                    name="email"
                                    component={TextInput}
                                    label={`E-mail`}
                                    disabled={true}
                                    className={classes.email}
                                    normalize={this.email}
                                />
                                <Field
                                    name="role"
                                    component={SelectInput}
                                    label={`Роль`}
                                    disabled={true}
                                    options={roles}
                                    className={classes.role}
                                />
                            </div> 
                        </div>
                    }
                </CardContent>
            </Card>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('profileForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
    reduxForm({
        form: 'profileForm',
        onSubmit: submit,
        validate: (values) => {
            const errors = { };
            if (!values.name_first) {
                errors.name_first = 'Обазятельное поле'
            }
            if (!values.name_second) {
                errors.name_second = 'Обазятельное поле'
            }
            return errors;
        },
        destroyOnUnmount: false,
    })
);

export default enhance(ProfileEdit);
