import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = createStyles({
    toolbar: {
        // justifyContent: 'space-between',
    },
    actions: {
        marginLeft: 'auto',
    },
    actionsButton: {
        marginRight: 20,
        marginBottom: 8
    }
});

const ListToolbar = ({
    classes,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    bulkActions,
    exporter,
    isLoading,
    fetchData,
    setCreate,
    creat,
    refresh,
    ...rest
}) => {
    return (
        <Toolbar className={classes.toolbar}>
            {filters &&
                React.cloneElement(filters, {
                    ...rest,
                    filterValues,
                    context: 'form',
                })
            }
            <div className={classes.actions}>
                {refresh&&
                    <Button 
                        className={classes.actionsButton}
                        color="primary" 
                        size="small"
                        variant="outlined"
                        startIcon={isLoading 
                            ? <CircularProgress size={20} thickness={2} />
                            : <RefreshIcon />
                        }
                        onClick={() => fetchData({refresh: true})}
                        disabled={isLoading}
                    > 
                        Обновить
                    </Button>
                }
                {creat&&
                    <Button 
                        className={classes.actionsButton}
                        color="primary" 
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setCreate()}
                    > 
                        Создать
                    </Button>
                }
                {actions &&
                    React.cloneElement(actions, {
                        ...rest,
                        className: classes.actionsButton,
                        bulkActions,
                        exporter,
                        filters,
                        filterValues,
                        permanentFilter,
                        ...actions.props,
                })}
            </div>
        </Toolbar>
    );
} 

ListToolbar.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.element,
    permanentFilter: PropTypes.object,
    actions: PropTypes.element,
    bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

ListToolbar.defaultProps = {
    classes: {},
};

export default withStyles(styles)(ListToolbar);
