import React, { Component } from 'react';
import compose from 'recompose/compose';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import deburr from 'lodash/deburr';
import { 
  withStyles,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';

import { 
  Info as InfoIcon,  
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Close as CloseIcon
} from '@material-ui/icons';

import AddButton from './AddButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
  },
  inputRoot: {
    flexWrap: 'wrap',
    color: theme.palette.text.primary,
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  search: {
    paddingBottom: theme.spacing(1)
  },
  list: {
    position: 'relative',
    overflow: 'auto',
    height: `calc(100vh - ${theme.spacing(35)}px)`
  },
  listItem: {
    padding: '0 0 0 3px',
    borderBottom: '1px solid #0a529f',
    minHeight: theme.spacing(4)
  },
  listIcon:{
    minWidth: theme.spacing(4)
  },
  textPrimary:{
    fontSize: 11,
    color: theme.palette.text.primary,
    fontWeight: 600,
    minWidth: theme.spacing(4)
  },
  textCode:{
    fontSize: 11,
    color: theme.palette.text.primary,
    // fontWeight: 600,
    minWidth: theme.spacing(5)
  },
  textSecondary:{
    color: theme.palette.text.primary,
    fontSize: 11,
    lineHeight: 1,
    letterSpacing: 0
  },
  textSecondaryError:{
    color: 'red',
    fontSize: 11,
    lineHeight: 1,
    letterSpacing: 0
  },
  ul: {
    paddingInlineStart: 0,
  },
  sticky: {
    borderBottom: '1px solid',
    backgroundColor: '#f4f6f9',
    lineHeight: `${theme.spacing(3)}px`,
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.text.primary,
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center'
  },
  find: {
    marginLeft: 'auto',
    fontSize: 14,
    marginTop: 10,
    color: theme.palette.primary.main,
  },
  notFound:{
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  panelButton: {
    marginLeft: 'auto',
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: '#f29f336b',
    }
  },
  price:{
    width: 50
  },
  code: {
    width: 60
  }
});

const Row = ({ suggestion, selected, onAdd, onInfo, formValues = {}, classes}) =>{
  const {sex, data = [], analysis = [], city} = formValues;
  const used = analysis.indexOf(suggestion.code)>=0 || data.findIndex( d=> d.Code===suggestion.code)>=0;
  const complexes = data.filter( d => d.isComplex);
  let inComplex = [];
  for (let index = 0; index < complexes.length; index++) {
    const element = complexes[index];
    const indexInComplex = element.inComplex.indexOf(suggestion.code);
    if(indexInComplex>=0){
      inComplex.push(element.Code);
    }
  }
  let errorMessage = null;
  if(used){
    errorMessage =  'Услуга в корзине';
  }else if(suggestion.deleted){
    errorMessage =  'Услуга заблокирована';
  }else if(suggestion.noAnalysis){
    errorMessage =  `Не выполняется в лабораторях Helix`;
  }else if(!suggestion.availability){
    errorMessage =  'Не доступен к заказу';
  }else if(suggestion.minsk_only&&city!=='5d985468ea4291b90b636d53'){
    errorMessage =  `Выполняется только в г.Минске`;
  }else if(suggestion.sex === "F" && sex!=='F'){
      errorMessage =  'Данный анализ доступен только для женщин';
  }else if(suggestion.sex === "M" && sex!=='M'){
    errorMessage = 'Данный анализ доступен только для мужчин';
  }else if(inComplex.length>0){
    errorMessage =  `Анализ входит в комплекс: ${inComplex.join(", ")}`;
  }
  return(
    <ListItem 
      button 
      classes={{
        root: classes.listItem
      }}
      style={{
        backgroundColor : selected===suggestion.id&&!suggestion.noAnalysis ? "#3cb66a40" : (used||!!errorMessage) && '#9e9e9e9c'
      }}
    >
        <ListItemIcon className={classes.code} classes={ {root: classes.textCode}}>
          <span>{suggestion.code}</span>
        </ListItemIcon>
        <ListItemText 
          classes={{
            primary: classes.textPrimary,
            secondary: errorMessage ? classes.textSecondaryError : classes.textSecondary
          }}
          primary={suggestion.name} 
          secondary={errorMessage ? errorMessage : suggestion.analog.join(', ')} 
        />
        <ListItemIcon classes={ {root: classes.textPrimary}}>
          <span>{suggestion.deadline&&`${suggestion.deadline} д`}</span>
        </ListItemIcon>
        <ListItemIcon className={classes.price} classes={ {root: classes.textPrimary}}>
          <span>{suggestion.price&&`${suggestion.price}р`}</span>
        </ListItemIcon>
        <ListItemIcon classes={ {root: classes.listIcon}}>
          {!suggestion.noAnalysis 
            ?
              <IconButton size='small' style={{color:  "#1450a1"}} onClick={() => onInfo(suggestion)} aria-label="Информация">
                <InfoIcon fontSize="small" />
              </IconButton>
            :
              <span></span>
          }
        </ListItemIcon>
        <ListItemIcon classes={ {root: classes.listIcon}}>
          <AddButton action={onAdd} disabled={used||!!errorMessage} value={suggestion} />
        </ListItemIcon>
    </ListItem>
 );
}

class IntegrationDownshift extends Component{
  constructor(props){
    super(props);
    this.state = {
      suggestions: [],
      search: '',
      count: 0,
      currentDetailIndex: 0,
      numOfListItems: 10,
      panel: null
    }

    this.renderInput = this.renderInput.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.addItem = this.addItem.bind(this);
    this.setPanel = this.setPanel.bind(this);
  }
  
  moveUp = () => {
      if (this.state.currentDetailIndex > 0) {
          this.setState({ currentDetailIndex: this.state.currentDetailIndex - 1 });
      }
  }

  moveDown = () => {
      if (this.state.currentDetailIndex < this.state.numOfListItems - 1) {
          this.setState({ currentDetailIndex: this.state.currentDetailIndex + 1 });
      }
  }

  onKeyPressed = (e) => {
      if (e.keyCode === '38') {
          // up arrow
          this.moveUp();
      }
      else if (e.keyCode === '40') {
          // down arrow
          this.moveDown();
      }
  }

  renderInput(inputProps) {
    const { InputProps, value, classes, count = 0, ref, ...other } = inputProps;
    const onPress = (event) => {
      this.setState({search: event.target.value})
    }
    const error = this.state.search.length!==""&&this.state.search.length<3;
    return (
      <div style={{display: 'flex'}}>
        <TextField
          style={{width: '80%'}}
          type="search"
          value={this.state.search}
          // error={error}
          helperText={error ? 'Минимум 3 символа' : ' '}
          InputProps={{
            inputRef: ref,
            classes: {
              root: classes.inputRoot,
              input: classes.inputInput,
            },
            ...InputProps,
          }}
          onChange={onPress}
          {...other}
        />
        {count>0&&
          <span className={classes.find}>{`Найдено: ${count}`}</span>
        }
      </div>
    );
  }

  getSuggestions(value, suggestions) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    // let count = 0;
    const result = inputLength < 3
      ? []
      : suggestions.filter(suggestion => {
          if(!suggestion.other_name){
            suggestion.other_name = '';
          }
          try {
              const name = suggestion.name.toLowerCase().trim().search(inputValue)>=0;
              const code = suggestion.code.toString().toLowerCase().trim().search(inputValue)>=0;
              const other_name = suggestion.other_name.toLowerCase().trim().search(inputValue)>=0;
              const analog = suggestion.analog.filter( a =>a.toLowerCase().trim().search(inputValue)>=0);
              const keep = ( name || code || other_name || analog.length>0) ; //count < 20 && 
              // if (keep) {
              //   count += 1;
              // }
              return keep;
          }
          catch(e){
            console.log(e);
            return false;
          }
        });
    return result;
  }

  addItem(value){
    this.props.onAdd(value);
  }

  setPanel(panel){
    this.setState({ panel: panel })
  }

  render(){
    const { classes, choices, selected, panels, ...props } = this.props;
    const { search, panel } = this.state;
    let filteredPanels = panels;
    let suggastions = this.getSuggestions(search, choices);
    if(panel){
      filteredPanels = panels.filter( p => p.code === panel);
      if(search.length<3){
        if(filteredPanels.length>0){
          suggastions = choices.filter( c => c.panel===panel);
        }else if(panel==='isComplex'){
          suggastions = choices.filter( c => c.isComplex);
        }else if(panel==='noAnalysis'){
          suggastions = choices.filter( c => c.noAnalysis);
        }
      }else{
        if(filteredPanels.length>0){
          suggastions = suggastions.filter( c => c.panel===panel);
        }else if(panel==='isComplex'){
          suggastions = suggastions.filter( c => c.isComplex);
        }else if(panel==='noAnalysis'){
          suggastions = suggastions.filter( c => c.noAnalysis);
        }
      }
    }
    const inComplex = suggastions.filter( s => s.isComplex);
    const noAnalysis = suggastions.filter( s => s.noAnalysis===true);
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.search}>
            {this.renderInput({
              fullWidth: false,
              classes,
              count: suggastions ? suggastions.length : null,
              InputProps: {
                placeholder: 'Введите код анализа, название или его аналоги',
              },
            })}
          </div>
          <div className={classes.list}>
            <List 
              onKeyDown={this.onKeyPressed} 
              className={classes.root} 
              subheader={<li />}
            >
              {((search.length<3&&!panel) || inComplex.length>0 || panel==='isComplex')&&
                <li className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader classes={{sticky: classes.sticky}}>
                      КОМПЛЕКСНЫЕ ИСЛЕДОВАНИЯ
                      <IconButton 
                        className={classes.panelButton} 
                        size='small' 
                        aria-label="Показать детали"  
                        style={{color: panel&&'red'}}
                        onClick={() => this.setPanel(!panel ? "isComplex" : null)} 
                      >
                        {!panel 
                          ?
                            <KeyboardArrowRightIcon size='small'/>
                          :
                            <CloseIcon size='small'/>
                        }
                      </IconButton>
                    </ListSubheader>
                    {inComplex.map((suggestion, index) =>
                      <Row 
                        suggestion={suggestion} 
                        index={index} 
                        key={index} 
                        selected={selected}
                        classes={classes}
                        onAdd={this.addItem}
                        {...props}
                      />
                    )}
                    {(inComplex.length===0 && search.length>=3)&&
                          <Typography className={classes.notFound}>
                            Результатов не найдено
                          </Typography>
                    }
                  </ul>
                </li>
              }
              {filteredPanels.map((p, i) => {
                const inPanel = suggastions.filter( s => s.panel === p.code);
                if(search.length<3 || inPanel.length>0 || panel){
                  return (
                    <li key={i} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader classes={{sticky: classes.sticky}}>
                          {p.code} {p.name}
                          <IconButton 
                            className={classes.panelButton} 
                            size='small' 
                            aria-label="Показать детали" 
                            style={{color: panel&&'red' }}
                            onClick={() => this.setPanel(!panel ? p.code : null)} 
                          >
                            {!panel 
                              ?
                                <KeyboardArrowRightIcon size='small'/>
                              :
                                <CloseIcon size='small'/>
                            }
                          </IconButton>
                        </ListSubheader>
                        {inPanel.map((suggestion, index) =>
                          <Row 
                            suggestion={suggestion} 
                            index={index} 
                            key={index} 
                            selected={selected}
                            classes={classes}
                            onAdd={this.addItem}
                            {...props}
                          />
                        )}
                        {(inPanel.length===0 && search.length>=3)&&
                          <Typography className={classes.notFound}>
                            Результатов не найдено
                          </Typography>
                        }
                      </ul>
                    </li>
                  )
                }else{
                  return null;
                }
              })}
              {((search.length<3&&!panel) || noAnalysis.length>0 || panel==='noAnalysis')&&
                <li className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader classes={{sticky: classes.sticky}}>
                      НЕ ВЫДОЛНЯЮТСЯ В ЛАБОРАТОРИЯХ Helix
                      <IconButton 
                        className={classes.panelButton} 
                        size='small' 
                        aria-label="Показать детали"  
                        style={{color: panel&&'red'}}
                        onClick={() => this.setPanel(!panel ? "noAnalysis" : null)} 
                      >
                        {!panel 
                          ?
                            <KeyboardArrowRightIcon size='small'/>
                          :
                            <CloseIcon size='small'/>
                        }
                      </IconButton>
                    </ListSubheader>
                    {noAnalysis.map((suggestion, index) =>
                      <Row 
                        suggestion={suggestion} 
                        index={index} 
                        key={index} 
                        selected={selected}
                        classes={classes}
                        onAdd={this.addItem}
                        {...props}
                      />
                    )}
                    {(noAnalysis.length===0 && search.length>=3)&&
                          <Typography className={classes.notFound}>
                            Результатов не найдено
                          </Typography>
                    }
                  </ul>
                </li>
              }
              {(suggastions.length===0 && search.length>=3 && !panel)&&
                <Typography className={classes.notFound}>
                  Результатов не найдено
                </Typography>
              }
            </List>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var recordLiveValues = getFormValues('calculatorForm')(state);
  return { formValues: recordLiveValues};
};

const enhance = compose(
  withStyles(styles),
  connect(
      mapStateToProps
  )
);

export default enhance(IntegrationDownshift);