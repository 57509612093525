import React, { Component } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router";
import {compose} from 'recompose';
import Tooltip from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography';

import { Icon } from '@iconify/react';
import iLaboratory from '@iconify/icons-medical-icon/i-laboratory';
import calculatorIcon from '@iconify/icons-mdi/calculator';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ContactsIcon from '@material-ui/icons/Contacts';
import HomeIcon from '@material-ui/icons/Home';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import BarChartIcon from '@material-ui/icons/BarChart';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
// import AssignmentIcon from '@material-ui/icons/Assignment';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocationCityIcon from '@material-ui/icons/LocationCity';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import SettingsIcon from '@material-ui/icons/Settings';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import uploadIcon from '@iconify/icons-mdi/upload';
// import DescriptionIcon from '@material-ui/icons/Description';
// import PersonIcon from '@material-ui/icons/Person';

// import { userActions } from '../_actions';
// import { colorConstants } from '../_constants'
import Responsive from '../responsive';

import { permissions } from '../../Utils/_helpers';

const drawerWidth = 240;
const styles = theme =>{
    return ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            paddingTop: 48,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            paddingTop: 48,
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            paddingTop: 48,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(11) + 1,
        },
        list:{
            paddingTop: 0,
            paddingBottom: 48,
            overflow: 'auto'
        },
        listIcon:{
            color: theme.palette.text.primary,
            justifyContent: 'center'
        },
        listItem: {
            flexDirection: 'column',
            color: theme.palette.text.primary,
            "&.Mui-selected": {
                // backgroundColor: colorConstants.primary,
                '& div':{
                    color: theme.palette.text.primary,
                    '& p':{
                        color: theme.palette.text.primary,
                    }
                }
            },
            "&:hover": {
                color: 'red',
                // '& div':{
                //     color: colorConstants.yellow
                // },
            }
        },
        title: {
            fontSize: '0.61rem',
            fontWeight: '600',
            color: theme.palette.text.primary,
        }
    });
} 


export const MenuItems = [
    {
        to: '/',
        title: 'Главная',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <HomeIcon icon={calculatorIcon} width="22" height="22" />
    },
    {
        to: '/calculator',
        title: 'Калькулятор',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <Icon icon={calculatorIcon} width="22" height="22" />
    },
    // {
    //     to: '/calculator',
    //     title: 'Калькулятор',
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <Icon icon={calculatorIcon} width="22" height="22" />
    // },
    {
        to: '/analysis',
        title: 'Справочник',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <Icon icon={iLaboratory} width="22" height="22" />
    },
    {
        to: '/discount/acting',
        title: 'Акции',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <LoyaltyIcon />
    },
    {
        to: '/partners',
        title: 'Партнеры',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <ContactsIcon />
    },
    {
        to: '/insurance',
        title: 'Страховые',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AccountBalanceIcon />
    },
    {
        to: '/reference',
        title: 'Правила',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AnnouncementIcon />
    },
    {
        to: '/centers',
        title: 'Пункты',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <LocationCityIcon />
    },
    {
        to: '/visor',
        title: 'Прослушка',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <VolumeUpIcon />
    },
    // {
    //     to: '/reports',
    //     title: 'Обращения',
    //     permissions: ['admin', 'supervisor'],
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <AssignmentIcon />
    // },
    {
        to: '/statistics',
        title: 'Статистика',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <BarChartIcon />
    },
    // {
    //     to: '/import',
    //     title: 'Импорт',
    //     permissions: ['admin'],
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <Icon icon={uploadIcon} width="24" height="24" />
    // },
    {
        to: '/users',
        title: 'Пользователи',
        permissions: ['admin'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <PeopleAltIcon />
    },
    // {
    //     to: '/profile',
    //     title: 'Профиль',
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <PersonIcon />
    // },
    // {
    //     to: '/login',
    //     title: 'Выход',
    //     responsive: ['xsmall', 'small'],
    //     onClick: userActions.logout,
    //     icon: <ExitToAppIcon />
    // },
];

class Menu extends Component{
    state = {
        open: false,
    }

    componentDidMount(){
        const pathname = this.props.location.pathname.split('/');
        const item = MenuItems.find( m => m.to === `/${pathname[1]}`)
        document.title = !!item ? item.title : 'Helix'
    }

    componentDidUpdate(prev){
        const pathname = this.props.location.pathname.split('/');
        
        const item = MenuItems.find( m => {
            const mainPath = m.to.split('/');
            return pathname[1] === mainPath[1] ? true : false
        })
        document.title = !!item ? `Helix | ${item.title}`: 'Helix'
    }
    render(){
        const { classes, location } = this.props;
        const { open } = this.state;
        const permission = permissions();
        return (
            <Responsive
                xsmall={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List>
                            {MenuItems.map((item, index) =>{
                                const pathname = location.pathname.split('/');
                                const mainPath = item.to.split('/');
                                return (
                                    <ListItem button key={index} component={Link} to={item.to} onClick={item.onClick} selected={pathname[1] === mainPath[1] ? true : false}>
                                        <Tooltip title={item.title} placement="right" arrow>
                                            <ListItemIcon>
                                                {item.icon} 
                                            </ListItemIcon>
                                        </Tooltip>
                                        {/* <ListItemText primary={item.title} /> */}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Drawer>
                }
                small={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List
                            classes={{
                                root: classes.list
                            }}
                        >
                            {MenuItems.map((item, index) => (
                                <ListItem button key={index} component={Link} to={item.to} onClick={item.onClick} selected={item.to === location.pathname ? true : false}>
                                    {/* <HtmlTooltip
                                        arrow
                                        placement="right" 
                                        title={
                                        <React.Fragment>
                                            <Typography color="inherit">{item.title}</Typography>
                                        </React.Fragment>
                                        }
                                    > */}
                                        <ListItemIcon
                                            classes={{
                                                root: classes.listIcon
                                            }}
                                        >
                                            {item.icon} 
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={'Secondary text'}
                                        />
                                    {/* </HtmlTooltip> */}
                                    {/* <ListItemText primary={item.title} /> */}
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                }
                medium={
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                        open={open}
                    >
                        <List
                            classes={{
                                root: classes.list
                            }}
                        >
                            {MenuItems.map((item, index) =>{
                                if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
                                    const pathname = location.pathname.split('/');
                                    const mainPath = item.to.split('/');
                                    return (
                                        <ListItem 
                                            button 
                                            key={index} 
                                            component={Link}
                                            to={item.to} 
                                            onClick={item.onClick} 
                                            selected={pathname[1] === mainPath[1] ? true : false}
                                            classes={{
                                                root: classes.listItem,
                                                selected: classes.selected
                                            }}
                                        >
                                            <ListItemIcon
                                                classes={{
                                                    root: classes.listIcon
                                                }}
                                            >
                                                {item.icon} 
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{
                                                    secondary: classes.title
                                                }}
                                                secondary={item.title}
                                            />
                                        </ListItem>
                                    )
                                }else{
                                    return null;
                                }
                            })}
                        </List>
                    </Drawer>
                }
            />
        );
    }
}

export default compose(
    withRouter,
    withStyles(styles),
)(Menu);    