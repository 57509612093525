import List from './List';
// import Edit from './Edit';
import Show from './Show';
// import Card from './Card';

export default {
    list: List,
    // edit: Edit,
    show: Show,
    // card: Card,
};
