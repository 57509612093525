import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';

import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, Card, CardContent } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import momment from 'moment';

import Utils from './utils';

export const styles = theme => ({
    table: {
        padding: 1,
        fontSize: 9,
        border: '1px solid rgba(224, 224, 224, 1)'
    },
    skeleton:{
        // backgroundColor: '#bbd9ea',
        height: 35,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
      },
      skeletonRow:{
        display: 'flex',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
});

class StatisticsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.prepairData = this.prepairData.bind(this);
    }

    prepairData() {
        const { data = [] } = this.props;
        const footer = data.find( d => d.date === 'summary');
        // data.sort( (a,b) =>  momment(a.date, "DD.MM.YYYY HH:mm")> momment(b.date, "DD.MM.YYYY HH:mm") ? 1 : -1);
        return { queues: data.filter( q => q.date!=='summary'), footer};
    }

    render() {
        const { classes, isLoading } = this.props;
        const { queues, footer } = this.prepairData();
        return (
             <Fragment>
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow style={{height: 30}}>
                                    <TableCell rowSpan={3} className={classes.table} style={{width: '7%', textAlign: 'center'}}>
                                        <strong>Дата/время</strong>
                                    </TableCell>
                                    <TableCell rowSpan={3} className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Всего</strong>
                                    </TableCell>
                                    <TableCell rowSpan={3} className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>SL</strong>
                                    </TableCell>
                                    <TableCell colSpan={10} className={classes.table} style={{width: '45%', textAlign: 'center'}}>
                                        <strong>Отвеченные</strong>
                                    </TableCell>
                                    <TableCell colSpan={7} className={classes.table} style={{width: '40%', textAlign: 'center', paddingRight: 'unset'}}>
                                        <strong>Неотвеченные</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{height: 30}}>
                                    <TableCell rowSpan={2} className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                       <strong>Кол-во</strong>
                                    </TableCell>
                                    <TableCell rowSpan={2} className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                       <strong>%</strong>
                                    </TableCell>
                                    <TableCell colSpan={5} className={classes.table} style={{width: '12%', textAlign: 'center'}}>
                                        <strong>Время разговора</strong>
                                    </TableCell>
                                    <TableCell colSpan={3} className={classes.table} style={{width: '24%', textAlign: 'center'}}>
                                        <strong>Ожидало в очереди</strong>
                                    </TableCell>
                                    <TableCell rowSpan={2} className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Кол-во</strong>
                                    </TableCell>
                                    <TableCell rowSpan={2}className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>%</strong>
                                    </TableCell>
                                    <TableCell colSpan={3} className={classes.table} style={{width: '12%', textAlign: 'center'}}>
                                        <strong>Время ожидания</strong>
                                    </TableCell>
                                    <TableCell colSpan={2} className={classes.table} style={{width: '8%', textAlign: 'center', paddingRight: 'unset'}}>
                                        <strong>После 5 сек</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{height: 30}}>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Всего</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Ср.</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Макс.</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Ужержание</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center', paddingRight: 'unset'}}>
                                        <strong>Обработка</strong>
                                    </TableCell>
                                   <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Всего</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Ср.</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Макс.</strong>
                                    </TableCell>
                                    
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Всего</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Ср.</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Макс.</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center'}}>
                                        <strong>Кол-во</strong>
                                    </TableCell>
                                    <TableCell className={classes.table} style={{width: '4%', textAlign: 'center', paddingRight: 'unset'}}>
                                        <strong>%</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(queues&&!isLoading)&&
                                    <Fragment>
                                        {queues.map(
                                            (item, index )=>
                                                    <TableRow key={index} style={{height: 30}}>
                                                        <TableCell className={classes.table} style={{paddingLeft: 10, paddingRight: 10}}>
                                                            <b>{momment(item.date).format("DD.MM.YYYY HH:mm")}</b>
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.total}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.sl}%
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.answered}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.answeredPercent}%
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.talkTime)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.talkTimeAvg)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.talkTimeMax)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.holdTime)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.postprocessingTime)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.answeredWaitTime)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.answeredWaitTimeAvg)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.answeredWaitTimeMax)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.abandoned}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.abandonedPercent}%
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.abandonedWaitTime)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.abandonedWaitTimeAvg)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {Utils.secondsToTime(item.abandonedWaitTimeMax)}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                            {item.abandonedWaitMoreThan5}
                                                        </TableCell>
                                                        <TableCell className={classes.table} style={{textAlign: 'center', paddingRight: 'unset'}}>
                                                            {item.abandonedWaitMoreThan5Percent}%
                                                        </TableCell>
                                                    </TableRow>
                                            )}
                                    </Fragment>
                                }
                            </TableBody>
                            <TableFooter>
                                {(footer&&!isLoading)&&
                                    <TableRow key="footer" style={{height: 30}}>
                                            <TableCell className={classes.table} style={{paddingLeft: 10, paddingRight: 10}}>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.total}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{textAlign: 'center'}}>
                                                <strong>{footer.sl}%</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.answered}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.answeredPercent}%</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.talkTime)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.talkTimeAvg)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.talkTimeMax)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong> {Utils.secondsToTime(footer.holdTime)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.postprocessingTime)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.answeredWaitTime)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.answeredWaitTimeAvg)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.answeredWaitTimeMax)}</strong>
                                            </TableCell>
                                            {/* <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.abandoned}</strong>
                                            </TableCell> */}
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.abandoned}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.abandonedPercent}%</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.abandonedWaitTime)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.abandonedWaitTimeAvg)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{Utils.secondsToTime(footer.abandonedWaitTimeMax)}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{ textAlign: 'center'}}>
                                                <strong>{footer.abandonedWaitMoreThan5}</strong>
                                            </TableCell>
                                            <TableCell className={classes.table} style={{textAlign: 'center', paddingRight: 'unset'}}>
                                                <strong>{footer.abandonedWaitMoreThan5Percent}%</strong>
                                            </TableCell>
                                    </TableRow>
                                }
                            </TableFooter>
                        </Table>
                        {isLoading&&
                            <Fragment >
                                {[].map.call('_'.repeat(10), (a,i)=>i).map((i) =>(
                                    <div className={classes.skeletonRow} key={`${i}`} >
                                        <Skeleton
                                                style={{
                                                    width: `${7}%`
                                                }}
                                                variant="text" 
                                                animation='wave'
                                                className={classes.skeleton}
                                        />
                                        {[].map.call('_'.repeat(19), (a,i)=>i).map( (h,j) => (
                                            <Skeleton
                                                style={{
                                                    width: `${4}%`
                                                }}
                                                key={`${j}`} 
                                                variant="text" 
                                                animation='wave'
                                                className={classes.skeleton}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </Fragment>
                        }
                    </CardContent>
                </Card>
            </Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
)(StatisticsTable);

