import { stringify } from 'query-string';

import { authHeader, notify } from '../_helpers';
import { userActions } from '../_actions';
import { fetchConstants } from '../_constants';

import store from '../_reducer';

const apiUrl = 'v1';

export const GET_LIST = 'GET_LIST';
export const GET_ONE = 'GET_ONE';
export const GET_MANY = 'GET_MANY';
export const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const UPLOAD = 'UPLOAD';
export const UPDATE_MANY = 'UPDATE_MANY';
export const DELETE = 'DELETE';
export const DELETE_MANY = 'DELETE_MANY';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */

const createHeadersFromOptions = (options) => {
    const requestHeaders = (options.headers ||
        new Headers({
            ...authHeader(),
            Accept: 'application/json',
        }));
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }
    return requestHeaders;
};
const httpClient = (url, options = {}) => {
    const requestHeaders = createHeadersFromOptions(options);
    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                if(status === 401 || status === 403){
                    store.dispatch({type: fetchConstants.ERROR})
                    notify({message: "Время сессии истекло", type: 'error'});
                    userActions.logout();
                }
                console.error(
                    (json && json.message) || statusText,
                    status,
                    json
                )
                return Promise.reject({
                    status,
                    message: json.message || statusText,
                    json
                });
            }
            return Promise.resolve({ status, headers, body, json });
        })
};

const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
        case GET_LIST: {
            let query = {};
            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                switch (key) {
                    case 'pagination':
                        const { page, perPage } = params[key];
                        query.skip = (page - 1) * perPage;
                        query.limit = perPage;
                        break;
                    case 'sort':
                        const { field, order } = params[key];
                        query.sort = [field, order];
                        break;
                    case 'filter':
                        query.filter = JSON.stringify(params[key]);
                        break;
                    default:
                        let value = params[key];
                        query[key] =value
                }
            }
            url = `/${apiUrl}${resource}?${stringify(query)}`;
            break;
        }
        case GET_ONE:
            if(params){
                url = `/${apiUrl}${resource}/${params}`;
            }else{
                url = `/${apiUrl}${resource}`;
            }
            break;
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `/${apiUrl}${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `/${apiUrl}${resource}?${stringify(query)}`;
            break;
        }
        case UPDATE:
            if(params.id){
                url = `/${apiUrl}${resource}/${params.id}`;
            }else{
                url = `/${apiUrl}${resource}`;
            }
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break;
        case CREATE:
            url = `/${apiUrl}${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case UPLOAD:
            url = `/${apiUrl}${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `/${apiUrl}${resource}/${params}`;
            options.method = 'DELETE';
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, status } = response;
    if(status!==200){
        return {
            status,
            message: json ? json.message : null
        }
    }
    switch (type) {
        case GET_LIST:
	    	json.data.map(x => x.id = x.id || x._id );
	        return {
                status,
	            data: json.data.map(x => x),
	            total: json.total,
	        };
        case GET_MANY_REFERENCE:
            if (!headers.has('content-range')) {
                throw new Error(
                    'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                status,
                data: json,
                total: parseInt(
                    headers
                        .get('content-range')
                        .split('/')
                        .pop(),
                    10
                ),
            };
        case CREATE:
            return { status, data: json };
        case DELETE_MANY: {
            return { status, data: json || [] };
        }
        default:
            return { status, data: json };
    }
};

const processing = (type, resource, params) =>{
    store.dispatch({type: 'HIDE_CONFIRM_MODAL'});
    store.dispatch({type: fetchConstants.START})
    if(type===CREATE || type===UPDATE){
        store.dispatch({type: fetchConstants.CREATE})
    }
    const { url, options } = convertDataRequestToHTTP(
        type,
        resource,
        params
    );
    return httpClient(url, options).then(
        response =>{
            store.dispatch({type: fetchConstants.SUCCESS})
            switch(type){
                case CREATE:
                    store.dispatch({type: fetchConstants.CREATE_END})
                    notify({message: `Элемент создан успешно`, type: 'success'});
                    break;
                case UPDATE:
                    store.dispatch({type: fetchConstants.CREATE_END})
                    notify({message: response.json.message || `Элемент обновлен успешно`, type: 'info'});
                    break;
                case UPDATE_MANY:
                    notify({message: `Test ${type}`, type: 'info'});
                    break;
                case DELETE:
                    notify({message: `Элемент удален`, type: 'error'});
                    break;
                default:
                    break;
            }
            return convertHTTPResponse(response, type, resource, params)
        }, 
        error =>{
            store.dispatch({type: fetchConstants.ERROR})
            // if(type===CREATE || type===UPDATE){
            //     store.dispatch({type: fetchConstants.CREATE_END})
            // }
            notify({message: `Ошибка в процессе выполнения запроса: ${error.message}`, type: 'error'});
            return convertHTTPResponse(error, type)
        }
    )
}



export const provider = (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }))
            .finally(() => store.dispatch({type: fetchConstants.SUCCESS}));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }))
            .finally(() => store.dispatch({type: fetchConstants.SUCCESS}));
        }
        return processing(type, resource, params);
    };

export default provider;