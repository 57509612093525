import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import {GET_LIST, provider } from '../../Utils/_services/provider';

import Reference from './Reference';

export const styles = theme => createStyles({
    button: {
        marginLeft: theme.spacing(1),
    },
    rules: {
        color: '#0a529f',
        backgroundColor: '#fbb72580',
        border: '1px solid #0a529f59',
        '&:hover': {
            backgroundColor: '#fbb725',
            border: '1px solid #0a529f',
        }
    }
})

class ReferenceButton extends Component {
    state = {
        openRules: false, 
        referenceList: null
    };

    openRules = async () => {
        this.setState({openRules: true});
        let reference = [];
        let referenceList = [];
        if(this.props.data){
            reference = this.reference(this.props.data.data)
        }else{
            reference = this.reference(this.props.formValues.data)
        }
        const { data } = await provider(
            GET_LIST,
            '/reference',
            {
                filter: {code: reference },
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data){
            for (let i = 0; i < reference.length; i++) {
                if(referenceList.filter( r=> r.code === reference[i]).length<=0){
                    referenceList.push(data.find( d=> d.code === reference[i]));
                }  
            }
            this.setState({referenceList})
        }   
    }
    
    handleClose = () => {
        this.setState({ openRules: false, referenceList: null})
    }
    
    reference (data = []){
        let references=[];
        for (var i = 0; i < data.length; i++) {
            const reference = data[i].reference;
            if(reference){
                for(var j = 0; j < reference.length; j++){
                    if(references.indexOf(reference[j])<0 && reference[j]){
                        references.push(reference[j]);
                    }
                }
            }
        }
        return references;
    }

    render() {
        const {classes, formValues, data, disabled } = this.props;
        const { openRules, referenceList } = this.state
        let reference = [];
        if(data){
            reference = this.reference(data.data)
        }else if(formValues){
            reference = this.reference(formValues.data); 
        }
        return (
            <Fragment>
                <Button    
                    className={classes.button}  
                    classes={{
                        root: classes.rules
                    }}                                                    
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={reference.length===0  || disabled }
                    startIcon={<AnnouncementIcon />}
                    onClick={this.openRules} 
                >
                    Правила подготовки
                </Button>
                <Reference 
                    open={openRules}
                    list={referenceList}
                    maxWidth="md"
                    fullWidth={true}
                    onClose={this.handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                />
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(ReferenceButton);
