import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import {GET_LIST, provider } from '../Utils/_services/provider';

import { AutocompleteInput, TextInput } from '../tpls/input';
import { Filter } from '../Utils/components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    search: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    panels: {
        display: 'flex',
        width: theme.spacing(60),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class AnalysisFilter extends Component {
    _isMounted = false;

    state = {
        panels: []
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async UNSAFE_componentWillMount() {
        const { data } = await provider(
            GET_LIST,
            '/panels',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(this._isMounted){
            const panels = data.map( panel => ({
                name: `${panel.code} ${panel.name}`,
                id: panel.id
            }))
            this.setState({
                panels
            });
        }
    }

    render(){
        const { classes, filterDefaultValues = {}, ...props } = this.props;
        const { panels } = this.state;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <TextInput 
                    type="search"
                    source="search"
                    label={`Поиск`}
                    alwaysOn
                    className={classes.search}
                />
                <TextInput 
                    type="number"
                    source="code"
                    label={`Код`}
                    alwaysOn
                    className={classes.code}
                />
                <AutocompleteInput 
                    source="panel_id"
                    label={`Панель`}
                    options={panels}
                    alwaysOn
                    className={classes.panels}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(AnalysisFilter);