import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

class SelectInput extends Component{
  render(){
    const { input, meta: { touched, error } = {}, ...props }  = this.props;
    return(
      <Fragment>
        {props.options 
        ?
          <TextField
            {...input}
            error={!!(touched && error)}
            helperText={touched && error}
            {...props}
            select
          >
              {props.options.map((item, key)=>(
                  <MenuItem disabled={item.disabled} key={key} value={item.id}>{item.name}</MenuItem>
              ))}
          </TextField>
        :
          <LinearProgress {...props} style={{ marginTop: 24 }}/>
        }
      </Fragment>
    )
  }
}

export default SelectInput;
