
import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const styles = theme => createStyles({
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    workHours:{
        fontWeight: 600
    },
    additionhHours:{
        fontStyle: 'oblique'
    },
});

const weekDay = {
    1: "Пн.",
    2: "Вт.",
    3: "Ср.",
    4: "Чт.",
    5: "Пт.",
    6: "Сб.",
    7: "Вс.",
};

const appsWorks = (item, classes) => {
    if(! item){
        return "-"
    }
    return (
        <span>
        {item.schedule.map( (el,index) =>
            <span key={index}>
                {index===0  
                    ? 
                        <div className={classes.workHours}>{el.start} - {el.end}</div>
                    : 
                        <div className={classes.additionhHours}>({el.start} - {el.end})*</div>
                }
            </span>
        )}
        </span>
    )
}

const Apps = ({record, classes}) => {
    return (
        <Table>
            <TableHead
                classes={{
                    root: classes.tableHead
                }}
            >
                <TableRow>
                    <TableCell style={{width: '30%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                        Приложение
                    </TableCell>
                    {Array.from({length: 7}, (v, k) => k+1).map( item =>
                        <TableCell key={item} style={{width: '10%', textAlign: 'center'}} classes={{ root: classes.cellHead}}>
                            {weekDay[item]}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            {record &&
                <TableBody>
                    {record.map(
                        (item, index )=>
                        <TableRow key={index}>
                            <TableCell classes={{ root: classes.cellRoot}}>
                                <b>Приложение {item.application}</b>
                            </TableCell>
                            {Array.from({length: 7}, (v, k) => k+1).map( day =>(
                                <TableCell key={day} style={{textAlign: 'center'}} classes={{ root: classes.cellRoot}}>
                                    {appsWorks(item.weekDays.find( i => i.day === day), classes)}
                                </TableCell>
                            ))}
                        </TableRow>
                    )}
                </TableBody>
            }
        </Table>
    )
};


export default withStyles(styles)(Apps);