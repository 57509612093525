import React, { Component } from 'react';
import moment from 'moment';
import "moment/locale/ru";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import MomentUtils from '@date-io/moment';
// MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth
// import DateFnsUtils from '@date-io/date-fns';

moment.locale("ru");

class DateInput extends Component{
  state ={
     date: moment()
  }

  render(){
    const { input, meta: { touched, error } = {}, ...props }  = this.props;
    return(
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <DatePicker value={input.value} onChange={input.onChange} /> */}
        <DatePicker 
          disableToolbar
          autoOk={true}
          variant="inline"
          format="DD.MM.YYYY"
          invalidDateMessage="Не верный формат даты"
          error={!!(touched && error)}
          helperText={touched && error}
          value={input.value}
          onChange={input.onChange}
          InputProps={{
              endAdornment: 
                  <InputAdornment position="end">
                    <EventIcon size="small"/>
                  </InputAdornment>
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default DateInput;
