import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import { TextInput } from '../tpls/input';
import { Filter } from '../Utils/components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    search: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class ReferenceFilter extends Component {
    render(){
        const { classes, filterDefaultValues = {}, ...props } = this.props;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <TextInput 
                    type="search"
                    source="search"
                    label={`Поиск`}
                    alwaysOn
                    className={classes.search}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ReferenceFilter);