import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {compose} from 'recompose';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { TextInput, SelectInput } from '../../tpls/input';

import {GET_ONE, GET_LIST, UPDATE, provider } from '../_services/provider';

const styles = theme => ({
    success: {
        color: '#bbd9ea',
        backgroundColor: "#0a529f",
    },
    error: {
        backgroundColor: "#e57373",
    },
    info: {
        color: '#0a529f',
    },
    warning: {
        backgroundColor: "#fbb725",
        color: '#0a529f',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    // close: {
    //     color: "#f44336",
    //     border: "1px solid #f4433663",
    //     '&:hover': {
    //         border: '1px solid #f44336',
    //         backgroundColor: '#ff000008',
    //     }
    // },
    button: {
        marginTop: 6,
        marginRight: 12
    },
    close: {
        adding: '0px 10px',
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#e93f33bf',
        }
    },
    drawerPaper:{
        zIndex: 1200,
        width: '100%',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'none',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        touchAction: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    barcodeCard: {
        paddingTop: theme.spacing(7),
        width: '50em',
        flex: '1 0 auto',
        padding: '0px 8px',
        top: 0,
        height: `calc(100vh - ${theme.spacing(7)}px)`,
        display: 'flex',
        outline: 'none',
        position: 'fixed',
        overflowY: 'auto',
        flexDirection: 'column',
        left: 'auto',
        right: 0
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#3cb66a',
        // color: '#0a529f',
    },
    cardTitleInfo: {
        color: '#ffffff;',
        fontSize: '0.95rem;',
        fontWeight: 600
    },
    searchRow: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '50%'
    },
    cellRoot:{
        padding: 0
    },
    flex60: {
        flex: '60em'
    },
    flex20: {
        flex: '40em'
    },
    flex40: {
        flex: '40em'
    },
    notFoundText: {
        paddingTop: theme.spacing(2),
        color: 'red',
        fontWeight: 700,
        textAlign: 'center'
    },
    firstName: {
        flex: "50em"
    },
    lastName: {
        marginLeft: theme.spacing(2),
        flex: "50em"
    },
    email: {
        flex: "50em"
    },
    role: {
        marginLeft: theme.spacing(2),
        flex: "50em"
    },
});

const ActionsShow  = ({classes, onClose, isSaving, invalid, submit}) => {
    return(
        <Fragment>
            <Button                                                         
                className={classes.button}                                                     
                size="small"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={isSaving || invalid}
                onClick={() => submit()}
            >
                Сохранить
            </Button>
            <Button                                                         
                className={classes.button}   
                classes={{
                    root: classes.close
                }}                                                   
                size="small"
                color="secondary"
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose} 
            >
                Закрыть
            </Button>
        </Fragment>
    );
}

class Profile extends React.Component {
    state = {
        openProfile: false,
        data: null
    };
  
    async componentDidUpdate() {
        const { profile } = this.props.profile
        const { openProfile } = this.state;
        if(profile&&!openProfile){
            this.openProfile(profile);
            this.fetchData(this.props.id)
            const { data } = await provider(
                GET_LIST,
                '/roles',
                {
                    filter: {},
                    sort: { field: 'name', order: 'ASC' },
                    pagination: { page: 1, perPage: 10000 },
                }
            );
            const roles = data.map( d => {return { id: d.id, name: d.label }})
            this.setState({
                roles
            });
        }
    }


    async fetchData() {
        const { data } = await provider(
            GET_ONE,
            `/profile`
        );
        if(data) {
            const { initialize } = this.props;
            initialize(data);
        }
    }


    submit = async () => {
        const { formValues } = this.props;
        const { last_name, first_name} = formValues;
        const {status} = await provider(
            UPDATE,
            '/profile',
            {
                data: {
                    last_name, 
                    first_name
                }
            }
        );
        if(+status===200){
            this.onClose();
        }
    }

    onClose = () => {
        const { dispatch } = this.props;
        this.setState({
            openProfile: false,
            data: null
        });
        dispatch({type: 'PROFILE_HIDE' });
    };

    openProfile = (profile) => {
        this.setState({ openProfile: profile, loading: true});
    }

  render() {
    const { openProfile, roles } = this.state;
    const { classes, invalid, formValues, isSaving } = this.props;
    return (
        <div>
            {openProfile &&
                    <div className={classes.drawerPaper}></div>
            }
            <Drawer
                variant="persistent"
                open={openProfile}
                anchor="right"
                onClose={this.onClose}
            >
                {openProfile ? (
                    <Card  className={classes.barcodeCard}>
                        <CardHeader
                            title={`Профиль пользователя`}
                            classes={{
                                title: classes.cardTitleInfo,
                                root: classes.cardHeaderInfo    
                            }}
                            action={ <ActionsShow classes={classes} onClose={this.onClose} isSaving={isSaving} invalid={invalid} submit={this.submit}/>}
                        />
                        <CardContent classes={{
                                root: classes.cardContent
                            }}
                        >
                            {formValues&&
                                <div className={classes.info} >
                                    <div style={{display:'flex'}}>
                                        <Field
                                            name="last_name"
                                            component={TextInput}
                                            label={`Фамилия`}
                                            disabled={isSaving}
                                            className={classes.firstName}
                                            normalize={this.formatName}
                                        />
                                        <Field
                                            name="first_name"
                                            component={TextInput}
                                            label={`Имя`}
                                            disabled={isSaving}
                                            className={classes.lastName}
                                            normalize={this.formatName}
                                        />
                                    </div> 
                                    <div style={{display:'flex', paddingTop: 16}}>
                                        <Field
                                            name="email"
                                            component={TextInput}
                                            label={`E-mail`}
                                            disabled={true}
                                            className={classes.email}
                                            normalize={this.email}
                                        />
                                        <Field
                                            name="role"
                                            component={SelectInput}
                                            label={`Роль`}
                                            disabled={true}
                                            options={roles}
                                            className={classes.role}
                                        />
                                    </div> 
                                </div>
                            }
                          
                        </CardContent>
                    </Card>
                ) : null}
            </Drawer>
        </div>
    );
  }
}

const mapStateToProps = (state, props) => {
    const { fetch, profile } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('profileForm')(state);
    return { 
        profile,
        formValues: recordLiveValues,
        isSaving
    };
};
export default compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
    reduxForm({
        form: 'profileForm',
        // onSubmit: submit,
        validate: (values) => {
            const errors = { };
            if (!values.name_first) {
                errors.name_first = 'Обазятельное поле'
            }
            if (!values.name_second) {
                errors.name_second = 'Обазятельное поле'
            }
            return errors;
        },
        destroyOnUnmount: false,
    })
)(Profile);