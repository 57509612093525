import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import moment from 'moment';

import * as FileSaver from 'file-saver';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './player.css';

import {GET_ONE, provider } from '../../_services/provider';
import { authHeader } from '../../_helpers';

let openPlayerFn;

export const styles = theme => createStyles({
    main: {
        paddingTop: 8
    },
    card: {
        borderRadius: '4px 4px 0px 0px'
    },
    root: {
        padding: '0px 0px 0px 16px'
    },
    title: {
        fontSize: 14
    },
    action: {
        margin: 0
    }

})

class Player extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            id: null,   
            src: null,
            show: false,
            recordName: null
        };
        this.fetchStream = this.fetchStream.bind(this);
        this.close = this.close.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        openPlayerFn = this.fetchStream;
    }

    componentDidUpdate(prev){
        if((this.props.player.record&&!prev.player.record) || (this.props.player.record&&prev.player.record&&this.props.player.record.id!==prev.player.record.id)){
            this.fetchStream();
        }
    }

    close() {
        const { dispatch } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload: {id: null, record: null, state: null} });
        this.setState({src: null, recordName: null, show: false});
    }

    async fetchStream() {
        const { dispatch , player} = this.props;
        const { record } = player;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: record.id, status: 'load', record } });
        this.setState({src: null, show: true, recordName: `${record.address} - ${record.agent} ${moment(record.callTime).format("DD.MM.YYYY HH:mm:ss")}`});
        const result = await provider(GET_ONE, '/stream', record.uid);
        if(this._isMounted){
            this.setState({src: `/v1/play/${result.data.fileName}`, fileName: result.data.fileName})
        }
    }

    onPause(){
        const { dispatch, player } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: player.record.uid, record: player.record, status: 'pause' } });
    }

    onPlay(){
        const { dispatch, player } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: player.record.uid, status: 'play', record: player.record } });
    }

    async download(){
        const { fileName, recordName } = this.state;
        const response = await fetch(`/v1/download/${fileName}`, {
            method: 'get',
            responseType: 'arraybuffer',
            headers: {...authHeader(), 'Content-Type': 'application/json'},
        })
        this.setState({ isSaving: false });
        response.arrayBuffer().then(function(buffer) {
            var blob = new Blob([buffer], {type: "audio/mpeg"});
            FileSaver.saveAs(blob, `${recordName}.mp3`);
        });
    }

    render() {
        const { classes } = this.props;
        const { src, show, recordName} = this.state;
        if(!show){
            return null;
        }
        return (
            <div className={classes.main}>
                <Card classes={{
                    root: classes.card
                }}>
                    <CardHeader
                        classes={{
                            root: classes.root,
                            title: classes.title,
                            action: classes.action
                        }}
                        title={recordName}
                        action={ 
                            <div>
                                <IconButton style={{color: '#00529f', borderRadius: 4, fontSize: 14}} onClick={() => this.download()} size='small' aria-label="Скачать">
                                    <CloudDownloadIcon style={{marginRight: 8}}/> Скачать
                                </IconButton>
                                <IconButton style={{color: 'red'}} onClick={() => this.close()} size='small' aria-label="Закрыть">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </Card>
                <AudioPlayer 
                    autoPlay 
                    src={src} 
                    showLoopControl={false}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                /> 
            </div>
        );
    }
}


const mapStateToProps = (state, props) =>{
    const { fetch, player } = state;
    return {
        player,
        isLoading: !!fetch.loading
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Player);


export function openPlayer(obj) {
    openPlayerFn(obj);
}