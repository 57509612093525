import { confirmConstants } from '../_constants';

const { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL } = confirmConstants;
const initialState = {
  modalType: null,
  modalProps: {}
}

export function confirm (state = initialState, action) {
  switch (action.type) {
    case SHOW_CONFIRM_MODAL:
      return action;
    case HIDE_CONFIRM_MODAL:
      return Object.assign({}, state, {
        isShowing: false
      })
    default:
      return state
  }
}