
import React, { Component, Fragment } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { AnalysisInfo } from '../../Utils/components';

export const styles = theme => createStyles({
    table: {
        fontSize: 11
    },
    inComplex: {
        padding: '0px 8px',
    },
    info:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(29)}px)`,
    },
    flex20: {
        flex: '20em'
    },
    flex30: {
        flex: '30em'
    },
    flex40: {
        flex: '40em'
    },
    flex80: {
        flex: '80em'
    },
    fullField: {
        width: '100%',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    heading: {
        color: '#0a529f',
        fontWeight: 600,
        padding: '0px 8px',
        wifth: '90%'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(23)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '54%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#3cb66a',
    },
    cardTitleInfo: {
        color: '#ffffff;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    closeInfo: {
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ce8e96',
        }
    },
    basketButton: {
        backgroundColor: '#0a529f',
        color: "#f6b733",
        '&:hover': {
            border: '1px solid #0a529f',
            color: "#f6b733d1",
            backgroundColor: '#0a529fc4',
        }
    },
});


const daysLabel = (day) => {
    let days = 'дней';
    if(day===1){
        days = 'день'
    }else if(day>1&& day<5){
        days = 'дня'
    }
    return `${day} ${days}`;
}

const checkError = (formValues, suggestion ) => {
    if(!suggestion){
      return {}
    }
    const {sex, data = [], analysis = [], city} = formValues;
    const used = analysis.indexOf(suggestion.code)>=0 || data.findIndex( d=> d.Code===suggestion.code)>=0;
    const complexes = data.filter( d => d.isComplex);
    let inComplex = [];
    for (let index = 0; index < complexes.length; index++) {
      const element = complexes[index];
      const indexInComplex = element.inComplex.indexOf(suggestion.code);
      if(indexInComplex>=0){
        inComplex.push(element.Code);
      }
    }
    let errorMessage = null;
    if(used){
      errorMessage =  'Услуга в корзине';
    }else if(suggestion.noAnalysis){
      errorMessage =  `Не выполняется в лабораторях Helix`;
    }else if(!suggestion.availability){
      errorMessage =  'Не доступен к заказу';
    }else if(suggestion.minsk_only&&city!=='5d985468ea4291b90b636d53'){
      errorMessage =  `Выполняется только в г.Минске`;
    }else if(suggestion.deleted){
      errorMessage =  'Услуга не доступна';
    }else if(suggestion.sex === "F" && sex!=='F'){
        errorMessage =  'Услуга доступна только для женщин';
    }else if(suggestion.sex === "M" && sex!=='M'){
      errorMessage = 'Услуга доступна только для мужчин';
    }else if(inComplex.length>0){
      errorMessage =  `Услуга входит в комплекс: ${inComplex.join(", ")}`;
    }
    return { used, errorMessage }
  }

class Info extends Component {
    render(){
        const {data, closeInfo, onInfo, addAnalys,  classes, formValues } = this.props
        const record = {
            application: data.application ? `Приложение ${data.application}` : '-',
            panel: data.panel ? data.panel : '-',
            code: data.code || '-',
            price: data.price || '-',
            deadline: data.deadline ? daysLabel(data.deadline) : '-',
            material: data.material || '-',
            third_party: data.third_party || false,
            discount: data.discount || false,
            happy_hours: data.happy_hours || false,
            isComplex: data.isComplex || false,
            other_name: data.other_name,
            description: data.description,
            included: data.included || [],
            inComplex: data.inComplex || [],
            availability: data.availability || true
        }
        const { errorMessage } = checkError(formValues, record);
        return(
            <Card  className={classes.cardStyleRigth}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleInfo,
                        root: classes.cardHeaderInfo
                    }}
                    style={{ paddingBottom: 6}}
                    title={`${data.code} ${data.name}`}
                    action={
                        <Fragment>
                            {!!!errorMessage&&
                                <Button  
                                    style={{
                                        padding: '0px 10px',
                                        marginTop: 6
                                    }}
                                    // classes={{
                                    //     root: classes.basketButton
                                    // }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<ShoppingCartIcon />}
                                    onClick={ () => addAnalys(data) } 
                                >
                                    В корзину
                                </Button>
                            }
                            <Button  
                                style={{
                                    padding: '0px 10px',
                                    marginTop: 6,
                                    marginLeft: 6
                                }}
                                classes={{
                                    root: classes.closeInfo
                                }}                                                        
                                size="small"
                                color="secondary"
                                variant="outlined"
                                startIcon={<CloseIcon />}
                                onClick={closeInfo} 
                            >
                                Закрыть
                            </Button>
                        </Fragment>
                    }
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    <AnalysisInfo data={data} className={classes.info} onInfo={onInfo} />
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
  };
  
  const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    )
  );
  
export default enhance(Info);