import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';

import {
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { GET_LIST, CREATE, DELETE, provider } from '../../Utils/_services/provider';
import PanelsSettings from './PanelsSettings';
import ApplicationsSettings from './ApplicationsSettings';

const styles = theme => ({
    root: {
        width: '100%',
    },
    dialogPaper: {
        height: 'calc(100% - 64px)'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    tableHeader: {
        padding: 1,
        fontSize: 11,
    },
    table: {
        padding: 1,
        fontSize: 11,
        color: '#084c92',
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    form: {
        display: 'flex',
        paddingBottom: theme.spacing(2),
    },
    name: {
        display: 'flex',
        width: theme.spacing(40),
        marginRight: 20
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginRight: 20,
        marginBottom: theme.spacing(2),
    }
});


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panels: [],
            applications: [],
            isSaving: false,
            tab: 0
        };

        this.fetchPanels = this.fetchPanels.bind(this);
        this.fetchApplications = this.fetchApplications.bind(this);
        this.addApp = this.addApp.bind(this);
        this.addPanel = this.addPanel.bind(this);
        this.deletePanel = this.deletePanel.bind(this);
        this.deleteApp = this.deleteApp.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidUpdate(prevProps){
        if(!prevProps.showDialog&&this.props.showDialog){
            this.fetchPanels();
            this.fetchApplications();
        }
    }

    async fetchPanels() {
        const { data } = await provider(
            GET_LIST,
            `/panels`,
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data) {
          this.setState({ panels: data });
        }
    }

    async fetchApplications() {
        const { data } = await provider(
            GET_LIST,
            `/applications`,
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(data) {
          this.setState({ applications: data });
        }
    }

    async addPanel(formValues) {
        const { status } = await provider(
            CREATE,
            '/panels',
            {
                data: formValues,
            }
        );
        if(status===200){
            this.fetchPanels();
        }
    }

    async addApp(formValues) {
        const { status } = await provider(
            CREATE,
            '/applications',
            {
                data: formValues,
            }
        );
        if(status===200){
            this.fetchApplications();
        }
    }

    async deletePanel(item) {
        const { status } = await provider(
            DELETE,
            '/panels',
            item._id
        );
        if(status===200){
            this.fetchPanels();
        }
    }

    async deleteApp(item) {
        const { status } = await provider(
            DELETE,
            '/applications',
            item._id
        );
        if(status===200){
            this.fetchApplications();
        }
    }

    handleCloseClick = () => {
        this.props.handleCloseClick();
    }
   
    handleChange (event, newValue) {
        this.setState({ tab: newValue})
    }


    render() {
        const { applications, panels, isSaving, tab } = this.state;
        const {classes, showDialog } = this.props;
        return (
            <Fragment>
                <Dialog
                    maxWidth='lg'
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                >
                    <DialogTitle>Настройки</DialogTitle>
                    <DialogContent>

                        <AppBar position="static">
                            <Tabs value={tab} onChange={this.handleChange}>
                                <Tab label="Панели"  />
                                <Tab label="Приложения"  />
                            </Tabs>
                        </AppBar>
                        {tab === 0 && (
                            <PanelsSettings showDialog={showDialog} panels={panels}  addPanel={this.addPanel} deletePanel={this.deletePanel} isSaving={isSaving}/>
                            )}
                        {tab === 1 && (
                            <ApplicationsSettings showDialog={showDialog} applications={applications} addApp={this.addApp} deleteApp={this.deleteApp} isSaving={isSaving}/>
                        )}
                        
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.close}
                            onClick={this.handleCloseClick}
                            startIcon={<CloseIcon />}
                        >
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
)(Settings);