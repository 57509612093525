import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';

import { submit, isInvalid } from 'redux-form'

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { Button } from '@material-ui/core';

import { history } from '../../Utils/_helpers';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


class Actions extends Component {
    close = () =>{
        const pathname = history.location.pathname.split("/");
        return history.push(`/${pathname[1]}`);
    }

    edit = () =>{
        const pathname = history.location.pathname.split("/");
        pathname[pathname.length-1]='edit';
        return history.push(`${pathname.join('/')}`);
    }

    render(){
        const { 
            classes, 
            dispatch,
            isSaving,
            invalid
        } = this.props;
        return(
            <Fragment>
                <Button                                                         
                    className={classes.button}                                                     
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={isSaving || invalid}
                    onClick={() => dispatch(submit('userForm'))}
                >
                    Сохранить
                </Button>
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        invalid: isInvalid('calculatorForm')(state),
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(Actions);