import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        // color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
}

const HTMLField = ({classes, style, ...props}) =>{
    return (
        <div style={{ ...style, textIndent: 20 }} dangerouslySetInnerHTML={{__html: props.value}} />
    )
}

export default withStyles(styles)(HTMLField);
