import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Route } from 'react-router';
import { createStyles, withStyles } from '@material-ui/core/styles';

import moment from 'moment';

import Drawer from '@material-ui/core/Drawer';


import { history } from '../Utils/_helpers';
import { List } from '../Utils/components';
import Player from '../Utils/components/Player'

import { TimeField, TextField, DateField } from '../tpls/fields';

import Filter from './Filter';
import Show from './Show';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class VisorList extends Component {
    render() {
        const { classes, formValues, perPage, page } = this.props;
        return (
            <Route path="/visor/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params
                        );
                        return (
                            <div className={classes.root}>
                                <List
                                    classes={{root: isMatch&&classes.listWithDrawer}}
                                    refresh={true}
                                    creat={false}
                                    show={false}
                                    play={true}
                                    basePath='visor'
                                    filters={<Filter form="visorForm"/>}
                                    match={match}
                                    perPage={perPage}
                                    page={page} 
                                    filterDefaultValues={formValues}
                                    sort={{ field: 'callTime', order: 'ASC' }} 
                                >
                                    <DateField
                                        source="callTime"
                                        label="Дата"
                                        format="DD.MM.YYYY HH:mm:ss"
                                    />
                                    <TextField
                                        source="address"
                                        label="Телефон"
                                    />
                                    <TimeField
                                        source="duration"
                                        label="Длит."
                                    />
                                    <TextField
                                        source="agent"
                                        label="Оператор"
                                    />
                                    <TextField
                                        source="operator"
                                        label="ФИО оператора"
                                    />
                                    <TextField
                                        source="task"
                                        label="Тема"
                                    />
                                    <TextField
                                        source="sub_task"
                                        label="Подтема"
                                    />
                                    <TextField
                                        source="description"
                                        label="Комментарий"
                                    />
                                    {/* <TextField
                                        source="queue"
                                        label="Очредь"
                                    /> */}
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <div style={{padding: '0px 8px'}}>
                                            <Show id={match.params.id}/>
                                        </div>
                                    ) : null}
                                </Drawer>
                                <Player />
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('visorForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { startDate: moment().startOf('day'), endDate: moment().endOf('day') },
    };
};


export default compose(
    connect(mapStateToProps),
    withStyles(styles),
)(VisorList);
