import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Route } from 'react-router';
import { createStyles, withStyles } from '@material-ui/core/styles';


import Drawer from '@material-ui/core/Drawer';

import { history } from '../Utils/_helpers';

import { List } from '../Utils/components';

import { TextField, BooleanField } from '../tpls/fields';

import Filter from './Filter';
import Show from './Show';
import SettingsButton from './components/SettingsButton';

import { permissions } from '../Utils/_helpers';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class AnalysisList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: { },
            permission: null,
            permissionsComponent: ['admin']
        };
    }

    componentDidMount(){
        const permission = permissions();
        this.setState({permission});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes, perPage, page } = this.props;
        const { filter, permissionsComponent = [], permission } = this.state;
        return (
            <Route path="/analysis/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );
                        return (
                            <div className={classes.root}>
                                <List
                                    classes={{
                                        root: isMatch&&classes.listWithDrawer
                                    }}
                                    refresh={true}
                                    creat={false}
                                    show={true}
                                    basePath='analysis'
                                    filters={<Filter form="analysisForm"/>}
                                    match={match}
                                    filterDefaultValues={filter}
                                    actions={(permission&&permissionsComponent.indexOf(permission)>=0) ? <SettingsButton /> : null }
                                    perPage={perPage}
                                    page={page} 
                                    deletedField='deleted'
                                    sort={{ field: 'panel', order: 'DESC' }} 
                                >
                                    <TextField
                                        notSort={true}
                                        source="code"
                                        label="Код"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="price"
                                        label="Цена"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="panelName"
                                        label="Панель"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="application"
                                        label="Прил."
                                    />
                                    <BooleanField
                                        notSort={true}
                                        source="minsk_only"
                                        label="Только в г. Минске"
                                    />
                                    <BooleanField
                                        notSort={true}
                                        source="isComplex"
                                        label="Комплекс"
                                    />
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <div style={{padding: '0px 8px'}}>
                                            <Show id={match.params.id}/>
                                        </div>
                                    ) : null}
                                </Drawer>
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('analysisForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { },
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps),
)(AnalysisList);
