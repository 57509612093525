import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import { DateInput, } from '../tpls/input';
import { Filter } from '../Utils/components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    date: {
        display: 'flex',
        width: theme.spacing(15),
        marginRight: 20
    },
    phone: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    user: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class ImportFilter extends Component {

    render(){
        const { classes, filterDefaultValues = {}, formValues = {}, ...props } = this.props;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <DateInput
                    source="startDate"
                    label={`С`}
                    alwaysOn
                    // minDate={ new Date(moment(formValues.endDate).subtract(30, 'days'))}
                    maxDate={new Date(formValues.endDate)}
                    className={classes.date}
                />
                <DateInput
                     source="endDate"
                     label={`По`}
                     alwaysOn
                     minDate={new Date(formValues.startDate)}
                     maxDate={ new Date() }
                     className={classes.date}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ImportFilter);