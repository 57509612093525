import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import { withStyles } from '@material-ui/core/styles';
import Downshift from "downshift";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import AddButton from './AddButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  },
  container: {
    flexGrow: 1,
    position: 'absolute',
    width: '55%'
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: theme.spacing(38),
    overflowY: 'auto'
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  gutters: {
    padding: '4px 8px'
  },
  multiline:{
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    fontWeight: 600
  },
  secondary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: '#0a529f'
  },
  secondaryError: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: 'red'
  }
});

function renderInput(inputProps, submitProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  const handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        if(!submitProps.error.used && !submitProps.error.errorMessage){
          submitProps.onAdd(submitProps.selectedItem, submitProps.clearSelection);
        }
      }
  }
  return (
    <TextField
      style={{width: '80%'}}
      onKeyPress={handleKeyPress}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const checkError = (formValues, suggestion ) => {
  if(!suggestion){
    return {}
  }
  const {sex, data = [], analysis = [], city} = formValues;
  const used = analysis.indexOf(suggestion.code)>=0 || data.findIndex( d=> d.Code===suggestion.code)>=0;
  const complexes = data.filter( d => d.isComplex);
  let inComplex = [];
  for (let index = 0; index < complexes.length; index++) {
    const element = complexes[index];
    const indexInComplex = element.inComplex.indexOf(suggestion.code);
    if(indexInComplex>=0){
      inComplex.push(element.Code);
    }
  }
  let errorMessage = null;
  if(used){
    errorMessage =  'Услуга в корзине';
  }else if(suggestion.deleted){
    errorMessage =  'Услуга заблокирована';
  }else if(suggestion.noAnalysis){
    errorMessage =  `Не выполняется в лабораторях Helix`;
  }else if(!suggestion.availability){
    errorMessage =  'Не доступен к заказу';
  }else if(suggestion.minsk_only&&city!=='5d985468ea4291b90b636d53'){
    errorMessage =  `Выполняется только в г.Минске`;
  }else if(suggestion.sex === "F" && sex!=='F'){
      errorMessage =  'Данный анализ доступен только для женщин';
  }else if(suggestion.sex === "M" && sex!=='M'){
    errorMessage = 'Данный анализ доступен только для мужчин';
  }else if(inComplex.length>0){
    errorMessage =  `Анализ входит в комплекс: ${inComplex.join(", ")}`;
  }
  return { used, errorMessage }
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, formValues, classes }) {
  const isHighlighted = highlightedIndex === index;
  const label = selectedItem ? `${selectedItem.code} ${selectedItem.name}` : null;
  const isSelected = (label || '').indexOf(suggestion.label) > -1;
  const {used, errorMessage } = checkError(formValues, suggestion);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      disabled={used||!!errorMessage}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <ListItem 
        button
        classes={{
          gutters: classes.gutters,
        }}
        // disabled={used||!!errorMessage}
      >
          <ListItemText 
            classes={{
              multiline: classes.multiline,
              primary: classes.primary,
              secondary: !!errorMessage ? classes.secondaryError : classes.secondary
            }}
            primary={`${suggestion.code} ${suggestion.name}`}  
            secondary={errorMessage ? errorMessage : suggestion.analog.join(', ')} 
          />
       </ListItem>
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, suggestions, selectedItem) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const result = inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        if(!suggestion.other_name){
          suggestion.other_name = '';
        }
        try {
            const name = suggestion.name.toLowerCase().trim().search(inputValue)>=0;
            const code = suggestion.code.toString().toLowerCase().trim().search(inputValue)>=0;
            const other_name = suggestion.other_name.toLowerCase().trim().search(inputValue)>=0;
            const analog = suggestion.analog.filter( a =>a.toLowerCase().trim().search(inputValue)>=0);
            const keep = count < 20 && ( name || code || other_name || analog.length>0) ;
            if (keep) {
              count += 1;
            }
            return keep;
        }
        catch(e){
          console.log(e);
          return false;
        }
      });
  return result;
}

function QuickSuggestionsField(props) {
  const { classes, choices, onAdd, formValues } = props;
  return (
    <div className={classes.root}>
      < Downshift
        itemToString={item => (item ? `${item.code} ${item.name}` : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          clearSelection,
        }) =>{
          const {used, errorMessage } = checkError(formValues, selectedItem);
          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: false,
                classes,
                InputProps: getInputProps({
                  placeholder: 'Быстрое добавление услуги',
                }),
              },{
                  onAdd: onAdd,
                  clearSelection: clearSelection,
                  selectedItem: selectedItem,
                  error: {
                    used, errorMessage
                  }
              })}
              <IconButton 
                size="small" 
                style={{color: selectedItem&&'red'}} 
                disabled={!selectedItem}  
                onClick={clearSelection} 
                aria-label="Очистить"
              >
                <Clear size="small" />
              </IconButton>
              <IconButton 
                size='small' 
                style={{color: (selectedItem&&!!!used&&!!!errorMessage )&&'#0a529f'}} 
                disabled={ !selectedItem || !!used || !!errorMessage } 
                onClick={()=> onAdd(selectedItem, clearSelection)} 
                aria-label="Добавить анализ"
              >
                  <ArrowForwardIcon fontSize="small"/>
              </IconButton>
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue, choices, selectedItem,).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        formValues,
                        itemProps: getItemProps({ item: suggestion}),
                        highlightedIndex,
                        selectedItem,
                        classes
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        } 
      </Downshift>
      <div className={classes.divider} />
    </div>
  );
}

QuickSuggestionsField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  var recordLiveValues = getFormValues('calculatorForm')(state);
  return { formValues: recordLiveValues};
};

const enhance = compose(
  withStyles(styles),
  connect(
      mapStateToProps
  )
);

export default enhance(QuickSuggestionsField);