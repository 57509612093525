import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
class TextInput extends Component{
  render(){
    const { input: { ...inputProps }, meta: { touched, error } = {}, ...props }  = this.props;
    return(
      <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
      />
    )
  }
}

export default TextInput;
