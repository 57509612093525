import React, { Component } from 'react';
import compose from 'recompose/compose';
import {  
    getFormValues,
} from 'redux-form';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import LinearProgress from '@material-ui/core/LinearProgress';

import AnalysisTable from './AnalysisTable'
import Info from './Info';

export const styles = theme => createStyles({
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(23)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '54%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    cardTitle: {
        color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#0a529f'
    },
})
class CalculateCard extends Component {
    state = {
    };
    
    render() {
        const { 
            classes, 
            showInfo, 
            showData, 
            formValues, 
            isLoading,
            calculate,
            summary,
            closeInfo, 
            choices,
            addAnalys,
            panels,
            onInfo,
            deleteElement
        } = this.props;
        if(showInfo){
            return  <Info data={showData} onInfo={onInfo} addAnalys={addAnalys} closeInfo={closeInfo}/>
        }else{
            return (
                <Card  className={classes.cardStyleRigth}>
                    <CardHeader
                        classes={{
                            title: classes.cardTitle,
                            root: classes.cardHeader
                        }}
                        title="Расчет стоимости анализов"
                    />
                    <CardContent style={{padding: 0, height: 4}}>
                        {isLoading&&
                            <LinearProgress color="secondary" /> 
                        } 
                    </CardContent>
                    <CardContent classes={{
                            root: classes.cardContent
                        }}
                    >
                        {formValues&&
                            <AnalysisTable 
                                addAnalys={addAnalys}
                                choices={choices}
                                panels={panels}
                                data={formValues.data} 
                                city={formValues.city}
                                analysis={formValues.analysis}
                                calculate={calculate}
                                deleteElement={deleteElement}
                                summary={summary}
                            />
                        }
                    </CardContent>
                </Card>
            )
        }
    }
}


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { formValues: recordLiveValues};
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    )
);


export default enhance(CalculateCard);
