import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import LinearProgress from '@material-ui/core/LinearProgress';

import {GET_ONE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import { Labeled, TextField, PhoneField, SelectField } from '../tpls/fields'

import AnalysisTable from './components/AnalysisTable';
import ActionsShow from './components/ActionsShow';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
});

class CalculatorShow extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            data: {},
            calculate: false,
            summary: {
                deadline: '-',
                additionalPrice: '-',
                analysisPrice: '-',
                summa: '-',
            }
        };
    }
    
    componentDidMount(){
        this._isMounted = true;
        if(this.props.id){
            this.fetchData(this.props.id)
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(this._isMounted&&data) {
            const summary = {
                additionalPrice: this.additionalPrice(data.data),
                analysisPrice: this.analysisPrice(data.data),
                summa: this.summaPrice(data.data),
                deadline: this.deadline(data.data),
            }
          this.setState({ data, summary, calculate: true });
        }
    }

    additionalPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            if(!item.isAnalys){
                summ += +item.Price;
            }
        }
        return `${summ.toFixed(2)}р`;
    }

    analysisPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            if(item.isAnalys){
                summ += +item.Price;
            }
        }
        return `${summ.toFixed(2)}р`;
    }

    summaPrice (data){
        let summ = 0;
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            summ += +item.Price;
        }
        return `${summ.toFixed(2)}р`;
    }

    deadline (data){
        let max=0;
        for (var i = 0; i < data.length; i++) {
            if(data[i].WorkingDays>max){
                max = data[i].WorkingDays;
            }
        }
        let days = 'рабочих дней';
        if(max===1){
            days = 'рабочий день'
        }else if(max>1&& max<5){
            days = 'рабочих дня'
        }
        return `${max}  ${days}`;
    }

    render() {
        const { summary, data, calculate } = this.state;
        const { classes } = this.props;
        return (
            <Card  className={classes.cardStyleShow}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleShow,
                        root: classes.cardHeaderShow
                    }}
                    action={ <ActionsShow data={data}/>}
                />
                <CardContent style={{padding: 0, height: 4}}>
                    {!calculate&&
                        <LinearProgress color="secondary" /> 
                    } 
                </CardContent>
                <CardContent>
                <div style={{display: 'flex'}}>
                        <div>
                            <Labeled label="Имя клиента" className={classes.inline}>
                                <TextField record={data} source="first_name"/>
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Пользователь" className={classes.inline}>
                                <TextField record={data} source="user_update"/>
                            </Labeled>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div> 
                            <Labeled label="Пол" className={classes.inline}>
                                <SelectField 
                                    record={data} 
                                    source="sex"
                                    options={[
                                            {name: "Муж", id: "M"},
                                            {name: "Жен", id: "F"},
                                        ]}
                                />
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Телефон" className={classes.inline}>
                                <PhoneField record={data} source="phone"/>
                            </Labeled>
                        </div>
                        <div style={{ marginLeft: 'auto'}}>
                            <Labeled label="Город" className={classes.inline}>
                                <TextField record={data} source="cityName"/>
                            </Labeled>
                        </div>
                    </div>
                </CardContent>
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    {calculate&&
                        <AnalysisTable 
                            show={true}
                            data={data.data} 
                            city={data.city}
                            analysis={data.analysis}
                            calculate={calculate}
                            summary={summary}
                        />
                    }
                </CardContent>
            </Card>
                
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(CalculatorShow);
