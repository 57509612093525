import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import {GET_LIST, provider } from '../Utils/_services/provider';

import { AutocompleteInput, TextInput } from '../tpls/input';
import { Filter } from '../Utils/components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    search: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    city: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class CalculatorFilter extends Component {
    state = {
        cities: []
    }
    async UNSAFE_componentWillMount() {
        const { data } = await provider(
            GET_LIST,
            '/city',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const cities = data.map( city => ({
            name: `${city.name}`,
            id: city.id
        }))
        this.setState({
            cities
        });
    }

    render(){
        const { classes, filterDefaultValues = {}, ...props } = this.props;
        const { cities } = this.state;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <TextInput 
                    type="search"
                    source="search"
                    label={`Поиск`}
                    alwaysOn
                    className={classes.search}
                />
                <AutocompleteInput 
                    source="city"
                    label={`Город`}
                    options={cities}
                    alwaysOn
                    className={classes.city}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(CalculatorFilter);