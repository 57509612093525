import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class DeleteButton extends Component {
    state = {
        error: false,
    };
    
    render() {
        const { item, action, isSaving } = this.props;
        const onClick = () => {
            return action(item);
        }
        return (
            <IconButton disabled={isSaving} size='small'  style={{color: isSaving ? '#9e9e9e' : '#F44336'}} onClick={onClick} aria-label="Удалить">
                <DeleteForeverIcon fontSize="small"/>
            </IconButton>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    return { 
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
)(DeleteButton);