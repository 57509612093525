import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import {GET_ONE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import Info from './components/Info';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    }
});

class UsersShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
    }
    
    componentDidMount(){
        if(this.props.id){
            this.fetchData(this.props.id)
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.fetchData(this.props.id);
        }
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(data) {
          this.setState({ data });
        }
    }

    close = () =>{
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}`)
    }

    render() {
        const { roles } = this.props;
        const { data = {} } = this.state;
        return (
            <Info data={data} close={this.close} roles={roles}/>
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(UsersShow);
