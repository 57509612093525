import React, { Component, Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

class AutocompleteInput extends Component{
    onChange = (event, value) => {
        const { input } = this.props;
        input.onChange(value ? value.id : null)
    }
    render(){
        const { input, meta: { error, touched } = {}, options, ...props }  = this.props;
        return(
            <Fragment>
                {options 
                ?
                    <Autocomplete
                        getOptionLabel={
                            option => {
                                return  option ? option.name : ""
                            }
                        }
                        loading={!!options}
                        loadingText="Загрузка"
                        renderInput={params =>{
                            return (
                                <TextField 
                                    {...params} 
                                    label={props.label}
                                    error={!!(touched && error)}
                                    helperText={touched && error}
                                    fullWidth 
                                    style={{marginTop: 0, width: '100%'}}
                                />
                            )
                        }}
                        onChange={this.onChange}
                        options={options}
                        {...props}
                    />
                :
                    <LinearProgress {...props} style={{ marginTop: 24 }}/>
                }
            </Fragment>
        )
    }
  }
  
export default AutocompleteInput;