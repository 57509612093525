import { userConstants } from '../_constants';

let token = localStorage.getItem('token');

const initialState = { loggedIn: true, token };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {};
        case userConstants.LOGIN_SUCCESS:
            return {
                ...action
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.RESET_REQUEST:
            return {};
        case userConstants.RESET_SUCCESS:
            return {
                ...action
            };
        case userConstants.RESET_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}