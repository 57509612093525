import React, { Component } from 'react';
import compose from 'recompose/compose';
import { Route } from 'react-router';
import { createStyles, withStyles } from '@material-ui/core/styles';

import moment from 'moment';

import Drawer from '@material-ui/core/Drawer';

import { List } from '../Utils/components';

import { TextField, DateField, PhoneField, SelectField } from '../tpls/fields';

import Filter from './Filter';
import Show from './Show';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class CalculatorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                startDate: moment().startOf('day').subtract(6,'days'),
                endDate: moment().endOf('day'), 
            }
        };
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { filter } = this.state;
        return (
            <Route path="/calculator/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params &&
                            match.params.id !== 'create'
                        );
                        return (
                            <div className={classes.root}>
                                <List
                                    classes={{root: isMatch&&classes.listWithDrawer}}
                                    refresh={true}
                                    creat={true}
                                    show={true}
                                    filters={<Filter />}
                                    match={match}
                                    basePath='calculator'
                                    filterDefaultValues={filter}
                                    perPage={25} 
                                    sort={{ field: 'updateAt', order: 'DESC' }} 
                                >
                                    <TextField
                                        source="name"
                                        label="Имя"
                                    />
                                    <PhoneField
                                        notSort={true}
                                        source="phone"
                                        label="Телефон"
                                    />
                                    <SelectField
                                        notSort={true}
                                        source="sex"
                                        label="Пол"
                                        options={[
                                            {name: "Муж", id: "M"},
                                            {name: "Жен", id: "F"},
                                        ]}
                                    />
                                    <TextField
                                        notSort={true}
                                        source="city"
                                        label="Город"
                                    />
                                    <DateField
                                        format="DD.MM.YYYY HH:mm"
                                        source="updateAt"
                                        label="Дата / Время"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="user"
                                        label="Пользователь"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="summ"
                                        label="Сумма"
                                    />
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {isMatch ? (
                                        <div style={{padding: '0px 8px'}}>
                                            <Show id={match.params.id}/>
                                        </div>
                                    ) : null}
                                </Drawer>
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

export default compose(
    withStyles(styles),
)(CalculatorList);
