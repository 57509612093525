import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class DeleteButton extends Component {
    onClick(){
        const { item, action, isSaving, disabled } = this.props;
        if(!isSaving && !disabled) { 
            return action(item) 
        }
        return;
    }
    render() {
        const { isSaving, disabled } = this.props;
        return (
            <IconButton disabled={ isSaving || disabled } size='small'  style={{color: isSaving || disabled ? '#9e9e9e' : '#F44336'}} onClick={ () => this.onClick() } aria-label="Удалить">
                <DeleteForeverIcon fontSize="small"/>
            </IconButton>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    return { 
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
)(DeleteButton);