import React from 'react';
import { createRoot } from 'react-dom/client';
import {  Provider  } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

import store from './Utils/_reducer';
import Notifier from './Utils/components/Notifier';
import Confirm from './Utils/components/Confirm';
import Barcode from './Utils/components/Barcode';
import Profile from './Utils/components/Profile';


import { history } from './Utils/_helpers';

import './index.css';
import './App.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Auth from './Utils/auth';
import Reset from './Utils/reset';
const theme = createMuiTheme(
    {
        palette: {
            primary: {
                // light: '#0a529fc7',
                main: '#00539F',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
                contrastText: '#ffffff',
            },
            secondary: {
                // light: '#bbd9ea',
                main: '#00539F',
                // dark: will be calculated from palette.secondary.main,
                contrastText: '#0a529f',
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            contrastText: "#00529f",
            text: {
                primary: "#2e3b52",
                secondary: "#00539F",
                color: '#00529f',
            },
            Mui: {
                selected: {
                    backgroundColor: "#00529f",
                },
            },
        },
        overrides: {
            MuiMenuItem: {
                root: {
                    color: '#0a529f',
                    '&:hover': {
                        backgroundColor: '#bbd9ea',
                    },
                    '&$selected': {
                         backgroundColor: '#fbb725',
                    },
                }
            },
            MuiTableHead: {
                root: {
                    backgroundColor: '#f4f6f9',
                    color: '#2e3b52',
                }
            },
            MuiTableCell: {
                head:{
                    color: '#2e3b52',
                    fontWeight: 600,
                    // '&.MuiTableSortLabel-root.MuiTableSortLabel-active':{
                    //     color: '#fbb725 !important',
                    // }
                },
                root:{
                    fontSize: '0.75rem',
                    borderBottom: '1px solid rgb(119 117 117)'
                },
                footer: {
                    color: "#2e3b52",
                },
                body:{
                    color: '#2e3b52',
                },
                sizeSmall: {
                    padding: '0px 8px 0px 8px'
                }
            },
            MuiTableSortLabel:{
                root: {
                    color: '#2e3b52',
                    '&:hover':{
                        color: '#2e3b52',
                    }
                },
                icon: {
                    color: '#2e3b52',
                    fontSize: '1.2rem'
                },
                active: {
                    color: '#2e3b52 !important',
                    fontWeight: 600,
                    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':{
                        color: '#2e3b52',
                        fontSize: '1.2rem'
                    }
                },

            },
            MuiIconButton: {
                root: {
                    // color: '#fbb725',
                    '&:hover': {
                        backgroundColor: '#bbd9ea',
                    }
                },
            },
            MuiFormLabel: {
                root: {
                    color: '#000000',
                    fontWeight: 600,
                }
            },
            MuiInputBase: {
                root: {
                    color: '#000000',
                }
            },
            MuiInput:{
                underline: {
                    '&:before': {
                        borderBottom: '1px solid #000000'
                    },
                    '&:hover:not(.Miu-disabled):before': {
                        borderBottom: '2px solid #000000'
                    }
                }
            },
            MuiPickersDay: {
                day: {
                    color: '#0a529f'
                },
                current: {
                    backgroundColor: '#fbb725'
                }
            },
            MuiPickersCalendarHeader:{
                switchHeader: {
                    color: '#0a529f',
                    fontWeight: 600,
                },
                daysHeader: {
                    backgroundColor: '#bbd9ea',
                    padding: 3,
                    '& span':{
                        color: '#0a529f'
                    }
                }
            },
            MuiAutocomplete:{
                option:{
                    color: '#0a529f',
                    '&:hover': {
                        backgroundColor: '#bbd9ea'
                    },
                    // '&[aria-selected="true"]':{
                    //     backgroundColor: '#fbb725'
                    // },
                    // '&[data-focus="true"]':{
                    //     backgroundColor: '#fbb725'
                    // }
                }
            },
            MuiDialogTitle:{
                root:{
                    color: '#ffffff',
                    backgroundColor: '#3fbe6e',
                    padding: '8px 24px'
                }
            },
            MuiCardHeader:{
                content: {
                    color: '#2e3b52'
                }
            }
        }
    }, ruRU
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path='/login' component={Auth}/>
                    <Route exact path='/reset' component={Reset}/>
                    <Route path='/' component={App}/>
                </Switch>
            </Router>
            <Notifier />
            <Confirm />
            <Barcode />
            <Profile />
        </Provider>
    </ThemeProvider>,
    // document.getElementById('root')
);

serviceWorker.unregister();
