import React, { Component } from 'react';
import compose from 'recompose/compose';
import { Route } from 'react-router';
import { createStyles, withStyles } from '@material-ui/core/styles';


import Drawer from '@material-ui/core/Drawer';

import { List } from '../Utils/components';

import {GET_LIST, provider } from '../Utils/_services/provider';

import { TextField, StatusField, BooleanField } from '../tpls/fields';

import Filter from './Filter';
import Show from './Show';
import Create from './Create'
import Edit from './Edit'

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%'
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

const statuses = [
    {id: 'Active', name: 'Активен'},
    {id: 'Disabled', name: 'Заблокирован'},
]

class UsersList extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filter: { },
            roles: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    async UNSAFE_componentWillMount() {
        const { data } = await provider(
            GET_LIST,
            '/roles',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const roles = data.map( d => {return { id: d.id, name: d.label }});
        if(this._isMounted ){
            this.setState({
                roles
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { filter, roles } = this.state;
        return (
            <Route path="/users/:id/:action">
                    {({ match, location }) => {
                        const isMatch = !!(
                            match &&
                            match.params
                        );
                        const create = location.pathname==="/users/create";
                        return (
                            <div className={classes.root}>
                                <List
                                    classes={{root: (isMatch || create)&&classes.listWithDrawer}}
                                    refresh={true}
                                    creat={true}
                                    show={true}
                                    basePath='users'
                                    pathToShow={`/show`}
                                    filters={<Filter roles={roles} />}
                                    match={match}
                                    filterDefaultValues={filter}
                                    perPage={25} 
                                    sort={{ field: 'updateAt', order: 'DESC' }} 
                                >
                                    <TextField
                                        notSort={true}
                                        source="fullname"
                                        label="Имя"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="email"
                                        label="E-mail"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="role"
                                        label="Роль"
                                    />
                                    <StatusField
                                        notSort={true}
                                        source="status"
                                        label="Статус"
                                    />
                                    <TextField
                                        notSort={true}
                                        source="ips"
                                        label="IP адреса"
                                    />
                                    <BooleanField 
                                        notSort={true}
                                        source="externalAccess"
                                        label="Внешний доступ"
                                    />
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch || create}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {(isMatch&&match.params.action === 'show') ? (
                                        <div style={{padding: 0}}>
                                            <Show id={match.params.id} roles={roles} statuses={statuses}/>
                                        </div>
                                    ) : null}
                                    {(isMatch&&match.params.action === 'edit') ? (
                                        <div style={{padding: 0}}>
                                            <Edit id={match.params.id} roles={roles}/>
                                        </div>
                                    ) : null}
                                    {create ? (
                                            <div style={{padding: 0}}>
                                                <Create roles={roles}/>
                                            </div>
                                    ) : null}
                                </Drawer>
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

export default compose(
    withStyles(styles),
)(UsersList);
