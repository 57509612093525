import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import Actions from './components/Actions';
import { Labeled, TextField, HTMLField, BooleanField, SelectField, DateField } from '../tpls/fields'

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    cardStyleFull: {
        width: '-webkit-fill-available',
        padding: theme.spacing(1),
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        height: `calc(100vh - ${theme.spacing(10)}px)`,
        overflowY: 'auto',
        position: 'relative'
    },
    cardHeaderMain: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    },
    flex10: {
        flex: '10em'
    },
    flex20: {
        flex: '20em'
    }
});

class ReferenceShow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    componentDidMount(){
        if(!!(this.props.match&&this.props.match.params&&this.props.match.params.id)){
            this.fetchData(this.props.match.params.id)
        }
    }

    async fetchData(id) {
        let path =  history.location.pathname.replace(`/${id}`, '');
        const { data } = await provider(
            GET_ONE,
            path,
            id
        );
        if(data) {
          this.setState({ data });
        }
    }

    render() {
        const { classes } = this.props;
        const { data = {} } = this.state;
        return (
            <Card className={classes.cardStyleFull}>
                <CardHeader
                    classes={{ root: classes.cardHeaderMain }}
                    action={ <Actions />}
                />
                <CardContent style={{padding: 0, display: 'flex'}}>
                    <Labeled label="Код" className={classes.flex10}>
                            <TextField record={data} source="code"/>
                    </Labeled>
                    <Labeled label="Доступность" className={classes.flex10}>
                        <BooleanField record={data} source="availability"/>
                    </Labeled>
                    <Labeled label="Название" className={classes.flex20}>
                        <TextField record={data} source="name"/>
                    </Labeled>
                    <Labeled label="Контракт" className={classes.flex20}>
                        <TextField record={data} source="contract"/>
                    </Labeled>
                    <Labeled label="Скидка" className={classes.flex10}>
                        <TextField record={data} source="discount"/>
                    </Labeled>
                    <Labeled label="Начало" className={classes.flex10}>
                        <DateField record={data} source="start"/>
                    </Labeled>
                    <Labeled label="Окончание" className={classes.flex10}>
                        <DateField record={data} source="end"/>
                    </Labeled>
                </CardContent>
                <CardContent style={{padding: 0, display: 'flex'}}>
                    <Labeled label="База формирования скидки" className={classes.flex20}>
                        <SelectField record={data} source="discount_dase"
                            options={[
                                { 
                                    id: 'У',
                                    name: 'Услуга'
                                },
                                { 
                                    id: "Ч",
                                    name: 'Чек'
                                },
                                { 
                                    id: 'Н',
                                    name: 'Нулевая стоимость'
                                },
                                { 
                                    id: 'Т',
                                    name: 'Скидка от теста'
                                },
                                { 
                                    id: 'С',
                                    name: 'По себестоимости'
                                },
                            ]}
                        />
                    </Labeled>
                    <Labeled label="Влияние" className={classes.flex20}>
                        <SelectField 
                            record={data} 
                            source="influence"
                            options={[
                                { 
                                    id: 'А',
                                    name: 'Анализ'
                                },
                                { 
                                    id: "К",
                                    name: 'Комплекс'
                                },
                                { 
                                    id: 'АК',
                                    name: 'Анализ/Комплекс'
                                },
                            ]}
                        />
                    </Labeled>
                </CardContent>
                <CardContent>
                    <Labeled label="Описание" className={classes.flex20}>
                        <HTMLField record={data} source="description"/>
                    </Labeled>
                </CardContent>
                <CardContent>
                    <Labeled label="Ограничения" className={classes.flex20}>
                        <HTMLField record={data} source="limitations"/>
                    </Labeled>
                </CardContent>
            </Card>
        );
    }
}

const enhance = compose(
    connect(),
    withStyles(styles),
);

export default enhance(ReferenceShow);
