import { fetchConstants } from '../_constants';

export const fetchActions = {
    start,
    error,
    success
};

function start() {
    return { type: fetchConstants.START };
}

function error() {
    return { type: fetchConstants.ERROR };
}

function success() {
   return { type: fetchConstants.SUCCESS };
}