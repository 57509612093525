import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
}

const BooleanField = ({classes, style, ...props}) => (
    <div className={classes.main} style={style}>
        <div className={classes.div} style={{color: props.value ? "#4CAF50" : "#ec716a"}}>
            {props.value
                ?
                    <CheckIcon size="small" />
                :
                    <CloseIcon size="small" />
            }
        </div>
    </div>
)

export default withStyles(styles)(BooleanField);