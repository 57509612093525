import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import {GET_LIST, provider } from '../Utils/_services/provider';

import { DateInput, AutocompleteInput, TextInput } from '../tpls/input';
import { Filter } from '../Utils/components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    date: {
        display: 'flex',
        width: theme.spacing(15),
        marginRight: 20
    },
    phone: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    user: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class CalculatorFilter extends Component {
    _isMounted = false;

    state = {
        users: []
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    normolize = value => (
        value.replace(/\D+/g,"")
    )

    async UNSAFE_componentWillMount() {
        const { data } = await provider(
            GET_LIST,
            '/users',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(this._isMounted){
            const users = data.map( user => ({
                name: user.fullname,
                id: user.id
            }))
            this.setState({
                users
            });
        }
    }
    
    normolize = value => (
        value.replace(/\D+/g,"")
    )

    render(){
        const { classes, filterDefaultValues = {}, formValues = {}, ...props } = this.props;
        const { users } = this.state;
        return (  
            <Filter form="calculatorFilter" filterValues={filterDefaultValues} {...props}>
                <DateInput
                    source="startDate"
                    label={`С`}
                    alwaysOn
                    // minDate={ new Date(moment(formValues.endDate).subtract(30, 'days'))}
                    maxDate={new Date(formValues.endDate)}
                    className={classes.date}
                />
                <DateInput
                     source="endDate"
                     label={`По`}
                     alwaysOn
                     minDate={new Date(formValues.startDate)}
                     maxDate={ new Date() }
                     className={classes.date}
                />
                <TextInput 
                    source="phone"
                    label={`Телефон`}
                    alwaysOn
                    className={classes.phone}
                />
                <AutocompleteInput 
                    source="user_update"
                    label={`Пользователь`}
                    options={users}
                    alwaysOn
                    className={classes.user}
                />
            </Filter>
        );
    }
}



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('calculatorFilter')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(CalculatorFilter);