import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';

import CloseIcon from '@material-ui/icons/Close';

import { Button } from '@material-ui/core';

import { history } from '../../Utils/_helpers';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


class ActionsShow extends Component {
    close = () =>{
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}`)
    }
    
    render(){
        const { 
            classes, 
        } = this.props;
        return(
            <Fragment>
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}
    
export default  compose(
    connect(),
    withStyles(styles),
)(ActionsShow);
