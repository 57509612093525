import { barcodeConstants } from '../_constants';

const { BARCODE_HIDE, BARCODE_SHOW } = barcodeConstants;
const initialState = {
  barcode: false,
}

export function barcode (state = initialState, action) {
  const { type } = action
  switch (type) {
    case BARCODE_SHOW:
      return { barcode: true, profile: false };
    case BARCODE_HIDE:
      return { barcode: false }
    default:
      return state
  }
}