import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';

class SwitchInput extends Component{
  render(){
    const { input, ...props }  = this.props;
    return(
      <div style={{display: 'grid'}}>
        <label style={{
                color: 'rgba(0, 0, 0, 0.54)',
                padding: 0,
                fontSize: '0.75rem',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: '0.00938em'
            }}
        >
            {props.label}
        </label>
        <Switch
            checked={!!input.value}
            onChange={(event, value) => {
                return input.onChange(value)
            }}
            value={input.value}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            {...props}
        />
      </div>
    )
  }
}

export default SwitchInput;
