import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import NotFoundSvg from '../../img/404.svg';
import { history } from '../_helpers';

const styles = theme =>({
    center: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center!important',
    },
    image: {
        maxWidth: '100%',
        height: 'auto'
    }
});

class NotFound extends Component {
    render(){
        const { classes } = this.props;
        return (
            <div className={classes.center}>
                <div className="logo">
                    <img src={NotFoundSvg} alt="404"/>
                </div>
                <h1>Ууупс! Страница не найдена</h1>
                <p>
                    Страница, которую вы запрашиваете, не найдена.
                </p>
                <Button color="primary" variant="outlined" onClick={() => history.goBack()}>Вернуться</Button>
            </div>
        );
    }
}


export default withStyles(styles)(NotFound);
