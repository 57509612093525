
import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Labeled, TextField, StatusField, SelectField, BooleanField } from '../../tpls/fields'
import Actions from './Actions';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    info:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(13)}px)`,
    },
    flex20: {
        flex: '20em'
    },
    flex30: {
        flex: '30em'
    },
    flex40: {
        flex: '40em'
    },
    flex80: {
        flex: '80em'
    },
    fullField: {
        width: '100%',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto',
        width: '100%'
    },
});

class UserInfo extends Component {
    render(){
        const {data, classes, roles } = this.props
        return(
            <Card  className={classes.root}>
                <CardHeader
                    action={ <Actions />}
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    <div className={classes.info} >
                        <div style={{display:'flex'}}>
                            <Labeled label="Имя" className={classes.flex80}>
                                <TextField record={data} source="fullname"/>
                            </Labeled>
                        </div> 
                        <div style={{display:'flex'}}>
                            <Labeled label="E-mail" className={classes.flex40}>
                                <TextField record={data} source="email"/>
                            </Labeled>
                            <Labeled label="Роль" className={classes.flex20}>
                                <SelectField record={data} options={roles} source="role"/>
                            </Labeled>
                            <Labeled label="Статус" className={classes.flex20}>
                                <StatusField record={data} source="status"/>
                            </Labeled>
                        </div>
                        <div style={{display:'flex'}}>
                            <Labeled label="IP адреса" className={classes.flex80}>
                                <TextField record={data} source="ips"/>
                            </Labeled>
                            <Labeled label="Внешний доступ" className={classes.flex80}>
                                <BooleanField record={data} source="externalAccess"/>
                            </Labeled>
                        </div> 
                    </div>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(UserInfo);