import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';
import { connect } from 'react-redux';

import CardContent from '@material-ui/core/CardContent';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { history } from '../../Utils/_helpers';

import {GET_LIST, CREATE, provider } from '../../Utils/_services/provider';

import { TextInput, SelectInput, AutocompleteInput, PhoneInput } from '../../tpls/input';

export const styles = theme => createStyles({
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    name: {
        width: '30%'
    },
    phone: {
        width: '20%',
        marginLeft: '3%'
    },
    city: {
        width: '15%',
        marginLeft: '3%'
    },
    sex: {
        width: '10%',
        marginLeft: '3%'
    },
})


const submit = async ( data ) =>{
    var values = Object.assign({}, data);
    values.phone = values.phone.replace(/\D+/g,"");
    values.analysis = JSON.stringify(values.analysis);
    values.data = JSON.stringify(values.data);
    const { status } = await provider(
        CREATE,
        '/calculator',
        {
            data: values,
        }
    );
    if(status===200){
        history.goBack()
    }
}

class CalculatorForm extends Component {
    state = {
        initial: {
            data: [],
            analysis: []
        },
    }

    async UNSAFE_componentWillMount() {
        const { data } = await provider(
            GET_LIST,
            '/city',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        this.setState({
            cities: data
        });
    }

    componentDidMount() {
        const { initialize } = this.props;
        const { initial } = this.state;
        initialize(initial);
    }

    formatName = value =>(
        value ? [].map.call( value.split(/\s+/), word => word.substring(0, 1).toUpperCase() + word.substring(1)).join(' ') : ''
    )

    checkGender = (data) => {
        let block = null;
        if(data){
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if(!!element.sex&&element.sex!== 'U'){
                    block = element.sex === "F" ? "M" :  "F";
                }
            }
        }
        return block;
    }

    onFocus = (e) => {
        if(e.target.value.length===0){
            this.props.change('phone',  '+375')
        }
    }

    onChange = (event, value) => {
        this.props.change('city', value ? value.id : null)
    }

    render(){
        const { classes, handleSubmit, formValues, isSaving } = this.props;
        let { cities } = this.state;
        const data = formValues ? formValues.data : [];
        let minsk_only = false
        data.forEach(element => {
            if(element.minsk_only&&!minsk_only){
                minsk_only = true;
            }
        });
        if(minsk_only) {
            cities = cities.filter( city => city.id === '5d985468ea4291b90b636d53')
        }
        return(
            <form onSubmit={handleSubmit}>
                <CardContent classes={{
                        root: classes.cardContent
                    }} 
                    className={classes.content}
                >
                    <Field
                        name="first_name"
                        component={TextInput}
                        label={`Имя`}
                        disabled={isSaving}
                        className={classes.name}
                        normalize={this.formatName}
                    />
                    <Field
                        name="phone"
                        component={PhoneInput}
                        label={`Телефон`}
                        disabled={isSaving}
                        className={classes.phone}
                        onFocus={this.onFocus}
                    />
                    <Field
                        name="city"
                        options={cities}
                        disabled={isSaving}
                        component={AutocompleteInput}
                        label={`Город`}
                        onChange={this.onChange}
                        className={classes.city}
                    />
                    <Field
                        name="sex"
                        options={[
                            {name: "Муж", id: "M", disabled: this.checkGender(data)==="M"},
                            {name: "Жен", id: "F", disabled: this.checkGender(data)==="F"},
                        ]}
                        disabled={isSaving}
                        component={SelectInput}
                        label={`Пол`}
                        className={classes.sex}
                    />
                    <Field
                        style={{ display: 'none'}}
                        name="data"
                        disabled={isSaving}
                        component={TextInput}
                        label={`Корзина`}
                    />
                </CardContent>
            </form>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('calculatorForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
    reduxForm({
        form: 'calculatorForm',
        onSubmit: submit,
        validate: (values) => {
            const errors = {};
            if (!values.first_name) {
                errors.first_name = 'Обазятельное поле'
            } else if (values.first_name.length < 3) {
                errors.first_name = 'Не менее 3-х символов'
            }
            if (!values.phone) {
                errors.phone = 'Обазятельное поле'
            } else if (values.phone.length<2) {
                errors.phone = 'Не верный формат номера'
            } else if (parsePhoneNumberFromString(values.phone)) {
                if (!parsePhoneNumberFromString(values.phone).isValid()){
                    errors.phone = 'Не верный формат номера'
                }
            }
            if (!values.city) {
                errors.city = 'Обазятельное поле'
            } 
            if (!values.sex) {
                errors.sex = 'Обазятельное поле'
            } 
            if (!values.data) {
                errors.data = 'Нет услуг в корзине'
            }else if(values.data.length<1){
                errors.data = 'Нет услуг в корзине'
            }
            return errors;
        },
        destroyOnUnmount: false
    })
);

export default enhance(CalculatorForm);