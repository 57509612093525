
import React, { Component, Fragment } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Labeled, TextField, BooleanField } from '../../tpls/fields'

export const styles = theme => createStyles({
    table: {
        fontSize: 11
    },
    inComplex: {
        padding: '0px 8px',
    },
    info:{
        overflow: 'auto',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(13)}px)`,
    },
    flex20: {
        flex: '20em'
    },
    flex30: {
        flex: '30em'
    },
    flex40: {
        flex: '40em'
    },
    flex80: {
        flex: '80em'
    },
    fullField: {
        width: '100%',
    },
    cellRoot: {
        padding: `0 ${theme.spacing(1)}px`,
        fontSize: '0.7rem'
    },
    heading: {
        color: '#0a529f',
        fontWeight: 600,
        padding: '0px 8px',
        wifth: '90%'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardStyleRigth: {
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto',
        width: '100%'
    },
    cardHeaderInfo: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: '#fbb725',
        color: '#0a529f',
    },
    cardTitleInfo: {
        color: '#0a529f;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    closeInfo: {
        backgroundColor: '#e93f33',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ce8e96',
        }
    },
});


const daysLabel = (day) => {
    let days = 'дней';
    if(day===1){
        days = 'день'
    }else if(day>1&& day<5){
        days = 'дня'
    }
    return `${day} ${days}`;
}

class AnalysisInfo extends Component {
    render(){
        const {data, close, classes } = this.props
        const record = {
            application: data.application ? `Приложение ${data.application}` : '-',
            panel: data.panel ? data.panel : '-',
            code: data.code || '-',
            price: data.price || '-',
            deadline: data.deadline ? daysLabel(data.deadline) : '-',
            material: data.material || '-',
            third_party: data.third_party || false,
            discount: data.discount || false,
            happy_hours: data.happy_hours || false,
            isComplex: data.isComplex || false,
            other_name: data.other_name,
            description: data.description,
            inComplex: data.inComplex || [],
        }
        return(
            <Card  className={classes.cardStyleRigth}>
                <CardHeader
                    classes={{
                        title: classes.cardTitleInfo,
                        root: classes.cardHeaderInfo
                    }}
                    style={{ paddingBottom: 6}}
                    title={`${data.code} ${data.name}`}
                    action={
                        <Button  
                            style={{
                                padding: '0px 10px',
                                marginTop: 6
                            }}
                            classes={{
                                root: classes.closeInfo
                            }}                                                        
                            size="small"
                            color="secondary"
                            variant="outlined"
                            startIcon={<CloseIcon />}
                            onClick={close} 
                        >
                            Закрыть
                        </Button>
                    }
                />
                <CardContent classes={{
                        root: classes.cardContent
                    }}
                >
                    <div className={classes.info} >
                        <div style={{display:'flex'}}>
                            <Labeled label="Код" className={classes.flex20}>
                                <TextField record={record} source="code"/>
                            </Labeled>
                            <Labeled label="Цена" className={classes.flex20}>
                                <TextField record={record} source="price"/>
                            </Labeled>
                            <Labeled label="Срок" className={classes.flex20}>
                                <TextField record={record} source="deadline"/>
                            </Labeled>
                            <Labeled label="Биоматериал" className={classes.flex40}>
                                <TextField record={record} source="material"/>
                            </Labeled>
                        </div> 
                        <div style={{display:'flex'}}>
                            <Labeled label="Приложение" className={classes.flex20}>
                                <TextField record={record} source="application"/>
                            </Labeled>
                            <Labeled label="Панель" className={classes.flex80}>
                                <TextField record={record} source="panel"/>
                            </Labeled>
                        </div> 
                        <div style={{display:'flex'}}>
                            <Labeled label="Комплекс" className={classes.flex20}>
                                <BooleanField record={record} source="isComplex"/>
                            </Labeled>
                            <Labeled label="Скидки" className={classes.flex20}>
                                <BooleanField record={record} source="discount"/>
                            </Labeled>
                            <Labeled label="Счастливые часы" className={classes.flex30}>
                                <BooleanField record={record} source="happy_hours"/>
                            </Labeled>
                            <Labeled label="Сторонний исполнитель" className={classes.flex30}>
                                <BooleanField record={record} source="third_party"/>
                            </Labeled>
                        </div>
                        {record.description&&
                            <Labeled label="Описание" className={classes.fullField}>
                                <TextField style={{color: 'red'}} record={record} source="description" />
                            </Labeled>
                        }
                        {record.other_name&&
                            <Labeled label="Дополнительная информация" className={classes.fullField}>
                                <TextField record={record} source="other_name" />
                            </Labeled>
                        }
                        {(record.isComplex&&record.inComplex.length>0)&&
                            <Fragment>
                                <Typography className={classes.heading}>В комплексе иследования по {record.inComplex.length} показателям</Typography>
                                <div className={classes.inComplex}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: '5%'}} classes={{ root: classes.cellRoot}}>
                                                    #
                                                </TableCell>
                                                <TableCell style={{width: '7%'}} classes={{ root: classes.cellRoot}}>
                                                    Код
                                                </TableCell>
                                                <TableCell style={{width: '45%'}} classes={{ root: classes.cellRoot}}>
                                                    Название
                                                </TableCell>
                                                <TableCell style={{width: '33%'}} classes={{ root: classes.cellRoot}}>
                                                    Материал
                                                </TableCell>
                                                <TableCell style={{width: '10%'}} classes={{ root: classes.cellRoot}}>
                                                    Срок
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {record.inComplex.map( (item, i )=>
                                                <TableRow key={item.code}>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{i+1}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        <b>{item.code}</b>
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {item.material}
                                                    </TableCell>
                                                    <TableCell  classes={{ root: classes.cellRoot}}>
                                                        {daysLabel(item.deadline)}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fragment>
                        }
                    </div>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(AnalysisInfo);