import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';
import { connect } from 'react-redux';

import { getFormValues, submit, isInvalid } from 'redux-form'

import SaveIcon from '@material-ui/icons/Save';
import { Button } from '@material-ui/core';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


class ActionsProfile extends Component {
    render(){
        const { 
            classes, 
            dispatch,
            isSaving,
            invalid
        } = this.props;
        return(
            <Fragment>
                <Button                                                         
                    className={classes.button}                                                     
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={isSaving || invalid}
                    onClick={() => dispatch(submit('profileForm'))}
                >
                    Сохранить
                </Button>
            </Fragment>
        );
    }
}
    
const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        formValues: getFormValues('userEditForm')(state),
        invalid: isInvalid('userEditForm')(state),
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ActionsProfile);