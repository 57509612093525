import React, { Component } from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Profile from './Profile';
// import Password from './Password';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
});

class UserProfile extends Component {
    render() {
        const { classes } = this.props;
        return(
            <div className={classes.content}>
                <Profile />
                {/* <Password /> */}
            </div>
        )
    }
}

const enhance = compose(
    withStyles(styles),
);

export default enhance(UserProfile);