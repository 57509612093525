import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {  
    getFormValues,
    Field, 
    reduxForm 
} from 'redux-form';

import { Editor } from '@tinymce/tinymce-react'; 

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {GET_ONE, UPDATE, provider } from '../Utils/_services/provider';
import { history } from '../Utils/_helpers';

import ActionsEdit from './components/ActionsEdit';
import { TextInput } from '../tpls/input';

export const styles = theme => createStyles({
    root: {
        height: `calc(100vh - ${theme.spacing(8)}px)`
    },
    cardStyleFull: {
        width: '-webkit-fill-available',
        padding: theme.spacing(1),
        display: 'inline-block',
        verticalAlign: 'top',
        boxShadow: 'unset',
        height: `calc(100vh - ${theme.spacing(10)}px)`,
        overflowY: 'auto',
        position: 'relative'
    },
    cardHeaderMain: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    content: {
        display: 'inline-flex',
        width: '100%',
    },
    drawerPaper: {
        width: 240,
    },
    card: {
        '& div:first-child': { height: 'intrinsic'}
    },
    flex: {
        display: 'flex',
        flex: 'auto',
        padding: '0px 16px'
    },
    flex10: {
        flex: '10em'
    },
    name: {
        marginLeft: theme.spacing(2),
    },
    cardHeaderShow: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        // backgroundColor: '#0a529f'
    },
    cardContent: {
        paddingTop: 0,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    cardTitleShow: {
        // color: '#bbd9ea;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardStyleShow: {
        height: `calc(100vh - ${theme.spacing(9)}px)`,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        display: 'inline-block',
        boxShadow: 'unset',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 'auto'
    }
});

const toolbar = 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help';

const plugins = [
    'advlist autolink lists link image', 
    'charmap print preview anchor help',
    'searchreplace visualblocks code',
    'insertdatetime media table paste wordcount'
]

const renderTinyMCE = (field) => {
    let props = Object.assign({}, field);
    delete props.input;
    delete props.meta;
    return <Editor
        apiKey="lhjm6ya162ilbidn7hs1kclbhoi13rzrfrrxtr7tuyol8enn"
        initialValue={field.input.value !== '' ? field.input.value : null}
        init={{
            height: 500,
            menubar: true,
            plugins,
            toolbar
        }}
        onBlur={ (event, value) => { 
            field.input.onChange(event.target.getContent()) 
        }}
    />
}

const submit = async ( values ) =>{
    const { code, name, description } = values;
    const { status } = await provider(
        UPDATE,
        '/reference',
        {  id: values._id,
            data: { code, name, description }
        }
    );
    if(status===200){
        history.goBack()
    }
}

class ReferenceEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    componentDidMount(){
        if(!!(this.props.match&&this.props.match.params&&this.props.match.params.id)){
            this.fetchData(this.props.match.params.id)
        }
    }

    async fetchData(id) {
        const pathname = history.location.pathname.split('/');
        const { data } = await provider(
            GET_ONE,
            `/${pathname[1]}`,
            id
        );
        if(data) {
            const { initialize } = this.props;
            initialize(data);
        }
    }

    render() {
        const { classes, isSaving, formValues } = this.props;
        return (
            <Card className={classes.cardStyleFull}>
                <CardHeader
                    classes={{ root: classes.cardHeaderMain }}
                    action={ <ActionsEdit />}
                />
                <CardContent style={{padding: 0, display: 'flex'}}>
                    {formValues&&
                        <div className={classes.flex}>
                            <Field
                                name="code"
                                component={TextInput}
                                label={`Код`}
                                disabled={isSaving}
                                className={classes.flex10}
                                normalize={this.formatName}
                            />
                            <Field
                                name="name"
                                component={TextInput}
                                label={`Название`}
                                disabled={isSaving}
                                className={classes.name}
                                onFocus={this.onFocus}
                            />
                        </div>
                    }
                </CardContent>
                <CardContent>
                    {formValues&&
                        <Field
                            name="description"
                            component={renderTinyMCE}
                            label={`Описание`}
                            disabled={isSaving}
                            onFocus={this.onFocus}
                        />
                        
                    }
                </CardContent>
            </Card>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('referenceForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
    reduxForm({
        form: 'referenceForm',
        onSubmit: submit,
        validate: (values) => {
            const errors = {};
            if (!values.code) {
                errors.code = 'Обазятельное поле'
            }
            if (!values.name) {
                errors.name = 'Обазятельное поле'
            }
            if (!values.description) {
                errors.city = 'Обазятельное поле'
            } 
            return errors;
        },
        destroyOnUnmount: false,
    })
);

export default enhance(ReferenceEdit);
