import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { TextField, BooleanField, SelectField, DateField, HTMLField } from '../../tpls/fields'


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    upload: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    fileLabel: {
        color: '#0a529f'
    },
    deleteLabel: {
        color: 'red'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
});

const STRING = 'string',
      INTEGER = 'integer',
      ARRAY = 'array',
      BOOLEAN = 'boolean',
      SELECT = 'select',
      DATE = 'date',
      OBJECT = 'object',
      HTML = 'html'

const headersNames ={
    Applications: {
        'code': {
            name: "Код",
            type: INTEGER
        },
        'name': {
            name: "Название",
            type: STRING
        }, 
    },
    NoAnalysis: {
        'code': {
            name: "Код",
            type: INTEGER
        },
        'name': {
            name: "Название",
            type: STRING
        }, 
    },
    Panels: {
        'code': {
            name: "Код",
            type: INTEGER
        },
        'name': {
            name: "Название",
            type: STRING
        }, 
    },
    Analysis: {
        'code': {
            name: "Код услуги",
            type: INTEGER
        },
        'name': {
            name: "Наименование услуги",
            type: STRING
        },
        'analog': {
            name: "Другое название услуги",
            type: ARRAY
        },
        'other_name': {
            name:  "Дополнительная информация об анализе",
            type: HTML
        },
        'material': {
            name: "Биоматериал для выполнения анализа",
            type: STRING
        }, 
        'reference': {
            name: "Правила подготовки",
            type: ARRAY
        }, 
        'price': {
            name: "Стоимость",
            type: STRING
        }, 
        'third_party': {
            name: "Сторонний исполнитель",
            type: BOOLEAN
        }, 
        'deadline': {
            name: "Срок выполнения",
            type: STRING
        }, 
        'application': {
            name: "Номер приложения (пункты)",
            type: INTEGER
        }, 
        'panel': {
            name: "ID Панели",
            type: STRING
        }, 
        'sex': {
            name: "Мужской/женский",
            type: SELECT,
            options: [
                { 
                    id: 'U',
                    name: 'Муж/Жен'
                },
                { 
                    id: "M",
                    name: 'Муж'
                },
                { 
                    id: 'F',
                    name: 'Жен'
                },
            ]
        }, 
        'discount': {
            name: "Признак предоставления скидки",
            type: BOOLEAN
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
        'happy_hours': {
            name: 'Скидка "Счастливые часы"',
            type: BOOLEAN
        },
        'minsk_only': {
            name: 'Только в Минске',
            type: BOOLEAN
        },
        'punkts': {
            name: 'Пункты',
            type: ARRAY
        },
        'excPunkts': {
            name: 'Пункты(исключение)',
            type: ARRAY
        },
        'availability': {
            name: 'Доступен в заказе',
            type: BOOLEAN
        },
    },
    AnalysisToComplex: {
        'code': {
            name: "Код услуги",
            type: INTEGER
        },
        'name': {
            name: "Наименование услуги",
            type: STRING
        },
        'analog': {
            name: "Другое название услуги",
            type: ARRAY
        },
        'other_name': {
            name:  "Дополнительная информация об анализе",
            type: STRING
        },
        'price': {
            name: "Стоимость",
            type: STRING
        }, 
        'third_party': {
            name: "Сторонний исполнитель",
            type: BOOLEAN
        }, 
        'isComplex': {
            name: "Комплекс",
            type: BOOLEAN
        }, 
        'deadline': {
            name: "Срок выполнения",
            type: STRING
        }, 
        'application': {
            name: "Номер приложения (пункты)",
            type: INTEGER
        }, 
        'complex': {
            name: "Состав услуг",
            type: ARRAY
        },
        'discount': {
            name: "Признак предоставления скидки",
            type: BOOLEAN
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
        'happy_hours': {
            name: 'Скидка "Счастливые часы"',
            type: BOOLEAN
        },
        'reference': {
            name: "Правила подготовки",
            type: ARRAY
        }, 
        'sex': {
            name: "Мужской/женский",
            type: SELECT,
            options: [
                { 
                    id: 'U',
                    name: 'Муж/Жен'
                },
                { 
                    id: "M",
                    name: 'Муж'
                },
                { 
                    id: 'F',
                    name: 'Жен'
                },
            ]
        }, 
        'availability': {
            name: 'Доступен в заказе',
            type: BOOLEAN
        },
    },
    Partners: {
        'code': {
            name: "Код типа ДК",
            type: INTEGER
        },
        'name': {
            name: "Название типа ДК",
            type: STRING
        },
        'contract': {
            name:"Контракт Silab",
            type: STRING
        },
        'start': {
            name: "Начало",
            type: DATE
        },
        'end': {
            name: "Окончание",
            type: DATE
        },
        'discount_dase': {
            name: "База формирования скидки",
            type: SELECT,
            options: [
                { 
                    id: 'У',
                    name: 'Услуга'
                },
                { 
                    id: "Ч",
                    name: 'Чек'
                },
                { 
                    id: 'Н',
                    name: 'Нулевая стоимость'
                },
                { 
                    id: 'Т',
                    name: 'Скидка от теста'
                },
                { 
                    id: 'С',
                    name: 'По себестоимости'
                },
            ]
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
        'discount': {
            name: "Размер скидки %",
            type: INTEGER
        },
        'influence': {
            name: "Влияние",
            type: SELECT,
            options: [
                { 
                    id: 'А',
                    name: 'Анализ'
                },
                { 
                    id: "К",
                    name: 'Комплекс'
                },
                { 
                    id: 'АК',
                    name: 'Анализ/Комплекс'
                },
            ]
        },
        'limitations': {
            name:"Исключения/ограничения",
            type: STRING
        },
        'availability': {
            name: "Доступность",
            type: BOOLEAN
        },
    },
    Insurance: {
        'code': {
            name: "Код типа ДК",
            type: INTEGER
        },
        'name': {
            name: "Название типа ДК",
            type: STRING
        },
        'contract': {
            name:"Контракт Silab",
            type: STRING
        },
        'documents': {
            name: "Документы",
            type: STRING
        },
        'validity': {
            name: "Срок действия",
            type: STRING
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
    },
    DiscountCard: {
        'code': {
            name: "Код типа ДК",
            type: INTEGER
        },
        'name': {
            name: "Название типа ДК",
            type: STRING
        },
        'contract': {
            name:"Контракт Silab",
            type: STRING
        },
        'start': {
            name: "Начало",
            type: DATE
        },
        'end': {
            name: "Окончание",
            type: DATE
        },
        'discount_dase': {
            name: "База формирования скидки",
            type: SELECT,
            options: [
                { 
                    id: 'У',
                    name: 'Услуга'
                },
                { 
                    id: "Ч",
                    name: 'Чек'
                },
                { 
                    id: 'Н',
                    name: 'Нулевая стоимость'
                },
                { 
                    id: 'Т',
                    name: 'Скидка от теста'
                },
                { 
                    id: 'С',
                    name: 'По себестоимости'
                },
            ]
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
        'discount': {
            name: "Размер скидки %",
            type: INTEGER
        },
        'influence': {
            name: "Влияние",
            type: SELECT,
            options: [
                { 
                    id: 'А',
                    name: 'Анализ'
                },
                { 
                    id: "К",
                    name: 'Комплекс'
                },
                { 
                    id: 'АК',
                    name: 'Анализ/Комплекс'
                },
            ]
        },
        'limitations': {
            name:"Исключения/ограничения",
            type: STRING
        },
        'availability': {
            name: "Доступность",
            type: BOOLEAN
        },
    },
    DiscountPast: {
        'code': {
            name: "Код типа ДК",
            type: INTEGER
        },
        'name': {
            name: "Название типа ДК",
            type: STRING
        },
        'contract': {
            name:"Контракт Silab",
            type: STRING
        },
        'start': {
            name: "Начало",
            type: DATE
        },
        'end': {
            name: "Окончание",
            type: DATE
        },
        'discount_dase': {
            name: "База формирования скидки",
            type: SELECT,
            options: [
                { 
                    id: 'У',
                    name: 'Услуга'
                },
                { 
                    id: "Ч",
                    name: 'Чек'
                },
                { 
                    id: 'Н',
                    name: 'Нулевая стоимость'
                },
                { 
                    id: 'Т',
                    name: 'Скидка от теста'
                },
                { 
                    id: 'С',
                    name: 'По себестоимости'
                },
            ]
        },
        'description': {
            name: "Примечание",
            type: STRING
        },
        'discount': {
            name: "Размер скидки %",
            type: INTEGER
        },
        'influence': {
            name: "Влияние",
            type: SELECT,
            options: [
                { 
                    id: 'А',
                    name: 'Анализ'
                },
                { 
                    id: "К",
                    name: 'Комплекс'
                },
                { 
                    id: 'АК',
                    name: 'Анализ/Комплекс'
                },
            ]
        },
        'limitations': {
            name:"Исключения/ограничения",
            type: STRING
        },
        'availability': {
            name: "Доступность",
            type: BOOLEAN
        },
    },
    DiscountCardBingo: {
        'code': {
            name: "Код типа ДК",
            type: INTEGER
        },
        'name': {
            name: "Название типа ДК",
            type: STRING
        },
        'application': {
            name: "Номер приложения (пункты)",
            type: INTEGER
        },
        'complex': {
            name: "Код пакета для услуги",
            type: ARRAY
        },
        'price': {
            name: "Стоимость пакета/услуги",
            type: STRING
        },
        'deadline': {
            name: "Срок выполнения (рабочих дней*)",
            type: INTEGER
        },
    },
    MedicalCenter: {
        'id': {
            name: "ID",
            type: INTEGER
        },
        'state': {
            name: "Область",
            type: STRING
        },
        'city': {
            name: "Город",
            type: STRING
        },
        'address': {
            name: "Адрес",
            type: STRING
        },
        'workHours': {
            name: "Режим работы",
            type: OBJECT
        },
        'schedules': {
            name: "Режим приема материала",
            type: ARRAY
        },
        'orientirs': {
            name: "Ориентиры",
            type: STRING
        },
        'pandus': {
            name: "Наличие пандуса",
            type: STRING
        },
        'descriptions': {
            name: "Примечание",
            type: STRING
        },
        'code': {
            name: "Код",
            type: INTEGER
        },
    }
} 

const SwitchTypeElement = ({sheet, el, value}) => {
    const { type = STRING, options = [] } = headersNames[sheet][el] || {};
    switch(type){
        case ARRAY:
            return <span>{!!value ? value.join(', ') : ''}</span>;
        case SELECT:
            return <SelectField value={value} options={options} />
        case BOOLEAN: 
            return <BooleanField value={value} />;
        case DATE: 
            return <DateField value={value} />;
        case STRING: 
            return <TextField value={value} />;
        case INTEGER: 
            return <TextField value={value} />;
        case HTML: 
            return <HTMLField value={value} />;
        default:
            return <span>{typeof value === 'object' ? JSON.stringify(value) : value }</span>;
    }
}

const RowAdd = ({sheet, row}) =>{
    const array = Object.keys(row);
    return array.map( (el, i)=>
        <TableRow key={`${row.code}-${i}`}>
            {i===0&&
                <TableCell rowSpan={array.length} component="th" scope="row">
                    {row.code}
                </TableCell>
            }
            <TableCell component="th" scope="row">
                {headersNames[sheet][el] ? headersNames[sheet][el].name : el}
            </TableCell>
            <TableCell component="th" scope="row">
                <SwitchTypeElement value={row[el]} el={el} sheet={sheet} />
            </TableCell>
        </TableRow>
    );
}

const RowUpdate = ({sheet, row}) =>{
    const array = Object.keys(row.data);
    return array.map( (el, i)=>
        <TableRow key={`${row.code}-${i}`}>
            {i===0&&
                <TableCell rowSpan={array.length} component="th" scope="row">
                    {row.code}
                </TableCell>
            }
            <TableCell component="th" scope="row">
                {headersNames[sheet][el].name}
            </TableCell>
            <TableCell component="th" scope="row">
                <SwitchTypeElement value={row.data[el].old} el={el} sheet={sheet} />
            </TableCell>
            <TableCell component="th" scope="row">
                <SwitchTypeElement value={row.data[el].new} el={el} sheet={sheet} />
            </TableCell>
        </TableRow>
    )
}

class DeleteCategory extends Component {
    state = {
        perPade: 25,
        page: 0
    }
    handleChangePage = (event, newPage) =>{
        this.setState({page: newPage});
    }
    render(){
        const {classes, data } = this.props;
        const { page, perPade } = this.state;
        return (
            <div style={{width: '100%'}}>
                <h4>Удалить</h4>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Код</TableCell>
                            <TableCell>Название</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page*perPade, page*perPade+perPade).map( (row, index) =>
                           <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.code}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {data.length>25&&
                    <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={perPade}
                        page={page}
                        onChangePage={this.handleChangePage}
                    />
                }
            </div>
        )
    }
}

class AddCategory extends Component {
    state = {
        perPade: 25,
        page: 0
    }
    handleChangePage = (event, newPage) =>{
        this.setState({page: newPage});
    }
    render(){
        const {classes, data, sheet } = this.props;
        const { page, perPade } = this.state;
        return (
            <div style={{width: '100%'}}>
                <h4>Добавить</h4>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Код</TableCell>
                            <TableCell>Поле</TableCell>
                            <TableCell>Значение</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page*perPade, page*perPade+perPade).map( (row, index) =>
                            <RowAdd key={index} sheet={sheet} row={row} />
                        )}
                    </TableBody>
                </Table>
                {data.length>25&&
                    <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={perPade}
                        page={page}
                        onChangePage={this.handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
            </div>
        )
    }
}

class UpdateCategory extends Component {
    state = {
        perPade: 25,
        page: 0
    }
    handleChangePage = (event, newPage) =>{
        this.setState({page: newPage});
    }
    render(){
        const {classes, data, sheet } = this.props;
        const { page, perPade } = this.state;
        return (
            <div style={{width: '100%'}}>
                <h4>Обновить</h4>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Код</TableCell>
                            <TableCell>Поле</TableCell>
                            <TableCell>Старое значение</TableCell>
                            <TableCell>Новое значение</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page*perPade, page*perPade+perPade).map( (row, index) =>
                            <RowUpdate key={index} sheet={sheet} row={row} />
                        )}
                    </TableBody>
                </Table>
                {data.length>25&&
                    <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={perPade}
                        page={page}
                        onChangePage={this.handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
            </div>
        )
    }
}

const Sheets = ({classes, sheet, data, handleChange, expanded }) =>{
    if(data.add.length>0||data.update.length>0||data.delete.length>0){
        return (
            <ExpansionPanel key={sheet} expanded={expanded === sheet} onChange={handleChange(sheet)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={sheet}
                    id={`${sheet}-header`}
                >
                    <div className={classes.heading}>{sheet}</div>
                    <div className={classes.secondaryHeading}>
                        <span> Добавить: <span style={{color: 'green'}}>{data.add.length} </span> </span>
                        <span style={{marginLeft: 'auto'}}> Удалить: <span style={{color: 'red'}}>{data.delete.length} </span> </span>
                        <span style={{marginLeft: 'auto'}}> Обновить: <span style={{color: 'blue'}}>{data.update.length} </span> </span>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    {data.add.length>0&&
                        <AddCategory classes={classes} data={data.add} sheet={sheet}/>
                    }
                    {data.delete.length>0&&
                        <DeleteCategory classes={classes} data={data.delete}/>
                    }
                    {data.update.length>0&&
                        <UpdateCategory classes={classes} data={data.update} sheet={sheet}/>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }else{
        return null
    }
}


class CheckData extends Component {
    render() {
        const { classes, prepeireData, expanded, handleChange } = this.props;
        return (
            <Fragment>
                {Object.keys(prepeireData).map((sheet, i) =>
                    <Sheets 
                        key={i} 
                        sheet={sheet} 
                        data={prepeireData[sheet]} 
                        handleChange={handleChange} 
                        expanded={expanded} 
                        classes={classes}
                    />    
                )}    
            </Fragment>
        );
    }
}

export default compose(
    withStyles(styles)
)(CheckData);
