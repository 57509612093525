import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Switch, Route, Link } from "react-router-dom";

import Acting from './components/Acting';
import Past from './components/Past';
// import Card from './components/Card';


const styles = theme =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        list: {
            flexGrow: 1,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        listWithDrawer: {
            marginRight: 584,
        },
        drawerPaper:{
            width: 584,
            marginTop: theme.spacing(7)
        }
});

class DiscountList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Route
                    path="/discount"
                    render={({ location }) =>{
                        return(
                            <Fragment>
                                <AppBar position="static">
                                    <Tabs value={location.pathname}>
                                        <Tab label="Действущие" value="/discount/acting" component={Link} to={`/discount/acting`} />
                                        <Tab label="Прошедшие" value="/discount/past" component={Link} to={`/discount/past`} />
                                        {/* <Tab label="Комплексы" value="/discount/card" component={Link} to={`/discount/card`} /> */}
                                    </Tabs>
                                </AppBar>
                                <Switch>
                                    <Route path={`/discount/acting`} render={() => <Acting />} />
                                    <Route path={`/discount/past`} render={() => <Past /> } />
                                    {/* <Route path={`/discount/card`} render={() => <Card /> } /> */}
                                </Switch>
                            </Fragment>
                        )
                    }}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
)(DiscountList);
