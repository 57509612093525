import List from './List';
import Edit from './Edit';
import Create from './Create';
import Show from './Show';

export default {
    list: List,
    edit: Edit,
    show: Show,
    create: Create
};
